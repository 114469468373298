import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import { Image } from 'semantic-ui-react';
import appStoreBadge from '../../assets/images/appstore_badge.svg';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const AppStoreBadge = function AppStoreBadge({ className }) {
  return (
    <a
      className={classNames('app-store-badge', className)}
      href="https://itunes.apple.com/us/app/flexpit/id1358293754?ls=1&mt=8"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image src={appStoreBadge} alt="Download on the App store" />
    </a>
  );
};

AppStoreBadge.propTypes = propTypes;
AppStoreBadge.defaultProps = defaultProps;

export default AppStoreBadge;
