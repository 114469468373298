import React, { Component } from 'react';
import { node, func } from 'prop-types';

import { getPublicUser } from '../../services/users';
import withCachedData from '../../components/higher-order/withCache';
import { UserCacheContext } from '../../components/higher-order/withCachedUser';

class PublicUserCacheComponent extends Component {
  static propTypes = {
    get: func.isRequired,
    children: node,
  };

  render() {
    const { children, get } = this.props;

    return (
      <UserCacheContext.Provider value={get}>
        {children}
      </UserCacheContext.Provider>
    );
  }
}

const PublicUserCache = withCachedData(uid => getPublicUser({ uid }))(
  PublicUserCacheComponent,
);

PublicUserCache.defaultProps.fields = ['username', 'photoUrl'];

export default PublicUserCache;
