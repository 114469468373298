import React, { Component } from 'react';
import { node, string, number, func, bool } from 'prop-types';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';

class ImageCropperModal extends Component {
  static propTypes = {
    onCropImage: func.isRequired,
    image: string,
    header: node,
    content: node,
    aspect: number,
    fileName: string,
    closeOnDimmerClick: bool,
  };

  static defaultProps = {
    onCropImage: () => {},
    header: 'Crop your image',
    fileName: 'cropped_image',
    closeOnDimmerClick: false,
  };

  state = {
    crop: undefined,
    pixelCrop: undefined,
    image: undefined,
    isLoading: false,
  };

  handleImageLoaded = image => {
    const { aspect } = this.props;
    this.setState({
      image,
      crop: makeAspectCrop(
        {
          aspect,
          x: 0,
          y: 0,
        },
        aspect,
      ),
    });
  };

  handleCrop = (crop, pixelCrop) => this.setState({ crop, pixelCrop });

  handleSubmitCrop = async () => {
    // Show loading state if action is taking longer than 1s
    const loaderTimeout = setTimeout(
      () => this.setState({ isLoading: true }),
      1000,
    );

    const { fileName } = this.props;
    const { image, pixelCrop } = this.state;
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    // As a blob
    const file = await new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, 'image/jpeg');
    });

    if (this.state.isLoading) {
      this.setState({ isLoading: false });
    } else {
      clearTimeout(loaderTimeout);
    }

    const { onCropImage } = this.props;

    onCropImage(file);
  };

  render() {
    const {
      header,
      content,
      imageUrl,
      imageFile,
      fileName,
      aspect,
      onCropImage,
      ...props
    } = this.props;
    const { crop, isLoading } = this.state;

    const { onClose } = props;

    return (
      <Modal {...props}>
        <Modal.Header>{header}</Modal.Header>
        <Dimmer.Dimmable as={Modal.Content} dimmed={isLoading}>
          <Dimmer active={isLoading} inverted>
            <Loader content="Cropping..." />
          </Dimmer>

          <ReactCrop
            src={imageUrl}
            crop={crop}
            onChange={this.handleCrop}
            onImageLoaded={this.handleImageLoaded}
          />
          {content}
        </Dimmer.Dimmable>
        <Modal.Actions
          actions={[
            {
              key: 'cancel-btn',
              className: '-tertiary',
              content: 'Cancel',
              onClick: onClose,
            },
            {
              key: 'submit-btn',
              primary: true,
              content: 'Submit',
              onClick: this.handleSubmitCrop,
              disabled: isLoading || !crop,
            },
          ]}
        />
      </Modal>
    );
  }
}

export default ImageCropperModal;
