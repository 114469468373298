import React, { Component } from 'react';
import { string, func, shape } from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import { createSelector } from 'reselect';

import { convertLbsToRoundedKg, convertKgToRoundedLbs } from '../modules/units';
import withLoggedInUser from './higher-order/withLoggedInUser';

const unitOptions = [
  { text: 'lbs', value: 'lbs' },
  { text: 'kg', value: 'kg' },
];

class WeightInput extends Component {
  static propTypes = {
    name: string.isRequired,
    onChange: func.isRequired,
    value: shape({
      lb: string,
      kg: string,
    }).isRequired,
  };

  static defaultProps = {
    placeholder: 'Weight',
    type: 'number',
    action: true,
    value: {
      lb: '',
      kg: '',
    },
    min: 0,
  };

  constructor(props) {
    super(props);

    const {
      currentUserState: { profile },
    } = props;
    this.state = {
      unit: profile && profile.units ? profile.units.weight : 'lbs',
    };
  }

  selectValue = createSelector(
    (_, props) => props.value,
    state => state.unit,
    (value, unit) => {
      if (value[unit] || (!value.lb && !value.kg)) {
        return value[unit];
      }

      const computedValue =
        unit === 'lbs'
          ? convertKgToRoundedLbs(value.kg)
          : convertLbsToRoundedKg(value.lb);
      return computedValue.toString();
    },
  );

  convertValues(value, unit = this.state.unit) {
    let kg = '';
    let lbs = '';
    const parsedValue = parseFloat(value);

    if (value !== '' && !isNaN(parsedValue)) {
      if (unit === 'lbs') {
        // Need to compute kg
        lbs = value;
        kg = convertLbsToRoundedKg(parsedValue).toString();
      } else {
        // Need to compute lb
        kg = value;
        lbs = convertKgToRoundedLbs(parsedValue).toString();
      }
    }

    return {
      kg,
      lbs,
    };
  }

  handleChange = (_, { value }) => {
    const convertedValues = this.convertValues(value);

    const { name, onChange } = this.props;
    const target = {
      name,
      value: convertedValues,
    };

    return onChange({ target }, target);
  };

  handleUnitChange = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { onChange, value, currentUserState, ...props } = this.props;
    const { unit } = this.state;

    const unitDropdown = (
      <Dropdown
        name="unit"
        onChange={this.handleUnitChange}
        value={unit}
        options={unitOptions}
      />
    );

    return (
      <Input
        onChange={this.handleChange}
        value={this.selectValue(this.state, this.props)}
        step={unit === 'lbs' ? 1 : 0.5}
        label={unitDropdown}
        labelPosition="right"
        {...props}
      />
    );
  }
}

export default withLoggedInUser(WeightInput);
