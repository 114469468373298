import React, { Component, Fragment } from 'react';
import { string, number } from 'prop-types';
import { Link } from 'react-router-dom';
import { TableRow, TableCell } from 'semantic-ui-react';
import { createSelector } from 'reselect';

import {
  getLeaderboard,
  getUserRankingForActivity,
} from '../../services/leaderboards';
import withData from '../../components/higher-order/withData';
import withLazyLoadedData from '../../components/higher-order/withLazyLoadedData';
import { getLoggedInUid } from '../../services/authentication';
import LiftRankingTable from '../../components/activities/LiftRankingTable';
import LiftRankingTableExtraLifts from '../../components/activities/LiftRankingTableExtraLifts';
import NoDataMessage from '../../components/NoDataMessage';

const RankingTableWithExtraRankings = function RankingTableWithExtraRankings(
  props,
) {
  const { extraRankings, isLoading } = props;
  return (
    <Fragment>
      <LiftRankingTableExtraLifts {...props} />
      {!isLoading && extraRankings.length === 0 && (
        <NoDataMessage>
          Looks like you haven't posted a lift for this leaderboard yet. Try
          posting a lift via the app to take part.
        </NoDataMessage>
      )}
    </Fragment>
  );
};

const RankingTableWithExtraRankingsContainer = withData(
  getUserRankingForActivity,
  {
    dataPropName: 'extraRankings',
    paramPropName: 'criteria',
  },
)(RankingTableWithExtraRankings);

const ActivityOverviewLeaderboardTableContainer = withLazyLoadedData(
  getLeaderboard,
  {
    dataPropName: 'rankings',
    paramPropName: 'criteria',
    passDownParams: true,
  },
)(
  class ActivityOverviewLeaderboardTable extends Component {
    static propTypes = {
      userUid: string,
      nbUserRankings: number,
    };

    static defaultProps = {
      nbUserRankings: 2,
      fields: LiftRankingTable.defaultProps.fields,
    };

    selectUserRanking = createSelector(
      (_, props) => props.rankings,
      (_, props) => props.criteria,
      (_, props) => props.userUid,
      (rankings, criteria, userUid = getLoggedInUid()) => {
        return criteria.groupBy
          ? rankings.find(group =>
              group.data.find(ranking => ranking.userUid === userUid),
            )
          : rankings.find(ranking => ranking.userUid === userUid);
      },
    );

    render() {
      const {
        userUid,
        nbUserRankings,
        criteria,
        requestMoreData,
        isLastBatch,
        fullLeaderboardUrl,
        ...props
      } = this.props;
      const { isLoading, fields } = props;

      const userRanking = this.selectUserRanking(this.state, this.props);

      /**
       * userRanking: If the user is in the top X, do not display the user's position table
       * under the top X table.
       * isLoading: If it's loading the top X, wait for it to be loaded before displaying the
       * table.
       */
      const displayOnlyTopTable = userRanking || isLoading;

      const viewAllRow = fullLeaderboardUrl && !isLastBatch && (
        <TableRow>
          <TableCell colSpan={fields.length} textAlign="center">
            <Link className="link -primary" to={fullLeaderboardUrl}>
              View all
            </Link>
          </TableCell>
        </TableRow>
      );

      return displayOnlyTopTable ? (
        <LiftRankingTable groupedBy={Boolean(criteria.groupBy)} {...props}>
          {viewAllRow}
        </LiftRankingTable>
      ) : (
        <RankingTableWithExtraRankingsContainer
          criteria={{
            ...criteria,
            userUid,
            limit: nbUserRankings,
          }}
          groupedBy={Boolean(criteria.groupBy)}
          {...props}
        >
          {viewAllRow}
        </RankingTableWithExtraRankingsContainer>
      );
    }
  },
);

export default ActivityOverviewLeaderboardTableContainer;
