import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { firebaseConfig } from '../config';

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage().ref();
export const firebaseAuth = firebase.auth;
firebaseAuth().useDeviceLanguage();
