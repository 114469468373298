import React, { Component } from 'react';
import { string } from 'prop-types';
import { createSelector } from 'reselect';

import withLazyLoadedReactiveData from '../../components/higher-order/withLazyLoadedReactiveData';
import withData from '../../components/higher-order/withData';
import { subscribeToUsersPosts, getPost } from '../../services/posts';
import PostGridWithModal from '../../components/post/PostGridWithModal';

const UserPostGridWithModalWithExtraPost = withData(getPost, {
  dataPropName: 'openedPost',
  paramPropName: 'openedPostUid',
  singleData: true,
})(PostGridWithModal);

const UserPostGridWithModal = withLazyLoadedReactiveData({
  subscribeToData: subscribeToUsersPosts,
  nbByLoad: 9,
  dataPropName: 'posts',
})(
  class UserPostGridWithModalComp extends Component {
    static propTypes = {
      openedPostUid: string,
    };

    static defaultProps = {
      openedPostUid: undefined,
    };

    selectOpenedPost = createSelector(
      props => props.posts,
      props => props.openedPostUid,
      (posts, openedPostUid) => {
        return openedPostUid && posts.find(post => post.uid === openedPostUid);
      },
    );

    render() {
      const { openedPostUid, isLoading } = this.props;

      const openedPost = this.selectOpenedPost(this.props);

      return !openedPostUid || isLoading || openedPost ? (
        <PostGridWithModal {...this.props} openedPost={openedPost} />
      ) : (
        <UserPostGridWithModalWithExtraPost {...this.props} />
      );
    }
  },
);

export default UserPostGridWithModal;
