import React, { Component } from 'react';

import ContentContainer from '../../components/ContentContainer';
import EditProfileForm from '../../containers/users/forms/EditProfileForm';

class EditProfilePage extends Component {
  render() {
    const { user, onUpdateSuccess, onProgressChange } = this.props;

    return (
      <ContentContainer size="tiny" align="left">
        <EditProfileForm
          data={user}
          className="-stacked"
          onSuccess={onUpdateSuccess}
          onProgressChange={onProgressChange}
        />
      </ContentContainer>
    );
  }
}

export default EditProfilePage;
