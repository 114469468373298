import React, { Component } from 'react';
import { oneOfType, object, number, arrayOf } from 'prop-types';
import update from 'immutability-helper';
import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import times from 'lodash/times';

/**
 * @callback fetchDataFunction
 * @param { Object } params
 * @returns { Object[] } The data.
 */

/**
 * Gets a new parametised higher-order component with data.
 * The wrapped component is provided with data, and a function to refresh the data.
 *
 * @param { fetchDataFunction } fetchData The API call to subscribe
 * to data.
 * @param { object } config
 * @param { string } [ config.dataPropName = 'data' ] The name of the data prop
 * to pass down to the wrapped component.
 * @param { string } [ config.paramPropName = 'fetchParams' ] The name of the props
 * to get the fetching parameters from in the HoC.
 * @param { boolean } [ config.passDownParams = false ] true to pass down the `fetchParams` props
 * in the wrapped component, false else.
 * @param { boolean } [ params.wrapProps = false ] true to wrap all the props passed down
 * in a single prop object.
 * @param { function } [ params.combinerNumberData = dataMap => dataMap.size ]
 * Combiner used in the current number of data selector. Use this if the
 * map's size should not be used to calculate the offset when fetching, for example
 * if the actual data is wrapped in objects.
 * @returns { Function } A higher-order component function to wrap a component with data.
 */
const withLazyLoadedData = function withLazyLoadedData(
  fetchData,
  {
    dataPropName = 'data',
    paramPropName = 'fetchParams',
    passDownParams = false,
    wrapProps = false,
    combinerNumberData = dataMap => dataMap.size,
  } = {},
) {
  return WrappedComponent => {
    const wrappedDisplayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    class WithLazyLoadedData extends Component {
      static displayName = `WithLazyLoadedData(${wrappedDisplayName})`;

      static propTypes = {
        [paramPropName]: object,
        /**
         * The number of pieces of data to fetch on the next fetch. You can provide
         * an array if you want to fetch a different number of posts at each iteration.
         * For examplr: [20, 50] will fetch 20 pieces the first time, then 50 the
         * second time and 50 every consecutive time.
         */
        limit: oneOfType([number, arrayOf(number)]),
        originalOffset: number,
      };

      static defaultProps = {
        [paramPropName]: {},
        limit: 20,
        originalOffset: 0,
      };

      static get initialState() {
        return {
          data: new Map(),
          error: undefined,
          isLastBatch: undefined,
          isLoading: true,
          indexFetch: 0,
        };
      }

      state = WithLazyLoadedData.initialState;

      selectData = createSelector(
        state => state.data,
        (dataMap, ignoredDataSet) => {
          return [...dataMap.values()];
        },
      );

      /**
       * Selector for the limit. If the limit provided in the prop is an array,
       * the selector will get the appropriate number based on the number of
       * batches of data fetched.
       * @type { function }
       */
      selectCurrentLimit = createSelector(
        state => state.indexFetch,
        (_, props) => props.limit,
        (indexFetch, limit) => {
          let limitValue = limit;

          // Use last element of the array if indexFetch is greater than the length of the array
          if (Array.isArray(limit)) {
            const indexCurrentLimit = Math.max(
              Math.min(indexFetch, limit.length - 1), // Protect index from going over length
              0, // Protect index from going under length
            );
            limitValue = limit[indexCurrentLimit];
          }

          return limitValue;
        },
      );

      selectNumberData = createSelector(
        state => state.data,
        combinerNumberData,
      );

      /**
       * @type { function } Selects the number of pieces of data that should
       * have been fetched if all requests reached the limit provided.
       *
       * For example, if the limit is 10, and the fetch function has been called
       * 3 times, this function will return 30.
       */
      selectNbDataShouldHaveFetched = createSelector(
        state => state.indexFetch,
        (_, props) => props.limit,
        (indexFetch, limit) => {
          const nbData = times(indexFetch, index => {
            let nb = limit;

            if (Array.isArray(limit)) {
              const indexCurrentLimit = Math.min(index, limit.length - 1);
              nb = limit[indexCurrentLimit];
            }
            return nb;
          }).reduce((sum, nb) => sum + nb, 0);

          return nbData;
        },
      );

      /**
       * @type { function } Selects the offset for the next request.
       */
      selectOffset = createSelector(
        (_, props) => props.originalOffset,
        this.selectNbDataShouldHaveFetched,
        (originalOffset, nbAlreadyFetched) => {
          return originalOffset + nbAlreadyFetched;
        },
      );

      componentDidMount() {
        this.fetchNextData();
      }

      componentWillReceiveProps(nextProps) {
        const { [paramPropName]: fetchParams } = this.props;

        // If fetch params change, reset whole state
        if (!isEqual(fetchParams, nextProps[paramPropName])) {
          this.setState(WithLazyLoadedData.initialState, () =>
            this.fetchNextData(),
          );
        }
      }

      getData() {
        return this.selectData(this.state);
      }

      getCurrentLimit() {
        return this.selectCurrentLimit(this.state, this.props);
      }

      /**
       * Helper function to fetch data.
       * @param { Object } params
       * @param { number } params.limit The number of elements to fetch.
       * @param { number } [ params.offset ] If specified, offsets the query by this number.
       * @returns { Promise<object> } A Promise resolving with an object containing
       * the `immutability-helper` state update query.
       */
      async fetchData({ limit, offset, ...otherOverrideParams }) {
        // Show loading state
        this.setState({
          error: undefined,
          isLoading: true,
        });

        let error = null;
        let data = [];
        let isLastBatch = true;

        const { [paramPropName]: fetchParams } = this.props;

        // Fetch data
        try {
          const fetchResults = await fetchData({
            limit,
            offset,
            ...fetchParams,
          });

          ({ data, isLastBatch } = fetchResults);
        } catch (internalError) {
          error = internalError;
        }

        // Prepare data to update the state
        const dataUpdateQuery = this.updateStateWithData(data);

        // Return state update to hide loading state and update error/data
        return {
          error: { $set: error },
          isLastBatch: { $set: isLastBatch },
          isLoading: { $set: false },
          ...dataUpdateQuery,
        };
      }

      /**
       * Refreshes all already fetched data.
       * @returns { Promise } A Promise that resolves when the state was
       * requested to update with the refreshed data.
       */
      async refreshData() {
        const limit = this.selectNumberData(this.state, this.props);

        const stateQuery = await this.fetchData({ limit, offset: 0 });

        this.setState(
          update(this.state, {
            ...stateQuery,
          }),
        );
      }

      /**
       * Fetches the next batch of data.
       * @returns { Promise } A Promise that resolves when the state was
       * requested to update with the new data.
       */
      async fetchNextData() {
        const limit = this.getCurrentLimit();
        const offset = this.selectOffset(this.state, this.props);

        const stateQuery = await this.fetchData({
          limit,
          offset,
        });

        const indexUpdateQuery =
          stateQuery.data.$add.length > 0
            ? {
                indexFetch: { $apply: value => value + 1 },
              }
            : {};

        this.setState(
          update(this.state, {
            ...stateQuery,
            ...indexUpdateQuery,
          }),
        );
      }

      async fetchSpecificData(params) {
        const { limit = this.getCurrentLimit() } = params;

        const stateQuery = await this.fetchData({ limit, ...params });

        this.setState(
          update(this.state, {
            ...stateQuery,
          }),
        );
      }

      updateStateWithData(pieces) {
        const piecesToAdd = pieces.map(piece => [piece.uid, piece]);
        return {
          data: {
            $add: piecesToAdd,
          },
        };
      }

      handleRequestMoreData = () => this.fetchNextData();

      handleRequestRefreshData = () => this.refreshData();

      handleRequestSpecificData = params => this.fetchSpecificData(params);

      render() {
        const {
          originalOffset,
          [paramPropName]: fetchParams,
          isLoading: isLoadingProp,
          ...wrappedProps
        } = this.props;
        const { isLastBatch, isLoading, error } = this.state;

        const data = this.getData();

        const propWrapper = {
          error,
          isLastBatch,
          [wrapProps ? 'data' : dataPropName]: data,
          isLoading: isLoadingProp || isLoading,
          requestMoreData: this.handleRequestMoreData,
          requestRefreshData: this.handleRequestRefreshData,
          requestSpecificData: this.handleRequestSpecificData,
        };

        // Pass down parametes if asked to in HoC
        if (passDownParams) {
          propWrapper[paramPropName] = fetchParams;
        }

        // Either wrap all the props with an object, or pass them directly as props
        const extraProps = wrapProps
          ? { [dataPropName]: propWrapper }
          : propWrapper;

        return <WrappedComponent {...extraProps} {...wrappedProps} />;
      }
    }

    return WithLazyLoadedData;
  };
};

export default withLazyLoadedData;
