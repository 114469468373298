import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import withData from '../../components/higher-order/withData';
import {
  getRelationshipWith,
  updateRelationship,
} from '../../services/relationships';

class SpotButton extends Component {
  state = {
    relationship: undefined,
    isLoading: false,
    error: undefined,
  };

  async updateRelationship() {
    const { data, userUid } = this.props;
    const { relationship = data } = this.state;

    // We need to know the current relationship's status before updating it
    if (!relationship) {
      throw new Error(
        'Cannot update relationship with user before knowing current status',
      );
    }

    const { outgoingStatus } = relationship;

    // Show loading state
    this.setState({ isLoading: true, error: undefined });

    let error = null;
    let updateResultingRelationship = {};

    let action;

    // Check relationship status to determine action
    if (['spotting', 'requested'].includes(outgoingStatus)) {
      action = 'unspot';
    } else if (outgoingStatus === 'none') {
      action = 'spot';
    } else {
      const error = new Error('Unknown relationship status');
      error.data = { relationship };
      throw error;
    }

    // Call API to update
    try {
      const resultingRelationship = await updateRelationship(userUid, action);
      updateResultingRelationship = { relationship: resultingRelationship };
    } catch (internalError) {
      error = internalError;
    }

    // Update state with results
    this.setState({
      error,
      ...updateResultingRelationship,
      isLoading: false,
    });
  }

  handleClick = evt => {
    // Bubble event
    const { onClick } = this.props;
    if (onClick) onClick(evt);

    this.updateRelationship();
  };

  renderText() {
    const { data, children } = this.props;
    const { relationship = data } = this.state;

    let text = children;
    if (!text && relationship) {
      const { outgoingStatus } = relationship;
      switch (outgoingStatus) {
        case 'spotting': {
          text = 'Spotting';
          break;
        }

        case 'requested': {
          text = 'Request pending';
          break;
        }

        case 'none': {
          text = 'Spot';
          break;
        }

        default: {
          text = undefined;
          break;
        }
      }
    }
    return text;
  }

  render() {
    // Use the data in props if state does not hold an updated version of the data
    const {
      data,
      onClick,
      userUid,
      isLoading: propLoading,
      requestRefreshData,
      activeClassName,
      privateProfile,
      ...buttonProps
    } = this.props;

    const { relationship = data, isLoading: stateLoading } = this.state;

    // Display loading state if anyone is loading
    const isLoading = propLoading || stateLoading;
    const useSimpleStyle =
      !relationship || relationship.outgoingStatus !== 'none';

    const conditionalProps = useSimpleStyle
      ? {
          className: activeClassName,
        }
      : {
          primary: true,
        };

    // If the profile is private and you are not currently spotting the user, show the lock icon
    const useLockIcon =
      privateProfile &&
      (!relationship || relationship.outgoingStatus !== 'spotting');

    return (
      <Button
        {...conditionalProps}
        loading={isLoading}
        disabled={isLoading}
        onClick={this.handleClick}
        icon={useLockIcon}
        {...buttonProps}
      >
        {useLockIcon && <Icon name="lock" />}
        {this.renderText()}
      </Button>
    );
  }
}

export default withData(getRelationshipWith, {
  passDownParams: true,
  paramPropName: 'userUid',
  singleData: true,
})(SpotButton);
