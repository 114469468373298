import withData from '../../components/higher-order/withData';
import { getUserLeaderboardPositions } from '../../services/leaderboards';
import ActivityRankingList from '../../components/activities/ActivityRankingList';

const UserLeaderboardPositions = withData(getUserLeaderboardPositions, {
  paramPropName: 'userUid',
  dataPropName: 'rankings',
})(ActivityRankingList);

export default UserLeaderboardPositions;
