import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { bool, number, func } from 'prop-types';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { Search } from 'semantic-ui-react';

import UserSearchResult from './UserSearchResult';
import { searchUsers } from '../../services/users';

class UserMentionInput extends Component {
  static propTypes = {
    onChange: func.isRequired,
    cacheUserMention: func.isRequired,
    circular: bool,
    nbResults: number,
  };

  static defaultProps = {
    resultRenderer: UserSearchResult,
    showNoResults: true,
    input: {
      icon: undefined,
    },
    circular: false,
    nbResults: 8,
  };

  state = {
    onChange: () => {},
    handleCache: () => {},
    error: undefined,
    isLoading: undefined,
    searchValue: '',
    users: new Map(),
  };

  request = undefined;

  selectResults = createSelector(
    state => state.users,
    userMap => {
      const users = [...userMap.values()];
      return users.map(({ uid, photoUrl, username }) => ({
        id: uid,
        image: photoUrl,
        title: username,
      }));
    },
  );

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
      this.request = undefined;
    }
  }

  getResults() {
    return this.selectResults(this.state);
  }

  handleRequestFetchUsers = async () => {
    const { nbResults } = this.props;
    const { searchValue } = this.state;
    const usersMap = new Map();
    let error = null;

    if (searchValue) {
      if (this.request) {
        this.request.cancel();
        this.request = undefined;
      }

      try {
        this.request = searchUsers({ username: searchValue, limit: nbResults });
        const data = await this.request.promise;
        data.forEach(user => usersMap.set(user.uid, user));
      } catch (internalError) {
        error = internalError;
      }
    }

    this.setState({
      error,
      users: usersMap,
      isLoading: false,
    });
  };

  debouncedFetchUsers = debounce(this.handleRequestFetchUsers, 500);

  handleSearchChange = (e, data) => {
    const { value } = data;

    // Check if the last bit of text matches `@a`
    const mention = value.match(/@(\w+)$/);
    if (mention) {
      const [, username] = mention;
      this.setState({ isLoading: true, searchValue: username });
      this.debouncedFetchUsers();
    } else {
      this.setState({ isLoading: undefined });
    }

    const { onChange, name } = this.props;
    onChange(e, { ...data, name });
  };

  handleResultSelect = (_, { result: { id: uid, title: username } }) => {
    const { onChange, cacheUserMention, name, value } = this.props;
    const newValue = value.replace(/@\w+$/, `@${username} `);
    const artificialEvent = { name, value: newValue };
    onChange({ target: artificialEvent }, artificialEvent);
    cacheUserMention({ uid, username });
  };

  render() {
    const {
      loading,
      className,
      circular,
      contrasted,
      showNoResults,
      onSearchChange,
      nbResults,
      input,
      onChange,
      resultRenderer,
      cacheUserMention,
      value,
      ...props
    } = this.props;
    const { isLoading } = this.state;

    const results = this.getResults();

    return (
      <Search
        loading={isLoading || loading}
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.handleResultSelect}
        results={isLoading === false ? results : undefined}
        showNoResults={isLoading === false && showNoResults}
        className={classNames(!circular && '-notcircular', className)}
        resultRenderer={resultRenderer}
        input={{
          className: contrasted && 'contrasted',
          icon: undefined,
          iconPosition: isLoading ? 'left' : undefined,
          loading: isLoading || loading,
          ...props,
        }}
        value={value}
      />
    );
  }
}

export default UserMentionInput;
