/**
 * Service module for API functions related to the leaderboards.
 * @module services/leaderboards
 */

import axios from 'axios';
import check from 'check-types';

import { checkArguments } from '../modules/services';
import { getApiEndpoint, getTokenedConfig } from '../modules/api';
import { throwInternalError } from '../modules/errors';
import { getLoggedInUid } from './authentication';
import { getActivities } from './activities';

const activityTypes = ['MAX_WEIGHT', 'MAX_REPS', 'TIME', 'WEIGHTED'];
const groupByTypes = ['lbs', 'kg'];

/**
 * Adds a uid to a ranking and converts dates.
 * @param { object } data The ranking data to convert.
 * @returns { object } The converted data.
 */
export const convertRanking = function convertRanking(data) {
  const { dateCreated, ...ranking } = data;
  const { userUid } = ranking;
  return {
    ...ranking,
    uid: userUid,
    dateCreated: dateCreated && new Date(dateCreated),
  };
};

/**
 * Converts deeply weight groups of rankings.
 * @param { object } data The weight group.
 * @param { object[] } data.data The rankings.
 * @returns { object } The converted data.
 */
export const convertGroupedRankings = function convertGroupedRankings(data) {
  const { data: rankings, ...group } = data;
  const { kg } = group;

  return {
    ...group,
    uid: `${kg}-group`,
    data: rankings.map(convertRanking),
  };
};

/**
 * Gets the ranking of the best lifts for the specified category.
 *
 * @param { object } args
 * @param { string } args.activityUid The activity's uid to get the ranking for.
 * @param { string } args.type The criteria to base to ranking on.
 * @param { string } [ args.userUid ] If specified, returns only the ranking of the given user.
 * @param { number } [ args.offset = 0] If specified, offsets the query by the number of ranks.
 * @param { number } [ args.limit = 20] Limits the number of results in the query.
 * @param { boolean } [ args.media = false ] If true, returns the username and the
 * profile picture of each user.
 * @returns { Promise<DataWithBatchCheck> } A Promise of the data with a check for extra data.
 * @throws { InternalError }
 */
export const getLeaderboard = async function getLeaderboard(args) {
  checkArguments(
    args,
    {
      activityUid: check.nonEmptyString,
      type: check.nonEmptyString,
      userUid: check.maybe.string,
      offset: check.maybe.number,
      limit: check.maybe.number,
      media: check.maybe.boolean,
      groupBy: check.maybe.string,
    },
    activityTypes.includes(args.type),
    !Boolean(args.groupBy) || groupByTypes.includes(args.groupBy),
  );

  const {
    activityUid,
    type,
    userUid,
    groupBy,
    offset = 0,
    limit = 20,
    media = false,
  } = args;

  const params = {
    type,
    media,
    groupBy,
    limit: limit + 1,
    start: offset + 1,
  };

  if (userUid) {
    params.userUid = userUid;
  }

  const url = getApiEndpoint.leaderboards.getForActivity(activityUid);
  let data = [];

  try {
    const config = await getTokenedConfig({ params });
    data = await axios.get(url, config).then(response => response.data.data);
  } catch (error) {
    throwInternalError(error);
  }

  let dataWithUids;
  let slicedData;
  let nbRankings;

  // If fetching rankings grouped by weight
  if (groupBy) {
    dataWithUids = data.map(convertGroupedRankings);
    // Calculate the number of rankings by summing each array of data's length
    nbRankings = dataWithUids.reduce(
      (sum, group) => sum + group.data.length,
      0,
    );

    if (nbRankings > limit) {
      // If there are more pieces of data that originally fetched, removes the last
      dataWithUids[dataWithUids.length - 1].data.pop();

      // And remove any empty group
      slicedData = dataWithUids.filter(group => group.data.length !== 0);
    } else {
      slicedData = dataWithUids;
    }
  } else {
    dataWithUids = data.map(convertRanking);
    nbRankings = data.length;
    slicedData = dataWithUids.slice(0, limit);
  }

  return {
    data: slicedData,
    isLastBatch: nbRankings <= limit,
  };
};

/**
 * Gets the ranking of the specified user for the given activity and activity type,
 * as well as the users ranked before and after.
 * @param { object } args
 * @param { string } args.activityUid The activity's uid to get the ranking for.
 * @param { string } args.type The criteria to base to ranking on.
 * @param { string } [ args.userUid ] The user uid whose ranking to get. If unspecified,
 * will use the currently logged-in user.
 * @param { number } [ args.limit = 2 ] The number of rankings to get before and after
 * the user's ranking. For example, limit = 2 will fetch 5 rankings, 2 before
 * the user and 2 after the user.
 * @param { boolean } [ args.media = false ] If true, returns the username and the
 * @returns { Promise<DataWithBatchCheck> } A Promise of the data with a check for extra data.
 * @throws { InternalError }
 */
export const getUserRankingForActivity = async function getUserRankingForActivity(
  args,
) {
  checkArguments(
    args,
    {
      activityUid: check.nonEmptyString,
      type: check.nonEmptyString,
      userUid: check.maybe.string,
      limit: check.maybe.number,
      media: check.maybe.boolean,
      groupBy: check.maybe.string,
    },
    activityTypes.includes(args.type),
    !Boolean(args.groupBy) || groupByTypes.includes(args.groupBy),
  );

  const {
    activityUid,
    type,
    groupBy,
    userUid,
    limit = 2,
    media = false,
  } = args;

  const params = {
    type,
    media,
    groupBy,
    limit: limit + 1, // Fetch one extra to check if there's more data
  };

  // API defaults to current user so do not need to provide the user uid if same as logged in
  if (userUid && userUid !== getLoggedInUid()) {
    params.userUid = userUid;
  }

  const url = getApiEndpoint.leaderboards.getUserRankingForActivity(
    activityUid,
  );
  let data = [];

  try {
    const config = await getTokenedConfig({ params });
    ({
      data: { data },
    } = await axios.get(url, config));
  } catch (error) {
    throwInternalError(error);
  }

  if (data.length === 0) {
    return {
      data,
      isLastBatch: true,
    };
  }

  /**
   * The purpose of this piece of code is to remove the extra data we fetched
   * to check if isLastBatch === true or not.
   */
  let actualData = [];
  let isLastBatch = false;
  const organisedData = groupBy
    ? data.reduce(
        (acc, group) =>
          acc.concat(
            group.data.map(ranking => ({
              ...ranking,
              groupKg: group.kg,
            })),
          ),
        [],
      )
    : data;

  // Get the position of the user that we were looking for
  const userIndex = organisedData.findIndex(
    user => user.userUid === (userUid || getLoggedInUid()),
  );

  // Slice the response into two arrays containing the before and after rankings
  const beforeUserData = organisedData.slice(0, userIndex);
  const afterUserData = organisedData.slice(userIndex + 1);

  // Slice each array to remove before/after the data.
  const beforeUserActualData = beforeUserData.slice(-limit);
  const afterUserActualData = afterUserData.slice(0, limit);

  // Merge all data
  actualData = [
    ...beforeUserActualData,
    organisedData[userIndex],
    ...afterUserActualData,
  ];

  isLastBatch = afterUserData.length <= limit;

  if (groupBy) {
    actualData = actualData.reduce((groups, ranking) => {
      let group = groups.find(g => g.kg === ranking.groupKg);

      if (!group) {
        const { data: _, ...copyGroup } =
          data.find(g => g.kg === ranking.groupKg) || {};
        group = {
          ...copyGroup,
          data: [],
        };
        groups.push(group);
      }

      group.data.push(ranking);

      return groups;
    }, []);
  }

  const dataWithUids = actualData.map(
    groupBy ? convertGroupedRankings : convertRanking,
  );

  return {
    data: dataWithUids,
    isLastBatch,
  };
};

/**
 * Gets all the position for the indicated user on all types of the specified activity.
 * @param { object } activity
 * @param { string } activity.uid The uid of the activity.
 * @param { string[] } activity.types The types of the activity.
 * @param { string } [ userUid = loggedInUid ] The uid of the user whose positions to get.
 * @returns { Promise<object>[] } A list of Promises that each resolves with an object
 * structured like { type, position }.
 * Position can be null if the user is not part of this leaderboard.
 */
const getUserActivityPositions = function getYourActivityPositions(
  activity,
  userUid = getLoggedInUid(),
) {
  checkArguments(
    activity,
    {
      uid: check.nonEmptyString,
      type: check.array,
    },
    Boolean(userUid),
  );

  const { uid, type: types } = activity;
  const url = getApiEndpoint.leaderboards.getForActivity(uid);

  return types.map(async type => {
    const params = {
      type,
      userUid,
    };

    let position = undefined;

    try {
      const config = await getTokenedConfig({ params });
      position = await axios.get(url, config).then(response => {
        return response.data.data;
      });
    } catch (error) {
      throwInternalError(error);
    }

    return { position, type };
  });
};

/**
 * Formats the positions with activity information.
 * @param { object } activity The activity information.
 * @param { string } activity.uid
 * @param { string } activity.name
 * @param { object } positionData The position data.
 * @param { string } positionData.type The activity type of the position
 * @param { number } positionData.position The position for that activity type. Can be null if not ranked
 * for that activity.
 * @returns { object } The positions with the activity uid and name.
 */
const formatActivityPositions = function formatActivityPositions({
  activity,
  positionData,
  userUid = getLoggedInUid(),
}) {
  const { uid, name } = activity;
  const { type, position } = positionData;

  return {
    uid: `${uid}-${type}-${userUid}`,
    activity: {
      uid,
      name,
      type,
    },
    position,
  };
};

/**
 * Gets all the rankings of the user for each activity and for each activity type.
 * @param { string } [ userUid = logged-in user's uid ] The uid of the user whose rankings to get.
 * @returns { Promise<object>[] } A list of Promises that each resolves with
 * the user ranking for each activity and activity type, structured like
 * { activity: { name, type }, position }.
 * The position is null if the user is not ranked for that activity yet.
 */
export const getUserLeaderboardPositions = async function getUserLeaderboardPositions(
  userUid = getLoggedInUid(),
) {
  let rankings;
  try {
    const activities = await getActivities();
    const rankingPromises = activities.reduce((acc, activity) => {
      const positionPromises = getUserActivityPositions(activity, userUid).map(
        positionPromise =>
          positionPromise.then(positionData =>
            formatActivityPositions({ activity, positionData, userUid }),
          ),
      );
      return acc.concat(positionPromises);
    }, []);
    rankings = await Promise.all(rankingPromises);
  } catch (error) {
    throwInternalError(error);
  }

  return rankings;
};
