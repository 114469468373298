import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { string } from 'prop-types';
import update from 'immutability-helper';

import { USER_SEX_TEXTS } from '../../../modules/constants/users';
import DateInput from '../../DateInput';
import WeightInput from '../../WeightInput';
import HeightInput from '../../HeightInput';

const sexOptions = Object.keys(USER_SEX_TEXTS).map(value => ({
  value,
  text: USER_SEX_TEXTS[value],
}));

// const userTypeOptions = [
//   { text: 'Casual user', value: 'Casual' },
//   { text: 'Scout', value: 'Scout' },
//   { text: 'Trainer', value: 'Trainer' },
//   { text: 'Athlete', value: 'Athlete' },
// ];

// const sportOptions = Object.keys(USER_SPORTS).map(key => ({
//   text: USER_SPORTS[key],
//   value: USER_SPORTS[key],
//   image: (SPORT_ASSETS[USER_SPORTS[key]] || {}).svg,
// }));

class DetailsForm extends Component {
  static propTypes = {
    id: string,
  };

  static defaultProps = {
    id: 'details-form',
  };

  state = {};

  handleCustomChange = ({ target }, data) => {
    const { name: fullName, value } = data || target;
    const names = fullName.split('.');

    const updateObj = names.reduceRight(
      (partial, name) => ({
        [name]: partial,
      }),
      { $set: value },
    );
    this.setState(update(this.state, updateObj));
  };

  render() {
    const { id, onChange, data, formData, initialValue, ...props } = this.props;

    const getFieldProps = name => ({
      onChange,
      name,
      id: `${id}-${name}`,
      value: formData[name] !== undefined ? formData[name] : data[name] || '',
    });

    return (
      <Form id={id} {...props}>
        <Form.Dropdown
          className="formrow"
          label="Sex"
          placeholder="Sex"
          options={sexOptions}
          selection
          {...getFieldProps('sex')}
        />
        <Form.Field
          className="formrow"
          control={DateInput}
          label="Date of birth"
          {...getFieldProps('dateOfBirth')}
        />
        <Form.Field className="formrow">
          <label htmlFor={`${id}-weight`}>Weight</label>
          <WeightInput
            fluid
            {...getFieldProps('weight')}
            value={
              formData.weight !== undefined
                ? formData.weight
                : {
                    kg:
                      data.weight || data.weight === 0
                        ? data.weight.toString()
                        : '',
                    lbs: '',
                  }
            }
          />
        </Form.Field>
        <div className="formrow">
          <label htmlFor={`${id}-height.feed`}>Height</label>
          <HeightInput
            fluid
            {...getFieldProps('height')}
            value={
              formData.height !== undefined
                ? formData.height
                : {
                    cm:
                      data.height || data.height === 0
                        ? data.height.toString()
                        : '',
                    inches: '',
                    feet: '',
                  }
            }
          />
        </div>
        {/*
        INFO: For now, editing user's type is out of scope on web app
        <Form.Dropdown
          className="formrow"
          label={`I am a${
            (formData.userType || data.userType) === 'Athlete' ? 'n' : ''
          }`}
          options={userTypeOptions}
          selection
          {...getFieldProps('userType')}
        />
        <Form.Dropdown
          className="formrow"
          label="I practice"
          options={sportOptions}
          selection
          {...getFieldProps('sport')}
        /> */}
        <Form.Button primary type="submit" className="formrow">
          Submit
        </Form.Button>
      </Form>
    );
  }
}

export default DetailsForm;
