import withData from '../../components/higher-order/withData';
import UserList from '../../components/user/UserList';
import { getSpotters } from '../../services/relationships';

const SpotterList = withData(getSpotters, {
  dataPropName: 'users',
  paramPropName: 'userUid',
})(UserList);

export default SpotterList;
