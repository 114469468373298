import React from 'react';
import { Route } from 'react-router';

import LoginPage from '../pages/LoginPage';

const ProtectedRoute = function ProtectedRoute({
  component: Component,
  render,
  currentUserState,
  loginText,
  onLoginError,
  onLoginSuccess,
  ...routeProps
}) {
  return (
    <Route
      {...routeProps}
      render={props => {
        return currentUserState.isLogged ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <LoginPage
            text={loginText}
            onError={onLoginError}
            onSuccess={onLoginSuccess}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
