import React, { Component } from 'react';
import { Feed } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import BaseEventInfo from './BaseEventInfo';
import { routes, injectParamsIntoRoute } from '../../modules/routes';
import CroppedImage from '../CroppedImage';

class PostEvent extends Component {
  render() {
    const {
      event,
      event: {
        target: { post },
        unseen,
      },
      text,
      children,
      className,
      postAuthor,
      currentUserState,
      ...props
    } = this.props;

    const postUrl = injectParamsIntoRoute(routes.post, {
      postUid: post.uid,
    });

    return (
      <Feed.Event
        as={Link}
        to={postUrl}
        className={classNames(
          'event-notification',
          unseen && '-unseen',
          className,
        )}
        {...props}
      >
        <BaseEventInfo event={event} text={text}>
          {children}
        </BaseEventInfo>
        <CroppedImage className="endcontent" src={post.thumbnailUrl} />
      </Feed.Event>
    );
  }
}

export default PostEvent;
