import { getPublicUser } from '../../services/users';
import withData from '../../components/higher-order/withData';
import CommentItem from '../../components/post/CommentItem';

const FetchedAuthorCommentItem = withData(uid => getPublicUser({ uid }), {
  dataPropName: 'author',
  paramPropName: 'authorUid',
  singleData: true,
  wrapProps: true,
})(CommentItem);

export default FetchedAuthorCommentItem;
