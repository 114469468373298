import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import ContentContainer from '../components/ContentContainer';
import LoginBlock from '../components/login/LoginBlock';

class LoginPage extends Component {
  render() {
    const props = this.props;

    return (
      <ContentContainer size="mini" className="login-page">
        <LoginBlock as={Segment} {...props} />
      </ContentContainer>
    );
  }
}

export default LoginPage;
