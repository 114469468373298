import React, { Component } from 'react';
import { string, shape, object, bool } from 'prop-types';

import { Divider, Form, Icon, Input } from 'semantic-ui-react';
import { createSelector } from 'reselect';

class LoginForm extends Component {
  static propTypes = {
    id: string,
    formData: shape({
      email: string,
      password: string,
    }),
    error: object,
    subtilLoading: bool,
    contrasted: bool,
  };

  static defaultProps = {
    id: 'login-form',
    formData: {
      email: '',
      password: '',
    },
    subtilLoading: false,
    contrasted: false,
  };

  selectOtherProps = createSelector(
    (_, props) => props,
    props => {
      const {
        error,
        formData,
        loading,
        subtilLoading,
        disabled,
        initialValue,
        contrasted,
        onClickFacebookSignIn,
        ...otherProps
      } = props;

      return otherProps;
    },
  );

  render() {
    const {
      id,
      formData,
      loading,
      subtilLoading,
      disabled,
      contrasted,
      onClickFacebookSignIn,
    } = this.props;

    const props = this.selectOtherProps(this.state, this.props);

    return (
      <Form loading={subtilLoading ? undefined : loading} {...props}>
        <Form.Field>
          <label htmlFor={`${id}-email`}>Email address</label>
          <Input
            id={`${id}-email`}
            name="email"
            value={formData.email || ''}
            className={contrasted ? 'contrasted' : undefined}
            disabled={disabled || loading}
            placeholder="name@xyz.com"
            type="email"
            autoFocus
            autoComplete="email"
            required
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor={`${id}-password`}>Password</label>
          <Input
            id={`${id}-password`}
            name="password"
            value={formData.password || ''}
            className={contrasted ? 'contrasted' : undefined}
            disabled={disabled || loading}
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            required
          />
        </Form.Field>
        <Form.Button
          primary
          fluid
          type="submit"
          size="large"
          loading={subtilLoading ? loading : undefined}
        >
          Login
        </Form.Button>
        <Divider horizontal>Or</Divider>
        <Form.Button
          color="facebook"
          type="button"
          fluid
          disabled={disabled || loading}
          onClick={onClickFacebookSignIn}
        >
          <Icon name="facebook f" /> Sign in with Facebook
        </Form.Button>
      </Form>
    );
  }
}

export default LoginForm;
