export const customApi = {
  environment: 'production',
  apiUrl: 'https://flexpit-production.appspot.com/',
  apiVersion: 'v1',
};

export const firebaseConfig = {
  apiKey: 'AIzaSyBXwMsfHXvlEcgYzU9gKxWe5DPij7EWNZI',
  authDomain: 'flexpit-production.firebaseapp.com',
  databaseURL: 'https://flexpit-production.firebaseio.com',
  projectId: 'flexpit-production',
  storageBucket: 'flexpit-production.appspot.com',
  messagingSenderId: '564238017354',
};
