import React, { Component } from 'react';
import { string, shape } from 'prop-types';
import { Image, List } from 'semantic-ui-react';

import { SPORT_ASSETS } from '../../../assets/images/sports';
import SvgImage from '../../SvgImage';

class UserSportDetails extends Component {
  static propTypes = {
    data: shape({
      locationName: string,
      sportTeam: string,
      sport: string,
    }).isRequired,
  };

  render() {
    const {
      data: { locationName, sportTeam, sport },
    } = this.props;

    const sportString = sportTeam || sport;
    const image = SPORT_ASSETS[sportString];

    return (
      <List className="details">
        {locationName && (
          <List.Item>
            <List.Icon name="university" />
            <List.Content>
              <List.Header>{locationName}</List.Header>
            </List.Content>
          </List.Item>
        )}
        <List.Item>
          {image && (
            <SvgImage
              as={Image}
              size="small"
              src={image.svg}
              fallback={image.png}
            />
          )}
          <List.Content>
            <List.Header>{sportString}</List.Header>
          </List.Content>
        </List.Item>
      </List>
    );
  }
}

export default UserSportDetails;
