import React, { Component } from 'react';
import {
  oneOfType,
  string,
  element,
  func,
  shape,
  oneOf,
  number,
} from 'prop-types';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';

import { activityExerciseAsset } from '../../assets/images/exercises';
import { ACTIVITY_TYPE_TEXTS } from '../../modules/constants/activities';

class ActivityRankingItem extends Component {
  static propTypes = {
    ranking: shape({
      activity: shape({
        name: string.isRequired,
        type: oneOf(Object.keys(ACTIVITY_TYPE_TEXTS)).isRequired,
      }).isRequired,
      position: oneOfType([number, string]).isRequired,
    }).isRequired,
    as: oneOfType([string, element, func]),
    className: string,
  };

  static defaultProps = {
    ranking: {
      activity: {},
    },
    as: 'li',
    className: '',
  };

  render() {
    const {
      as: As,
      className,
      ranking: {
        activity: { name, type },
        position,
      },
      ...props
    } = this.props;

    const icon = activityExerciseAsset(name);
    if (!icon) {
      console.warn(`<ActivityRankingItem />: cannot find icon for '${name}'`);
    }

    const typeText = ACTIVITY_TYPE_TEXTS[type];
    if (!typeText) {
      console.warn(`<ActivityRankingItem />: cannot find text for '${type}'`);
    }

    return (
      <As className={classNames('activity-ranking-item', className)} {...props}>
        {icon && (
          <Image className="illustration" src={icon} alt={name} size="tiny" />
        )}
        {typeText}
        <strong className="strong-info position">{position}</strong>
      </As>
    );
  }
}

export default ActivityRankingItem;
