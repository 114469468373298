import React, { Component } from 'react';
import { bool, oneOfType, element, func, number } from 'prop-types';
import { Search } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';

import { searchUsers } from '../../services/users';
import { getHashtagSuggestions } from '../../services/hashtags';
import { UserProfileLinkResult } from '../user/UserSearchResult';
import { HashtagFeedLinkResult } from './HashtagSearchResult';
import { pluralise } from '../../modules/humanise';

class UserHashtagSearchBar extends Component {
  static propTypes = {
    circular: bool,
    hashtagResultRenderer: oneOfType([element, func]),
    userResultRenderer: oneOfType([element, func]),
    maxNbResults: number,
  };

  static defaultProps = {
    placeholder: 'Search user or hashtag, e.g. john, #tennis...',
    hashtagResultRenderer: HashtagFeedLinkResult,
    userResultRenderer: UserProfileLinkResult,
    showNoResults: true,
    input: {
      icon: 'search',
      iconPosition: 'left',
    },
    circular: false,
    maxNbResults: 8,
  };

  state = {
    error: undefined,
    isLoading: false,
    searchValue: '',
    users: new Map(),
    hashtags: new Map(),
  };

  userSuggestionRequest = undefined;

  selectUserResults = createSelector(
    state => state.users,
    userMap => {
      const users = [...userMap.values()];
      return users.map(({ uid, photoUrl, username }) => ({
        id: uid,
        image: photoUrl,
        title: username,
        sort: username,
      }));
    },
  );

  selectHashtagResults = createSelector(
    state => state.hashtags,
    hashtagMap => {
      const hashtags = [...hashtagMap.values()];
      return hashtags.map(({ name, popularity }) => ({
        id: name,
        title: `#${name}`,
        description: `${popularity} ${pluralise('post', popularity)}`,
        sort: name,
      }));
    },
  );

  selectResults = createSelector(
    this.selectUserResults,
    this.selectHashtagResults,
    (userResults, hashtagResults) =>
      sortBy(userResults.concat(hashtagResults), 'sort'),
  );

  componentWillUnmount() {
    if (this.userSuggestionRequest) {
      this.userSuggestionRequest.cancel();
      this.userSuggestionRequest = undefined;
    }
  }

  getResults() {
    return this.selectResults(this.state);
  }

  handleRequestFetchSuggestions = async () => {
    const { maxNbResults } = this.props;
    const { searchValue } = this.state;
    const usersMap = new Map();
    const hashtagsMap = new Map();

    let error = null;

    // Limit the suggestions to a type
    let onlySource = '';
    if (searchValue[0] === '#') {
      onlySource = 'hashtags';
    } else if (searchValue[0] === '@') {
      onlySource = 'users';
    }
    const queryHashtags = !onlySource || onlySource === 'hashtags';
    const queryUsers = !onlySource || onlySource === 'users';
    const limit = !onlySource ? maxNbResults / 2 : maxNbResults;

    // Cleans search value from #|@
    const [queryValue] = searchValue.match(/\w+/) || [];

    if (queryValue) {
      if (queryUsers && this.userSuggestionRequest) {
        this.userSuggestionRequest.cancel();
        this.userSuggestionRequest = undefined;
      }
      try {
        // User request if asked to query them
        const userSuggestionRequest = queryUsers
          ? searchUsers({ username: queryValue, limit })
          : { promise: Promise.resolve([]) };
        if (queryUsers) {
          this.userSuggestionRequest = userSuggestionRequest;
        }

        // Hashtag request if asked to query them
        const hashtagPromise = queryHashtags
          ? getHashtagSuggestions(queryValue, limit)
          : Promise.resolve([]);

        // Wait for all
        const [userData, hashtagData] = await Promise.all([
          userSuggestionRequest.promise,
          hashtagPromise,
        ]);

        // Format data for state
        userData.forEach(user => usersMap.set(user.uid, user));
        hashtagData.forEach(hashtag => hashtagsMap.set(hashtag.name, hashtag));
      } catch (internalError) {
        error = internalError;
      }
    }

    // Prepare query to update state
    const stateQuery = {
      error,
      isLoading: false,
    };

    if (queryHashtags) {
      stateQuery.hashtags = hashtagsMap;
    }

    if (queryUsers) {
      stateQuery.users = usersMap;
    }

    this.setState(stateQuery);
  };

  debouncedFetchSuggestions = debounce(this.handleRequestFetchSuggestions, 500);

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, searchValue: value });
    this.debouncedFetchSuggestions();
  };

  renderResult = ({ sort, ...props }) => {
    const {
      hashtagResultRenderer: HashtagResult,
      userResultRenderer: UserResult,
    } = this.props;

    const { title } = props;

    return title[0] === '#' ? (
      <HashtagResult {...props} />
    ) : (
      <UserResult {...props} />
    );
  };

  render() {
    const {
      loading,
      className,
      circular,
      showNoResults,
      hashtagResultRenderer,
      userResultRenderer,
      maxNbResults,
      ...props
    } = this.props;
    const { isLoading, value } = this.state;

    const results = this.getResults();

    return (
      <Search
        loading={isLoading || loading}
        onSearchChange={this.handleSearchChange}
        results={results}
        showNoResults={!isLoading && showNoResults}
        value={value}
        className={classNames(!circular && '-notcircular', className)}
        resultRenderer={this.renderResult}
        {...props}
      />
    );
  }
}

export default UserHashtagSearchBar;
