import React, { Component } from 'react';
import { Feed } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import moment from 'moment';
import classNames from 'classnames';

import SvgImage from '../SvgImage';
import { TROPHY_DATA } from '../../assets/images/trophies';

class TrophyEvent extends Component {
  selectMomentedDate = createSelector(
    props => props.event,
    event => moment(event.dateCreated),
  );

  render() {
    const {
      event: {
        data: { text, trophy },
        unseen,
      },
      currentUserState,
      className,
      ...props
    } = this.props;

    const trophyData = TROPHY_DATA[trophy];
    if (!trophyData) {
      console.warn(`<TrophyEvent />: unrecognised trophy '${trophy}' `);
    }

    const { name = text, criteria, image = {} } = trophyData || {};
    const { svg, png } = image;

    const dateCreated = this.selectMomentedDate(this.props);

    return (
      <Feed.Event
        className={classNames(
          'event-notification',
          unseen && '-unseen',
          className,
        )}
        {...props}
      >
        {image && (
          <Feed.Label>
            <SvgImage src={svg} fallback={png} alt={trophy} />
          </Feed.Label>
        )}
        <Feed.Content>
          <Feed.Summary>
            {name}
            <Feed.Date>{dateCreated.fromNow(true)}</Feed.Date>
          </Feed.Summary>
          {criteria && <Feed.Extra>{criteria}</Feed.Extra>}
        </Feed.Content>
      </Feed.Event>
    );
  }
}

export default TrophyEvent;
