/**
 * The `humanise.js` module contains all functions related to humanising
 * or stringifying data.
 */

/**
 * Converts a number in kilos.
 * @param { number } number The number to convert.
 * @param { number } [precision = 0] The number of decimals of the resulting number.
 * @returns { string } A string representing the number converted in kilos, followed by 'k'.
 *
 * Examples:
 * kifyNumber(38487, 1) => `38.4k`.
 * kifyNumber(38487, 3) => `38.487k`.
 */
export const kifyNumber = function kifyNumber(number, precision = 0) {
  if (isNaN(number) || isNaN(precision)) {
    return undefined;
  }

  const numberKs = number / 1000;
  const factor = Math.pow(10, precision);
  const truncatedNumberKs = Math.trunc(numberKs * factor) / factor;

  return `${truncatedNumberKs}k`;
};

/**
 * Pluralises a word if necessary, depending on the number of items.
 * @param { string } noun The noun to pluralise.
 * @param { number } [nb = 2] The number of items.
 * @returns { string } If nb > 1, the noun finishing with an 's', else
 * just the noun.
 */
export const pluralise = function pluralise(noun, nb = 2) {
  if (nb > 1 || nb === 0) {
    let pluralNoun;
    const lastLetters = noun.slice(-2);

    if (lastLetters.match(/.s|.x|.z|ch|sh/)) {
      pluralNoun = `${noun}es`;
    } else if (lastLetters[1] === 'y') {
      pluralNoun = `${noun.slice(0, -1)}ies`;
    } else {
      pluralNoun = `${noun}s`;
    }

    return pluralNoun;
  }

  return noun;
};

const punctuationRegex = /[!?.:;…,“”()]/;

/**
 * Add a mark of punctuation at the end of the string. If it is already there,
 * just returns the text.
 * @param { string } text The text to punctuate.
 * @param { string } [ mark = '.' ] The mark of punctuation to use.
 * @returns { string } The text with the specified mark of punctuation at the end.
 */
export const punctuate = function punctuate(text, mark = '.') {
  const lastChar = text[text.length - 1];

  if (lastChar !== mark) {
    return lastChar.match(punctuationRegex)
      ? `${text.slice(0, -1)}${mark}`
      : `${text}${mark}`;
  }
  return text;
};
