import withLazyLoadedReactiveData from '../../components/higher-order/withLazyLoadedReactiveData';
import Comments from '../../components/post/Comments';
import { subscribeToPostComments } from '../../services/posts';

const CommentsContainer = withLazyLoadedReactiveData({
  subscribeToData: subscribeToPostComments,
  nbByLoad: 30,
  nbInitial: 3,
})(Comments);

export default CommentsContainer;
