import backSquat from './back_squat-01.png';
import benchPress from './bench_press-01.png';
import cleanAndJerk from './clean_and_jerk-01.png';
import deadLift from './dead_lift-01.png';
import frontSquat from './front_squat-01.png';
import hangClean from './hang_clean-01.png';
import hangSnatch from './hang_snatch-01.png';
import inclineBench from './incline_bench-01.png';
import legpress from './legpress-01.png';
import muscleUp from './muscle_up-01.png';
import overheadSquat from './overhead_squat-01.png';
import powerClean from './power_clean-01.png';
import powerSnatch from './power_snatch-01.png';
import pullUps from './pull_ups-01.png';
import pushUps from './push_ups-01.png';
import situps from './situps-01.png';

const activityAssets = {
  'sit-up': situps,
  'push-up': pushUps,
  'pull-up': pullUps,
  deadlift: deadLift,
  'back squat': backSquat,
  'front squat': frontSquat,
  'hang clean': hangClean,
  'bench press': benchPress,
  'clean and jerk': cleanAndJerk,
  'muscle up': muscleUp,
  'overhead squat': overheadSquat,
  'power clean': powerClean,
  'power snatch': powerSnatch,
  'hang snatch': hangSnatch,
  'incline bench': inclineBench,
  'leg-press': legpress,
};

export const activityExerciseAsset = function activityExerciseAsset(
  activityName = '',
) {
  return activityAssets[activityName.toLowerCase()];
};
