import React, { Component } from 'react';

import UserFeed from '../containers/feed/UserFeed';
import ContentContainer from '../components/ContentContainer';

class FeedPage extends Component {
  render() {
    return (
      <ContentContainer size="big" style={{ maxWidth: '950px' }}>
        <UserFeed />
      </ContentContainer>
    );
  }
}

export default FeedPage;
