import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { Loader, Menu, Progress } from 'semantic-ui-react';

import ContentContainer from '../../components/ContentContainer';
import HeaderSegment from '../../components/HeaderSegment';
import RouterMenuItem from '../../components/RouterMenuItem';
import { injectParamsIntoRoute, routes } from '../../modules/routes';
import EditProfilePage from './EditProfilePage';
import { LoggedInUserContext } from '../../components/higher-order/withLoggedInUser';
import EditAboutYouPage from './EditAboutYouPage';
import RequestVerifiedAccountPage from './RequestVerifiedAccountPage';
import EditPreferencesPage from './EditPreferencesPage';

const urls = {
  aboutYou: '/about-you',
  requestVerification: '/request-verification',
  preferences: '/preferences',
};

class AccountPage extends Component {
  state = {
    progress: undefined,
  };

  handleProgressChange = progress => {
    this.setState({ progress });
  };

  render() {
    const {
      match: { path, url },
      isLoading,
      user,
      requestRefreshData,
    } = this.props;
    const { progress } = this.state;

    const passDownProps = {
      user,
      onChange: this.handleFormChange,
      onProgressChange: this.handleProgressChange,
    };

    return (
      <ContentContainer size="large" align="left">
        <LoggedInUserContext.Consumer>
          {({ isLoading, profile }) => {
            if (isLoading) return <Loader inline active size="tiny" />;

            const { username } = profile;
            const profileUrl = injectParamsIntoRoute(routes.user.profile, {
              username,
            });

            return (
              <Link className="navigation-link" to={profileUrl}>
                Back to Your Profile
              </Link>
            );
          }}
        </LoggedInUserContext.Consumer>
        <HeaderSegment header="Manage your profile" className="-sidemenu">
          <Menu vertical secondary>
            <RouterMenuItem exact to={url}>
              Your profile
            </RouterMenuItem>
            <RouterMenuItem to={`${url}${urls.aboutYou}`}>
              About you
            </RouterMenuItem>
            <RouterMenuItem to={`${url}${urls.requestVerification}`}>
              Request verification
            </RouterMenuItem>
            <RouterMenuItem to={`${url}${urls.preferences}`}>
              Preferences
            </RouterMenuItem>
          </Menu>
          <div className="content">
            <Loader inline="centered" active={isLoading} />
            {!isLoading && (
              <Switch>
                <Route
                  path={path}
                  exact
                  render={props => (
                    <EditProfilePage
                      {...props}
                      {...passDownProps}
                      onUpdateSuccess={requestRefreshData}
                    />
                  )}
                />
                <Route
                  path={`${path}${urls.aboutYou}`}
                  render={props => (
                    <EditAboutYouPage
                      {...props}
                      {...passDownProps}
                      onUpdateSuccess={requestRefreshData}
                    />
                  )}
                />
                <Route
                  path={`${path}${urls.requestVerification}`}
                  render={props => (
                    <RequestVerifiedAccountPage
                      {...props}
                      {...passDownProps}
                      onUpdateSuccess={requestRefreshData}
                    />
                  )}
                />
                <Route
                  path={`${path}${urls.preferences}`}
                  render={props => (
                    <EditPreferencesPage
                      {...props}
                      {...passDownProps}
                      onUpdateSuccess={requestRefreshData}
                    />
                  )}
                />
              </Switch>
            )}
          </div>
          {progress !== undefined && (
            <Progress
              percent={progress}
              autoSuccess
              active={progress < 100}
              indicating
              attached="bottom"
            />
          )}
        </HeaderSegment>
      </ContentContainer>
    );
  }
}

export default AccountPage;
