import { getLoggedInUid } from '../services/authentication';
import { getUser, getPublicUser } from '../services/users';

/**
 * @module modules/user-mentions
 * Module containing util functions dedicated to user mentions in text.
 */

/**
 * Generates a patterned string for user mentions.
 * @param { object } user
 * @param { string } user.uid The uid of the user.
 * @param { string } user.username The user's username or text to use.
 * @returns { string } A string following the pattern for user mentions.
 */
export const makeUserMention = function makeUserMention(user) {
  if (!user) {
    return '';
  }
  const { uid, username } = user;
  return `[@${username}:${uid}]`;
};

/**
 * Creates a new string where the beginning of a mention at the end of text
 * (e.g. @foo) is replaced with a complete mention.
 * @param { string } text The text to insert the mention in.
 * @param { object } user The user to mention.
 * @param { string } user.uid The uid of the user.
 * @param { string } user.username The user's username or text to use.
 * @returns { string } A new string containing the mention. If the mention is
 * not generated successfully, returns the original text.
 */
export const completeUserMentionInText = function completeUserMentionInText(
  text,
  user,
) {
  const mention = makeUserMention(user);
  return mention ? text.replace(/@\w+$/, `${mention} `) : text;
};

export const userMentionRegex = /\[(@[^:]+):([^\]]+)\]/g;
export const usernameRegex = /@([^:\s]+)/g;
export const usernameAndMentionRegex = /(?:@([^:\s]+))|(?:\[@([^:]+):[^\]]+\])/g;

/**
 * Replaces available usernames in a text (e.g. `@foobar`) by the patterned mentions.
 * @param { string } text The text to insert the mentions in.
 * @param { function } getUser The function to call the user's data (must return
 * an object).
 * @returns { string } A new string with the usernames replaced by mentions.
 */
export const replaceUsernameByMentions = function replaceUsernameByMentions(
  text,
  getUser,
) {
  const updatedText = text.replace(
    usernameAndMentionRegex,
    (match, username) => {
      // If the found string matches the mention pattern, leave it alone!!! I am serious!!
      if (match.match(userMentionRegex)) {
        return match;
      }

      const user = getUser(username);
      if (!user) {
        return match;
      }
      const mention = makeUserMention(user);
      return mention;
    },
  );

  return updatedText;
};

/**
 * Injects the missing user mentions into the text using API calls to fetch missing
 * users.
 * @param { String } text The text to inject mentiosn in.
 * @returns { Promise<String> } A Promise that resolves with the new string
 * containig the updated text.
 */
export const addMissingUserMentionsUsingApi = async function addMissingUserMentionsUsingApi(
  text,
) {
  // Add missing user mentions
  const usernamesMissingUid = new Set();
  let match;

  // Look for missing usernames, use Set to avoid duplicates
  while ((match = usernameAndMentionRegex.exec(text))) {
    const [fullMatch, username] = match;
    if (!fullMatch.match(userMentionRegex)) {
      usernamesMissingUid.add(username);
    }
  }

  if (usernamesMissingUid.size === 0) {
    return text;
  }

  // Some users are missing, so fetch them using the API
  const apiGetUser = getLoggedInUid() ? getUser : getPublicUser;
  const missingUsers = await Promise.all(
    [...usernamesMissingUid].map(username => apiGetUser({ username })),
  );

  const updatedText = replaceUsernameByMentions(text, username =>
    missingUsers.find(user => user.username === username),
  );

  return updatedText;
};
