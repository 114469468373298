import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';

/**
 * @callback subscribeToDataFunction
 * @param { Object } params
 * @param { onReceiveDataFunction } params.onReceiveDataFunction The handler to call
 * when receiving data.
 * @param { onError } params.onError The error handler function.
 * @returns { Function } The function to call to unsuscribe.
 */

/**
 * @callback onReceiveDataFunction
 * @param { Object } params
 * @param { Object[] } params.added The subscribed documents that were added.
 * @param { Object[] } params.removed The subscribed documents that were removed.
 * @param { Object[] } params.updated The subscribed documents that were updated.
 */

/**
 * Gets a new parametised higher-order component with reactive data.
 * The wrapped component is provided with data, and a function to get more data.
 *
 * @param { Object } params
 * @param { subscribeToDataFunction } params.subscribeToData The API call to subscribe
 * to data.
 * @param { string } [ params.dataPropName = 'data' ] The name of the prop to give to the wrapped component.
 * @param { string } [ params.paramsPropName = 'subscriptionParams' ] The name
 * of the props to get the fetching parameters from in the HoC.
 * @param { boolean } [ params.passDownParams = false ] true to pass down the
 * `fetchParams` props in the wrapped component, false else.
 * @param { boolean } [ params.wrapProps = false ] true to wrap all the props passed down
 * in a single prop object.
 * @returns { Function } A higher-order component function to wrap a component with data.
 */
const withReactiveData = function withReactiveData({
  subscribeToData,
  dataPropName = 'data',
  paramsPropName = 'subscriptionParams',
  passDownParams = false,
  wrapProps = false,
}) {
  return WrappedComponent => {
    const wrappedDisplayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const makeGetData = () =>
      createSelector(
        state => state.data,
        (dataMap, ignoredDataSet) => {
          return [...dataMap.values()];
        },
      );

    class WithReactiveData extends Component {
      static displayName = `WithReactiveData(${wrappedDisplayName})`;

      static propTypes = {
        [paramsPropName]: PropTypes.object,
      };

      static defaultProps = {
        [paramsPropName]: {},
      };

      static get warningUnsuscribe() {
        return () =>
          console.warn(
            'Warning: unsuscribe(): Called unsuscribe before having suscribed to any data.',
          );
      }

      static get initialState() {
        return {
          data: new Map(),
          error: undefined,
          isLoading: true,
        };
      }

      state = WithReactiveData.initialState;

      unsuscribe = WithReactiveData.warningUnsuscribe;

      madeGetData = makeGetData();

      componentDidMount() {
        this.subscribeToData();
      }

      componentWillReceiveProps(nextProps) {
        const { [paramsPropName]: fetchParams } = this.props;

        // If fetch params change, reset whole state and subscription
        if (!isEqual(fetchParams, nextProps[paramsPropName])) {
          this.unsuscribe();

          this.setState(WithReactiveData.initialState, () =>
            this.subscribeToData(),
          );
        }
      }

      componentWillUnmount() {
        this.unsuscribe();
      }

      getData() {
        return this.madeGetData(this.state);
      }

      /**
       * Creates a new subscription to data.
       */
      subscribeToData() {
        const { [paramsPropName]: subscriptionParams } = this.props;
        const { isLoading } = this.state;

        if (!isLoading) {
          this.setState({ isLoading: true });
        }

        const newSubscription = subscribeToData({
          ...subscriptionParams,
          onReceiveData: this.handleReceiveData,
          onError: this.handleError,
        });

        // Keep `unsuscribe` function for later use
        this.unsuscribe = newSubscription;
      }

      /**
       * Handles the document changes by updating the state.
       * @param { Object } params
       * @param { Object[] } params.added The pieces of data that were added to the subscription.
       * @param { Object[] } params.modified The pieces of data that were modified in the subscription.
       * @param { Object[] } params.removed The pieces of data that were removed from the subscription.
       */
      handleReceiveData = ({ added, modified, removed }) => {
        /**
         * The folowing functions return arrays in this structure [uid, data]. The
         * reason behind it is that we have a Map in the state, and `immutability-helper`
         * uses this format for the $add operator.
         */
        const piecesToAdd = added.map(piece => [piece.uid, piece]);
        const piecesToUpdate = modified.map(piece => [piece.uid, piece]);
        const piecesToRemove = removed.map(({ uid }) => uid);

        const query = {
          data: {
            $add: piecesToAdd.concat(piecesToUpdate),
            $remove: piecesToRemove,
          },
          isLoading: { $set: false },
        };

        this.setState(update(this.state, query));
      };

      handleError = error => {
        this.setState({ error });
      };

      render() {
        const {
          [paramsPropName]: subscriptionParams,
          isLoading: isLoadingProp,
          ...wrappedProps
        } = this.props;
        const { isLoading } = this.state;

        // Smart props from HoC to pass down
        const propWrapper = {
          isLoading: isLoadingProp || isLoading,
          [wrapProps ? 'data' : dataPropName]: this.getData(),
        };

        // Pass down the params prop if asked to
        if (passDownParams) {
          propWrapper[paramsPropName] = subscriptionParams;
        }

        // Either wrap all the props with an object, or pass them directly as props
        const extraProps = wrapProps
          ? { [dataPropName]: propWrapper }
          : propWrapper;

        return <WrappedComponent {...extraProps} {...wrappedProps} />;
      }
    }

    return WithReactiveData;
  };
};

export default withReactiveData;
