import React, { Component } from 'react';
import Comments from '../../components/post/Comments';
import { arrayOf, shape, instanceOf, number, string, oneOf } from 'prop-types';
import {
  makeSelectConcatenatedSlicesFromEnd,
  makeSelectOrderedData,
  makeSelectEndSlicedData,
} from '../../modules/selectors';
import { createSelector } from 'reselect';

class PartiallyLoadedComments extends Component {
  static propTypes = {
    data: arrayOf(
      shape({
        createdAt: instanceOf(Date),
      }),
    ).isRequired,
    nbBySlice: number,
    nbFirstSlice: number,
    orderBy: shape({
      field: string.isRequired,
      orderBy: oneOf(['asc', 'desc']),
    }),
  };

  static defaultProps = {
    nbBySlice: 30,
    nbFirstSlice: 3,
    orderBy: {
      field: 'dateCreated',
      orderBy: 'asc',
    },
  };

  state = {
    index: 0,
  };

  /** Selector from props data to ordered data. */
  selectOrderedData = makeSelectOrderedData((_, props) => props.data);

  /** Selector from ordered data to sliced data. */
  selectSlicedData = makeSelectEndSlicedData(this.selectOrderedData);

  /** Selector from sliced data to concatenated data to display. */
  selectDataToDisplay = makeSelectConcatenatedSlicesFromEnd(
    this.selectSlicedData,
  );

  /** Selector to check if there's no data left to display or not. */
  selectIsLastBatch = createSelector(
    this.selectSlicedData,
    state => state.index,
    (slicedData, index) => index + 1 >= slicedData.length,
  );

  getDataToDisplay() {
    return this.selectDataToDisplay(this.state, this.props);
  }

  getIsLastBatch() {
    return this.selectIsLastBatch(this.state, this.props);
  }

  handleRequestMoreData = () => {
    if (!this.getIsLastBatch()) {
      this.setState(state => ({ index: state.index + 1 }));
    }
  };

  render() {
    const {
      data,
      nbBySlice,
      nbFirstSlice,
      orderBy,
      ...otherProps
    } = this.props;

    const extraProps = {
      data: this.getDataToDisplay(),
      isLastBatch: this.getIsLastBatch(),
      requestMoreData: this.handleRequestMoreData,
    };

    return <Comments {...otherProps} {...extraProps} />;
  }
}

export default PartiallyLoadedComments;
