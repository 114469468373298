// SVG
import nightOwlSvg from './nightowelTrophy.svg';
import earlyBirdSvg from './earlybirdTrophy.svg';
import arnoldSvg from './arnoldTrophy.svg';
import manBestSvg from './menbestTrophy.svg';
import womanBestSvg from './womanbestTrophy.svg';
import gymRatSvg from './gymratTrophy.svg';
import crownSvg from './kingjamesTrophy.svg';
import pilotSvg from './pilotTrophy.svg';
import twoPlatesSvg from './twoPlates.svg';
import prSvg from './prTrophy.svg';
import profileSvg from './profileTrophy.png';

// Png
import nightOwlPng from './nightowelTrophy.png';
import earlyBirdPng from './earlybirdTrophy.png';
import arnoldPng from './arnoldTrophy.png';
import manBestPng from './menbestTrophy.png';
import womanBestPng from './womanbestTrophy.png';
import gymRatPng from './gymratTrophy.png';
import crownPng from './kingjamesTrophy.png';
import pilotPng from './pilotTrophy.png';
import twoPlatesPng from './twoPlates.png';
import prPng from './prTrophy.png';
import profilePng from './profileTrophy.png';

import { TROPHIES } from '../../../modules/constants/trophies';

export const TROPHY_ASSETS = {
  [TROPHIES.nightOwl]: {
    svg: nightOwlSvg,
    png: nightOwlPng,
  },
  [TROPHIES.personalRecord]: {
    svg: earlyBirdSvg,
    png: earlyBirdPng,
  },
  [TROPHIES.arnold]: {
    svg: arnoldSvg,
    png: arnoldPng,
  },
  [TROPHIES.mrWorldWide]: {
    svg: manBestSvg,
    png: manBestPng,
  },
  [TROPHIES.mrsWorldWide]: {
    svg: womanBestSvg,
    png: womanBestPng,
  },
  [TROPHIES.gymRat]: {
    svg: gymRatSvg,
    png: gymRatPng,
  },
  [TROPHIES.watchTheThrone]: {
    svg: crownSvg,
    png: crownPng,
  },
  [TROPHIES.pilot]: {
    svg: pilotSvg,
    png: pilotPng,
  },
  [TROPHIES.twoPlateBadge]: {
    svg: twoPlatesSvg,
    png: twoPlatesPng,
  },
  [TROPHIES.personalRecord]: {
    svg: prSvg,
    png: prPng,
  },

  [TROPHIES.onboardingComplete]: {
    svg: profileSvg,
    png: profilePng,
  },
};

export const TROPHY_DATA = {
  [TROPHIES.nightOwl]: {
    image: TROPHY_ASSETS[TROPHIES.nightOwl],
    name: 'Night Owl',
    criteria: 'Post lift between 11 PM - 4 AM',
  },

  [TROPHIES.earlyBird]: {
    image: TROPHY_ASSETS[TROPHIES.earlyBird],
    name: 'Early Bird',
    criteria: 'Post lift between 5 AM - 7AM',
  },

  [TROPHIES.arnold]: {
    image: TROPHY_ASSETS[TROPHIES.arnold],
    name: 'Arnold',
    criteria: 'Ranking first in three different lift categories',
  },

  [TROPHIES.mrWorldWide]: {
    image: TROPHY_ASSETS[TROPHIES.mrWorldWide],
    name: 'Mr. World Wide',
    criteria: 'First place in a lift world wide for males',
  },

  [TROPHIES.mrsWorldWide]: {
    image: TROPHY_ASSETS[TROPHIES.mrsWorldWide],
    name: 'Mrs. World Wide',
    criteria: 'First place in a lift world wide for females',
  },

  [TROPHIES.watchTheThrone]: {
    image: TROPHY_ASSETS[TROPHIES.watchTheThrone],
    name: 'Watch the Throne',
    criteria: 'Rank first in a category in your hometown',
  },

  [TROPHIES.pilot]: {
    image: TROPHY_ASSETS[TROPHIES.pilot],
    name: 'Pilot',
    criteria: 'Post a lift in 2 different countries',
  },

  [TROPHIES.gymRat]: {
    image: TROPHY_ASSETS[TROPHIES.gymRat],
    name: 'Gym Rat',
    criteria: 'Post a qualifying lift every day for one week straight',
  },

  [TROPHIES.twoPlateBadge]: {
    image: TROPHY_ASSETS[TROPHIES.twoPlateBadge],
    name: 'Two Plates Badge',
    criteria: 'Post a verified lift of 225 pounds or heavier',
  },

  [TROPHIES.personalRecord]: {
    image: TROPHY_ASSETS[TROPHIES.personalRecord],
  },
  [TROPHIES.onboardingComplete]: {
    image: TROPHY_ASSETS[TROPHIES.onboardingComplete],
    name: 'Profile Complete',
    criteria: "You've completed your profile!",
  },
};
