import React from 'react';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { injectParamsIntoRoute, routes } from '../../modules/routes';

const UserSearchResult = function UserSearchResult({
  id,
  baseUrl,
  image,
  title,
  price,
}) {
  const url =
    baseUrl &&
    injectParamsIntoRoute(baseUrl, {
      userUid: price,
      username: title,
    });

  const imageNode = image && <Image src={image} />;

  const content = (
    <div className="content">
      <strong className="title">@{title}</strong>
    </div>
  );

  return baseUrl ? (
    <Link to={url} className="semantic-link resultwrapper">
      {imageNode}
      {content}
    </Link>
  ) : (
    <div className="resultwrapper">
      {imageNode}
      {content}
    </div>
  );
};

export default UserSearchResult;

/**
 * Higher-order component to pass a URL to the search results.
 * @param { String } baseUrl The template url to use in the link.
 * @returns { React.Component }
 */
export const makeLinkUserSearchResult = function makeLinkUserSearchResult(
  baseUrl,
) {
  return function LinkUserSearchResult(props) {
    return <UserSearchResult {...props} baseUrl={baseUrl} />;
  };
};

export const UserProfileLinkResult = makeLinkUserSearchResult(
  routes.user.profile,
);
