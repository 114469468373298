import { firebaseAuth } from './../modules/firebase';
import { throwInternalError } from '../modules/errors';

export const login = ({ email, password }) => {
  return firebaseAuth().signInWithEmailAndPassword(email, password);
};

/**
 * Signs in using Facebook as an authentication provider.
 *
 */
export const loginWithFacebook = function loginWithFacebook() {
  const facebookAuthProvider = new firebaseAuth.FacebookAuthProvider();

  return firebaseAuth()
    .signInWithPopup(facebookAuthProvider)
    .catch(throwInternalError);
};

export const getIdToken = async () => {
  const idToken = await firebaseAuth().currentUser.getIdToken();
  return idToken;
};

export const logout = () => {
  // Clear user token and profile data from localStorage
  return firebaseAuth().signOut();
};

export const getProfile = () => {
  // Using jwt-decode npm package to decode the token
  return firebaseAuth().currentUser;
};

/**
 * Gets whether a user is logged in or not.
 * @returns { boolean }
 */
export const getIsPublic = function getIsPublic() {
  return !firebaseAuth().currentUser;
};

/**
 * Gets the currently logged-in user's uid.
 * @returns { string? } undefined if no user is logged in.
 */
export const getLoggedInUid = function getLoggedInUid() {
  return firebaseAuth().currentUser
    ? firebaseAuth().currentUser.uid
    : undefined;
};
