import withData from '../../components/higher-order/withData';
import SinglePostItem from '../../components/post/SinglePostItem';
import { getPost } from '../../services/posts';

const SinglePostItemContainer = withData(getPost, {
  dataPropName: 'post',
  paramPropName: 'postUid',
  singleData: true,
})(SinglePostItem);

export default SinglePostItemContainer;
