import React, { Component } from 'react';
import { shape, string } from 'prop-types';

import { getActivity } from '../../services/activities';
import withData from '../../components/higher-order/withData';
import ActivityLeaderboardPage from '../../pages/LeaderboardPage/ActivityLeaderboardPage';

const ActivityContainer = withData(getActivity, {
  singleData: true,
  paramPropName: 'activityUid',
  dataPropName: 'activity',
  passDownParams: true,
})(ActivityLeaderboardPage);

class ActivityLeaderboardPageContainer extends Component {
  static propTypes = {
    match: shape({
      params: shape({
        activity: string.isRequired,
      }),
    }),
  };

  static defaultProps = {
    match: {
      params: {},
    },
  };

  render() {
    const {
      match: {
        params: { activity },
      },
    } = this.props;

    if (!activity) {
      throw new Error('Internal error: malformed url params');
    }

    return <ActivityContainer activityUid={activity} />;
  }
}

export default ActivityLeaderboardPageContainer;
