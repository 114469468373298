import { createSelector } from 'reselect';
import sortBy from 'lodash/orderBy';

import { sliceByN, customSort } from './utils';

/**
 * Makes a new selector for sliced data.
 * @param { function } [ dataSelector ] The data selector. Can be a reselect selector
 * or a function.
 * @returns { function } A reselect selector.
 */
export const makeSelectSlicedData = function makeSelectSlicedData(
  dataSelector = (_, props) => props.data,
) {
  return createSelector(
    dataSelector,
    (_, props) => props.nbBySlice,
    (_, props) => props.nbFirstSlice,
    (data, nbBySlice, nbFirstSlice = nbBySlice) => {
      const head = data.slice(0, nbFirstSlice);
      const tail = data.slice(nbFirstSlice);
      const tailSlices = sliceByN(tail, nbBySlice);

      return [head, ...tailSlices];
    },
  );
};

/**
 * Makes a new selector for sliced data, slicing starting from the end.
 * @param { function } [ dataSelector ] The data selector. Can be a reselect selector
 * or a function.
 * @returns { function } A reselect selector.
 */
export const makeSelectEndSlicedData = function makeSelectEndSlicedData(
  dataSelector = (_, props) => props.data,
) {
  return createSelector(
    dataSelector,
    (_, props) => props.nbBySlice,
    (_, props) => props.nbFirstSlice,
    (data, nbBySlice, nbFirstSlice = nbBySlice) => {
      const tail = data.slice(-nbFirstSlice);
      const head = data.slice(0, -nbFirstSlice);
      const headSlices = sliceByN(head, nbBySlice, true);

      return [...headSlices, tail];
    },
  );
};

/**
 * Makes a new selector to concatenate slices based on an index, using slices from
 * the beginning to the index (included).
 * @param { function } slicesSelector The slices reselect selector (usually an instance made
 * by #makeSelectSlicedData.
 * @param { function } [ indexSelector ] The index selector.
 * @returns { function } A reselect selector.
 */
export const makeSelectConcatenatedSlices = function makeSelectConcatenatedSlices(
  slicesSelector,
  indexSelector = state => state.index,
) {
  return createSelector(
    slicesSelector,
    indexSelector,
    (slicedData, index) => {
      const slicesToDisplay = slicedData.slice(0, index + 1);
      return [].concat(...slicesToDisplay);
    },
  );
};

/**
 * Makes a new selector to concatenate slices based on an index, using slices from
 * the end to the index (included).
 * @param { function } slicesSelector The slices selector, either a reselect selector or a function.
 * @param { function } indexSelector The index selector.
 * @returns { function } A reselect selector.
 */
export const makeSelectConcatenatedSlicesFromEnd = function makeSelectConcatenatedSlicesFromEnd(
  slicesSelector,
  indexSelector = state => state.index,
) {
  return createSelector(
    slicesSelector,
    indexSelector,
    (slicedData, index) => {
      const slicesToDisplay = slicedData.slice(-index - 1);
      return [].concat(...slicesToDisplay);
    },
  );
};

/**
 * Makes a new selector to order data based on a criteria object using #customSort.
 * @param { function } selectData The data selector.
 * @param { function } [ selectOrderBy = props => props.orderBy ] The order by object selector.
 * @returns { function } A reselect selector.
 */
export const makeSelectOrderedData = function makeSelectOrderedData(
  selectData,
  selectOrderBy = (_, props) => props.orderBy,
) {
  return createSelector(
    selectData,
    selectOrderBy,
    (data, orderBy) => [...data].sort(customSort(orderBy)),
  );
};

/**
 * Makes a new selector to order data by date.
 * @param { function } selectData The data selector.
 * @param { string } [ dateField = 'dateCreated' ] The date field to sort by.
 * @param { string } [ order = 'desc' ] The order in which to sort ('asc' or 'desc').
 * @returns { function } A reselect selector.
 */
export const makeSelectOrderedByDate = function makeSelectOrderedByDate(
  selectData,
  dateField = 'dateCreated',
  order = 'desc',
) {
  return createSelector(
    selectData,
    data => {
      return sortBy(data, dateField, order);
    },
  );
};

/**
 * Makes a new selector to order rankings by ascending rank.
 * @param { function } [ selectRankings = props => props.rankings ] The rankings selector.
 * @param { function } [ selectGroupedBy = props => props.groupedBy ] The groupedBy selector.
 * If `groupedBy` is a truey value, the selector will treat the rankings as grouped by weight.
 * @returns { function } A reselect selector.
 */
export const makeSelectOrderedRankings = function makeSelectOrderedRankings(
  selectRankings = (_, props) => props.rankings,
  selectGroupedBy = (_, props) => props.groupedBy,
) {
  return createSelector(
    selectRankings,
    selectGroupedBy,
    (rankings, groupedBy) => {
      let rankingsToSort = rankings;
      let sorters = ['rank'];

      if (groupedBy) {
        // Sort rankings inside each weight group
        rankingsToSort = rankings.map(({ data, ...ranking }) => {
          return {
            ...ranking,
            data: sortBy(data, sorters, 'asc'),
          };
        });

        // And sort group by the rank of the first item
        sorters = group => (group.data[0] || {}).rank;
      }
      return sortBy(rankingsToSort, sorters, 'asc');
    },
  );
};
