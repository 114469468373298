import React, { Component } from 'react';
import { string, bool } from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import ScrollingModal from '../ScrollingModal';
import SpotterList from '../../containers/relationships/SpotterList';

class SpotterListModal extends Component {
  static propTypes = {
    userUid: string.isRequired,
    withSpottingButton: bool,
  };

  static defaultProps = {
    withSpottingButton: false,
  };

  render() {
    const { userUid, withSpottingButton, ...modalProps } = this.props;
    const { onClose } = modalProps;

    return (
      <ScrollingModal {...modalProps}>
        <Modal.Header>
          <Button floated="right" className="tertiary" onClick={onClose}>
            Close
          </Button>
          Spotters
        </Modal.Header>
        <Modal.Content>
          <SpotterList
            userUid={userUid}
            relaxed
            withSpottingButton={withSpottingButton}
          />
        </Modal.Content>
      </ScrollingModal>
    );
  }
}

export default SpotterListModal;
