import React, { Component, Fragment } from 'react';
import { node, bool } from 'prop-types';
import { oneOfType, string, element } from 'prop-types';
import { Menu, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import { withRouter } from 'react-router';

import { getLoggedInUid } from '../../services/authentication';
import ProfileDropdown from './ProfileDropdown';
import RouterMenuItem from '../../components/RouterMenuItem';
import Icomoon from '../../components/icons/Icomoon';

class HeaderBar extends Component {
  static propTypes = {
    as: oneOfType([string, element]),
    children: node,
    isNotificationPopupOpen: bool,
  };

  static defaultProps = {
    as: 'header',
    isNotificationPopupOpen: false,
  };

  handleItemClick = {
    notifications: () => {
      const { onItemClick } = this.props;
      return (
        onItemClick && onItemClick({}, { target: { name: 'notifications' } })
      );
    },
  };

  render() {
    const {
      as: As,
      children,
      className,
      isNotificationPopupOpen,
      nbUnseenNotifications,
      onItemClick,
      match,
      location,
      history,
      staticContext,
      ...otherProps
    } = this.props;

    return (
      <Fragment>
        <Menu className={classNames('header-bar', className)} {...otherProps}>
          {children}
          {getLoggedInUid() && (
            <Menu.Menu position="right">
              <Menu.Item
                aria-label="Your notifications"
                name="notifications"
                onClick={this.handleItemClick.notifications}
                className={classNames(
                  'notificationitem',
                  'toggle',
                  isNotificationPopupOpen ? 'active' : undefined,
                )}
              >
                <Icomoon
                  name="notifications"
                  filled={isNotificationPopupOpen}
                />
                {nbUnseenNotifications ? (
                  <Label color="red" circular floating size="small">
                    {nbUnseenNotifications}
                  </Label>
                ) : (
                  undefined
                )}
              </Menu.Item>
              <ProfileDropdown />
            </Menu.Menu>
          )}

          {!getLoggedInUid() && location.pathname !== '/' && (
            <RouterMenuItem position="right" to="/login">
              Sign in
            </RouterMenuItem>
          )}
        </Menu>
        {isNotificationPopupOpen && (
          <div
            className="popup-mask"
            onClick={this.handleItemClick.notifications}
            aria-hidden
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(HeaderBar);
