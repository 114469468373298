import React, { Component } from 'react';
import {
  oneOfType,
  string,
  element,
  shape,
  arrayOf,
  bool,
  func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import ActivityCard from './ActivityCard';
import { injectParamsIntoRoute, routes } from '../../modules/routes';

class ActivityGrid extends Component {
  static propTypes = {
    activities: arrayOf(
      shape({
        uid: string.isRequired,
      }).isRequired,
    ).isRequired,
    as: oneOfType([string, element, func]),
    isLoading: bool,
  };

  static defaultProps = {
    activities: [],
    as: 'div',
    isLoading: false,
  };

  render() {
    const { as: As, activities, isLoading } = this.props;

    const activityNodes = activities.map(activity => (
      <li key={activity.uid} className="activity-item">
        <ActivityCard
          as={Link}
          to={injectParamsIntoRoute(routes.leaderboard.activity, {
            activity: activity.uid,
            type: activity.type[0],
          })}
          data={activity}
        />
      </li>
    ));

    return (
      <As className="activity-grid">
        <Loader active={isLoading} size="large" inline="centered" />
        {!isLoading && <ul className="activity-list">{activityNodes}</ul>}
      </As>
    );
  }
}

export default ActivityGrid;
