import React from 'react';
import classNames from 'classnames';
import AppStoreBadge from './AppStoreBadge';
import PlayStoreBadge from './PlayStoreBadge';

const propTypes = {};

const defaultProps = {};

const StoreBadges = function StoreBadges({ className }) {
  return (
    <div className={classNames('badge-list', className)}>
      <AppStoreBadge className="badge" />
      <PlayStoreBadge className="badge" />
    </div>
  );
};

StoreBadges.propTypes = propTypes;
StoreBadges.defaultProps = defaultProps;

export default StoreBadges;
