import React, { Component } from 'react';
import classNames from 'classnames';
import { oneOf, bool } from 'prop-types';

class AnimatedIcon extends Component {
  static propTypes = {
    name: oneOf(['fist-bump']).isRequired,
    size: oneOf(['small', 'medium', 'large']),
    animated: bool,
  };

  static defaultProps = {
    size: 'medium',
    animate: false,
  };

  render() {
    const { name, size, animated, animate } = this.props;

    return (
      <span
        aria-hidden
        className={classNames(
          'animated-icon',
          `-${name}`,
          `-${size}`,
          animate && '-animate',
          animated && '-animated',
        )}
      />
    );
  }
}

export default AnimatedIcon;
