import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { injectParamsIntoRoute, routes } from '../../modules/routes';

const HashtagSearchResult = function HashtagSearchResult({
  baseUrl,
  description,
  title,
}) {
  const [hashtag] = title.match(/\w+/) || [];
  const url =
    baseUrl &&
    injectParamsIntoRoute(baseUrl, {
      hashtag,
    });

  const imageNode = (
    <Icon name="hashtag" circular className="image" inverted color="blue" />
  );

  const content = (
    <div className="content">
      <strong className="title">{title}</strong>
      <div className="description">{description}</div>
    </div>
  );

  return baseUrl ? (
    <Link to={url} className="semantic-link resultwrapper">
      {imageNode}
      {content}
    </Link>
  ) : (
    <div className="resultwrapper">
      {imageNode}
      {content}
    </div>
  );
};

export default HashtagSearchResult;

/**
 * Higher-order component to pass a URL to the search results.
 * @param { String } baseUrl The template url to use in the link.
 * @returns { React.Component }
 */
export const makeLinkHashtagSearchResult = function makeLinkHashtagSearchResult(
  baseUrl,
) {
  return function LinkHashtagSearchResult(props) {
    return <HashtagSearchResult {...props} baseUrl={baseUrl} />;
  };
};

export const HashtagFeedLinkResult = makeLinkHashtagSearchResult(
  routes.hashtagFeed,
);
