import React, { Component } from 'react';
import { createSelector } from 'reselect';
import LiftRankingTable, {
  GroupRows,
  LiftRankingRow,
} from './LiftRankingTable';

class LiftRankingTableExtraLifts extends Component {
  static defaultProps = {
    fields: LiftRankingTable.defaultProps.fields,
  };

  selectRankings = createSelector(
    (_, props) => props.extraRankings,
    rankings => rankings,
  );

  renderRankings = (
    extraRankings = this.selectRankings(this.state, this.props),
  ) => {
    const { selectedUids, fields } = this.props;
    const uidsToSelect = !Array.isArray(selectedUids)
      ? [selectedUids]
      : selectedUids;

    return extraRankings.map(ranking => (
      <LiftRankingRow
        key={ranking.uid}
        ranking={ranking}
        active={uidsToSelect.includes(ranking.uid)}
        fields={fields}
      />
    ));
  };

  renderWeightGroups() {
    const { fields } = this.props;
    const weightGroups = this.selectRankings(this.state, this.props);

    return weightGroups.map(group => (
      <GroupRows
        key={`${group.kg}-group`}
        group={group}
        renderRows={this.renderRankings}
        fields={fields}
      />
    ));
  }

  render() {
    const {
      extraRankings: _,
      children,
      requestMoreData,
      ...props
    } = this.props;

    const { groupedBy } = props;

    const extraRankingsRows = groupedBy
      ? this.renderWeightGroups()
      : this.renderRankings();

    return (
      <LiftRankingTable {...props}>
        {children}
        {extraRankingsRows}
      </LiftRankingTable>
    );
  }
}

export default LiftRankingTableExtraLifts;
