import React, { Component, Fragment } from 'react';
import { oneOfType, string, element, func } from 'prop-types';
import { createSelector } from 'reselect';
import isString from 'lodash/isString';
import { injectParamsIntoRoute, routes } from '../modules/routes';
import { Link } from 'react-router-dom';
import { userMentionRegex } from '../modules/user-mentions';
import { replaceTextWithNodes } from '../modules/utils';

const hashtagRegex = /#(\w+)/g;

/**
 * Searches for hashtags in a string, and create a React-renderable list containing the
 * Link nodes to the hashtags.
 * @param { string } child The string to add hashtag links to.
 * @returns { Array|String } An array of strings and React nodes that can be rendered
 * if hashtags were found, else the just the string.
 */
const hashtagChild = function hashtagChild(child) {
  // List hashtags matches
  const hashtags = [];
  let match;
  while ((match = hashtagRegex.exec(child))) {
    if (match) {
      const [matchedText, hashtag] = match;
      const { index } = match;

      const data = {
        matchedText,
        hashtag,
        index,
      };
      hashtags.push(data);
    }
  }

  // Replace hashtag texts by hashtag links
  const hashtaggedChild = replaceTextWithNodes(
    child,
    hashtags,
    ({ hashtag, matchedText }) => {
      const hashtagUrl = injectParamsIntoRoute(routes.hashtagFeed, {
        hashtag,
      });

      return (
        <Link className="hashtag" to={hashtagUrl}>
          {matchedText}
        </Link>
      );
    },
  );

  return hashtaggedChild;
};

/**
 * Searches for user mentons in a string, and create a React-renderable list
 * containing the Link nodes to users' profiles.
 * @param { string } child The string to replace mentions in.
 * @returns { Array|String } An array of strings and React nodes that can be rendered
 * if mentions were found, else the just the string.
 */
const insertUserMentionLinks = function insertUserMentionLinks(child) {
  // List user mentions with positions in text
  const mentions = [];
  let match;
  while ((match = userMentionRegex.exec(child))) {
    if (match) {
      const [matchedText, username, uid] = match;
      const { index } = match;

      const data = {
        matchedText,
        uid,
        username,
        index,
      };
      mentions.push(data);
    }
  }

  // Replace user mention patterns by user profile links
  const childWithUserMentions = replaceTextWithNodes(
    child,
    mentions,
    ({ username, uid }) => {
      const userProfileUrl = injectParamsIntoRoute(routes.user.profile, {
        username: uid,
      });

      const to = {
        pathname: userProfileUrl,
        search: '?useUid=true',
      };

      return (
        <Link className="usermention" to={to}>
          {username}
        </Link>
      );
    },
  );

  return childWithUserMentions;
};

class TextWithContentLinks extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
  };

  static defaultProps = {};

  selectHashtaggedText = createSelector(
    props => props.children,
    children => {
      return React.Children.map(children, child => {
        if (!isString(child) || !child) {
          return child;
        }

        return hashtagChild(child);
      });
    },
  );

  selectUserMentionnedText = createSelector(
    this.selectHashtaggedText,
    children => {
      return React.Children.map(children, child => {
        if (!isString(child) || !child) {
          return child;
        }

        /**
         * `selectHashtaggedText` returns a mixed array of String and arrays of
         * strings/nodes. If the child is an array, we need to explore deeply this
         * array and apply the transformation function.
         */
        return Array.isArray(child)
          ? child.map(grandchild => {
              if (!isString(grandchild) || !grandchild) {
                return grandchild;
              }
              return insertUserMentionLinks(grandchild);
            })
          : insertUserMentionLinks(child);
      });
    },
  );

  render() {
    const { as: As = Fragment, children, ...props } = this.props;
    const transformedChildren = this.selectUserMentionnedText(this.props);
    return <As {...props}>{transformedChildren}</As>;
  }
}

export default TextWithContentLinks;
