let config;

switch (process.env.REACT_APP_FLEXPIT_ENV) {
  case 'production': {
    config = require('../config/production');
    break;
  }

  case 'development':
  default: {
    config = require('../config/development');
    break;
  }
}

const { customApi, firebaseConfig } = config;

export { customApi, firebaseConfig };
export default config;
