import React, { Component } from 'react';
import { oneOfType, string, element } from 'prop-types';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import logoWordmark from '../../assets/images/logo_wordmark_black.png';

import MainNavigation from './MainNavigation';
import YourLeaderboardPositions from './YourLeaderboardPositions';
import { getLoggedInUid } from '../../services/authentication';
import MiscellaneousMenu from './MiscellaneousMenu';

class Sidebar extends Component {
  static propTypes = {
    as: oneOfType([string, element]),
  };

  static defaultProps = {
    as: 'div',
  };

  render() {
    const { as: As } = this.props;

    return (
      <As className="side-bar">
        <Link to="/">
          <Image src={logoWordmark} fluid />
        </Link>
        <MainNavigation as="nav" className="section" secondary vertical fluid />
        {getLoggedInUid() && (
          <YourLeaderboardPositions
            className="section"
            secondary
            vertical
            fluid
          />
        )}
        <MiscellaneousMenu fluid vertical text />
      </As>
    );
  }
}

export default Sidebar;
