import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { string, object } from 'prop-types';

import ButtonSwitch from '../../ButtonSwitch';

const weightOptions = ['lbs', 'kg'];
const heightOptions = ['ft', 'cm'];

class PreferencesForm extends Component {
  static propTypes = {
    formData: object.isRequired,
    id: string,
    data: object,
  };

  static defaultProps = {
    formData: {},
    id: 'preferences-form',
    data: {},
  };

  render() {
    const { id, onChange, data, formData, initialValue, ...props } = this.props;

    return (
      <Form id={id} {...props}>
        <Form.Field className="formrow">
          <label htmlFor={`${id}-units-weight`}>Weight unit</label>
          <div className="input">
            <ButtonSwitch
              onChange={onChange}
              name="units.weight"
              options={weightOptions}
              value={formData.units.weight || (data.units || {}).weight}
            />
          </div>
        </Form.Field>
        <Form.Field className="formrow">
          <label htmlFor={`${id}-units-height`}>Height unit</label>
          <div className="input">
            <ButtonSwitch
              onChange={onChange}
              name="units.height"
              options={heightOptions}
              value={formData.units.height || (data.units || {}).height}
            />
          </div>
        </Form.Field>
        <Form.Button primary type="submit" className="formrow">
          Save
        </Form.Button>
      </Form>
    );
  }
}

export default PreferencesForm;
