import React, { Component } from 'react';
import update from 'immutability-helper';
import { number, arrayOf, string } from 'prop-types';
import isObject from 'lodash/isObject';

import { reduceObject } from '../../modules/utils';

/**
 * Higher-order component to attach a cache system to a component. The wrapped
 * component gets access to a getter function 'get' that gets the required data
 * if already fetched, or fetches it before giving it to the component.
 *
 * The cache also comes with a expiration system so that after some time, data
 * is considered expired and needs to be refetched.
 *
 * @param { function } fetchData The fetch data function.
 * @param { object } config
 * @param { string } [ params.dataPropName = 'data' ] The name to give to the field
 * containing the data.
 * @param { string } [ params.wrapProps = '' ] Give a non-empty string to wrap
 * the props in one object.
 * @returns { function } A higher-order component taking a component as an argument.
 */
const withCache = function withCache(
  fetchData,
  { wrapProps = '', dataPropName = 'data' } = {},
) {
  return WrappedComponent => {
    const wrappedCompDisplayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    class WithCache extends Component {
      static displayName = `WithCache(${wrappedCompDisplayName})`;

      static propTypes = {
        fields: arrayOf(string),
        expirationTime: number, // Time in seconds before the data should be refetched
      };

      static defaultProps = {
        fields: [],
        expirationTime: 60,
      };

      state = {
        data: new Map(),
        timestamps: new Map(),
        errors: new Map(),
      };

      /**
       * @type { Map<String,Promise> } Pending Promises for each id. This is used
       * when a component requests data that is already being fetched to avoid
       * duplicate fetching.
       */
      promises = new Map();

      async requestData(uid) {
        const { fields } = this.props;

        // Show loading state for this uid
        this.setState(
          update(this.state, {
            errors: { $add: [[uid, undefined]] },
          }),
        );

        let error;
        let reducedData;
        let dataQuery = {};
        let timestampQuery = {};

        try {
          const data = await fetchData(uid);
          reducedData = reduceObject(data, ['uid', ...fields]);
          dataQuery = { data: { $add: [[uid, reducedData]] } };
          timestampQuery = { timestamps: { $add: [[uid, Date.now()]] } };
        } catch (internalError) {
          error = internalError;
        }

        // Hide loading state and update error/data
        this.setState(
          update(this.state, {
            ...dataQuery,
            ...timestampQuery,
            errors: error ? { $add: [[uid, error]] } : { $remove: [uid] },
          }),
        );

        return reducedData;
      }

      /**
       * Handler to get data from the cache. Can request the data if specified
       * to.
       * @param { string|object } query The unique identifier for the data to get,
       * or a query object to match against, for example { username: 'foobar' }.
       * @param { boolean } [ refresh = true ] True to request the data if missing or expired.
       * False to just return the result.
       * @returns { Promise<object>|object|undefined } A Promise that resolves with fresh data
       * if `refresh` = true. If `refresh` = false, the function returns an object
       * if the requested data is present in the cache, or undefined if it is
       * not present.
       */
      handleGetData = (query, refresh = true) => {
        const { expirationTime } = this.props;
        const { data: dataMap, timestamps } = this.state;

        let uid = query;

        // Find object using query if query is an object
        if (isObject(query)) {
          const entry =
            [...dataMap.entries()].find(([, object]) =>
              // Check that all keys in query have the same value as the object
              Object.keys(query).every(key => {
                return object[key] === query[key];
              }),
            ) || [];
          [uid] = entry;

          if (!uid) {
            return;
          }
        }

        // Get relevant information
        const data = dataMap.get(uid);
        const lastFetchedAt = timestamps.get(uid) || 0;
        const isLoading = this.promises.has(uid) || false;

        // If not asked to refresh, just return the data as it is
        if (!refresh) {
          return data;
        }

        // If loading, return the already loading promise
        if (isLoading) {
          return this.promises.get(uid);
        }

        // Check that the data has not expired, and return it if it's still fresh
        if (Date.now() - lastFetchedAt < expirationTime * 1000) {
          return Promise.resolve(data);
        }

        // Data has not been fetched or is expired and not loading, request it and return the promise of data
        const promise = this.requestData(uid).then(data => {
          this.promises.delete(uid);
          return data;
        });

        this.promises.set(uid, promise);
        return promise;
      };

      render() {
        const { ...props } = this.props;

        const propWrapper = {
          get: this.handleGetData,
        };

        const extraProps = wrapProps
          ? {
              [wrapProps]: propWrapper,
            }
          : propWrapper;

        return <WrappedComponent {...props} {...extraProps} />;
      }
    }

    return WithCache;
  };
};

export default withCache;
