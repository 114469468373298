import React, { Component, Fragment } from 'react';
import { Loader } from 'semantic-ui-react';
import { createSelector } from 'reselect';

import {
  getUserRankingForActivity,
  getLeaderboard,
} from '../../services/leaderboards';
import withData from '../../components/higher-order/withData';
import withLazyLoadedData from '../../components/higher-order/withLazyLoadedData';
import LiftRankingTable from '../../components/activities/LiftRankingTable';
import NoDataMessage from '../../components/NoDataMessage';

/**
 * Component to fetch extra rankings before and after user's already loaded rankings.
 */
const LeaderboardWithPreviousRankingsContainer = withLazyLoadedData(
  getLeaderboard,
  {
    dataPropName: 'rankings',
    paramPropName: 'criteria',
    passDownParams: true,
    // Sum the number of rankings in each group to calculate the offset
    combinerNumberData: groupMap => {
      const nbRankings = [...groupMap.values()].reduce(
        (sum, group) => sum + group.data.length,
        0,
      );

      return nbRankings;
    },
  },
)(
  class LeaderboardWithPreviousRankings extends Component {
    state = {
      isLoadingBefore: false,
    };

    selectRankings = createSelector(
      (_, props) => props.userSurroundingRankings,
      (_, props) => props.rankings,
      (userRankings, rankings) => userRankings.concat(rankings),
    );

    selectIsFirstBatch = createSelector(
      this.selectRankings,
      rankings => {
        if (!rankings[0]) {
          return true;
        }
        const { rank } = rankings[0].data[0] || {};
        return rank === 1;
      },
    );

    selectNbToFetchNext = createSelector(
      this.selectRankings,
      (_, props) => props.limit,
      this.selectIsFirstBatch,
      (rankings, limit, isFirstBatch) => {
        if (isFirstBatch) {
          return undefined;
        }

        const nbByFetch = Array.isArray(limit)
          ? limit[limit.length - 1]
          : limit;
        const { rank: currentHeadRank } = rankings[0].data[0];

        // If there is less than nbByFetch left to fetch, fetch this number, else cap to nbByFetch
        return Math.min(nbByFetch, currentHeadRank - 1);
      },
    );

    getRankings() {
      return this.selectRankings(this.state, this.props);
    }

    getIsFirstBatch() {
      return this.selectIsFirstBatch(this.state, this.props);
    }

    getNbToFetchNext() {
      return this.selectNbToFetchNext(this.state, this.props);
    }

    fetchPreviousData() {
      const { requestSpecificData, criteria } = this.props;

      if (this.getIsFirstBatch()) {
        return;
      }

      const { rank: currentHeadRank } = this.getRankings()[0].data[0];

      const nbToFetch = this.getNbToFetchNext();
      // Figure out from which rank we need to state fetching
      const offset = currentHeadRank - nbToFetch - 1;

      const params = {
        ...criteria,
        offset,
        limit: nbToFetch,
      };

      return requestSpecificData(params);
    }

    handleRequestPreviousData = () => {
      this.setState({ isLoadingBefore: true });
      return this.fetchPreviousData().then(() =>
        this.setState({ isLoadingBefore: false }),
      );
    };

    render() {
      const {
        rankings: _,
        isLoading,
        userSurroundingRankings,
        ...props
      } = this.props;
      const { isLoadingBefore } = this.state;

      const rankings = this.getRankings();
      const nbToFetchNextBefore = this.getNbToFetchNext();

      return (
        <LiftRankingTable
          requestPreviousData={this.handleRequestPreviousData}
          isFirstBatch={this.getIsFirstBatch()}
          rankings={rankings}
          isLoading={isLoading}
          isLoadingBefore={isLoadingBefore}
          nbToFetchNextBefore={nbToFetchNextBefore}
          {...props}
        />
      );
    }
  },
);

// Component to load the user's ranking and surrounding, and pass it down the previous component
const ActivityPersonalLeaderboardTableContainer = withData(
  getUserRankingForActivity,
  {
    dataPropName: 'userSurroundingRankings',
    paramPropName: 'criteria',
    passDownParams: true,
  },
)(
  class ActivityPersonalLeaderboardTable extends Component {
    selectMaxRanking = createSelector(
      (_, props) => props.userSurroundingRankings,
      userSurroundingRankings => {
        const ranks = userSurroundingRankings.reduce(
          (acc, group) =>
            acc.concat(group.data.map(ranking => parseInt(ranking.rank, 10))),
          [],
        );
        const maxRank = ranks.length !== 0 ? Math.max(...ranks) : undefined;
        return maxRank;
      },
    );

    renderNoDataMessage() {
      const {
        criteria: { userUid },
      } = this.props;
      return (
        <NoDataMessage>
          {!userUid // No useruid means we are viewing the current user's profile
            ? "Looks like you haven't posted a lift for this leaderboard yet. Try posting a lift via the app to take part."
            : 'User is not participating in this leaderboard yet.'}
        </NoDataMessage>
      );
    }

    render() {
      const {
        userSurroundingRankings,
        criteria: { activityUid, type, media, groupBy },
        isLoading,
        ...props
      } = this.props;

      const fetchMoreCriteria = {
        activityUid,
        type,
        media,
        groupBy,
      };

      return (
        <Fragment>
          {!isLoading &&
            (userSurroundingRankings.length === 0 ? (
              this.renderNoDataMessage()
            ) : (
              <LeaderboardWithPreviousRankingsContainer
                criteria={fetchMoreCriteria}
                userSurroundingRankings={userSurroundingRankings}
                originalOffset={this.selectMaxRanking(this.state, this.props)}
                {...props}
              />
            ))}
          <Loader inline="centered" active={isLoading} size="large" />
        </Fragment>
      );
    }
  },
);

export default ActivityPersonalLeaderboardTableContainer;
