import React, { Component } from 'react';
import { node, func } from 'prop-types';

import { getUser } from '../../services/users';
import withCachedData from '../../components/higher-order/withCache';
import { UserCacheContext } from '../../components/higher-order/withCachedUser';

class UserCacheComponent extends Component {
  static propTypes = {
    get: func.isRequired,
    children: node,
  };

  render() {
    const { children, get } = this.props;

    return (
      <UserCacheContext.Provider value={get}>
        {children}
      </UserCacheContext.Provider>
    );
  }
}

const UserCache = withCachedData(uid => getUser({ uid }))(UserCacheComponent);

UserCache.defaultProps.fields = [
  'username',
  'firstName',
  'lastName',
  'photoUrl',
];

export default UserCache;
