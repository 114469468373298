import PostItem from '../../components/post/PostItem';
import { subscribeToPostFistBumps } from '../../services/posts';
import withReactiveData from '../../components/higher-order/withReactiveData';

const PostItemContainer = withReactiveData({
  subscribeToData: subscribeToPostFistBumps,
  dataPropName: 'fistBumps',
})(PostItem);

export default PostItemContainer;
