import React, { Component } from 'react';
import PropTypes, {
  instanceOf,
  shape,
  bool,
  string,
  func,
  node,
} from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Comment, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { createSelector } from 'reselect';
import update from 'immutability-helper';

import { injectParamsIntoRoute, routes } from '../../modules/routes';
import TextWithContentLinks from '../TextWithContentLinks';
import { getLoggedInUid } from '../../services/authentication';
import AnimatedIcon from '../icons/AnimatedIcon';
import { fistBumpComment } from '../../services/posts';

const propTypes = {
  comment: PropTypes.object.isRequired,
};

const defaultProps = {};

class CommentItem extends Component {
  static propTypes = {
    comment: shape({
      dateCreated: instanceOf(Date).isRequired,
      text: string,
    }).isRequired,
    author: shape({
      isLoading: bool,
      data: shape({
        username: string.isRequired,
      }),
    }).isRequired,
    postUid: string.isRequired,
    children: node,
    onClickReply: func,
  };

  state = {
    fistBumping: {
      animate: false,
      isLoading: false,
      result: undefined,
      error: undefined,
    },
  };

  selectIsFistBumpedByMe = createSelector(
    state => state.fistBumping.result,
    (_, props) => props.comment.fistbumps,
    (stateValue, fistbumps) => {
      return stateValue !== undefined
        ? stateValue
        : Boolean(fistbumps) && fistbumps.includes(getLoggedInUid());
    },
  );

  async fistBumpComment() {
    const {
      comment: { uid },
      postUid,
    } = this.props;
    const currentValue = this.selectIsFistBumpedByMe(this.state, this.props);

    this.setState(
      update(this.state, {
        fistBumping: {
          animate: { $set: true },
          isLoading: { $set: true },
          result: { $set: !currentValue },
        },
      }),
    );

    let error = null;
    let result = {};

    try {
      await fistBumpComment({ uid, postUid, value: !currentValue });
    } catch (err) {
      console.error(err);
      error = err;
      result = { result: { $set: currentValue } };
    }

    this.setState(
      update(this.state, {
        isLoading: { $set: false },
        error: { $set: error },
        ...result,
      }),
    );
  }

  handleClickReply = () => {
    const {
      onClickReply,
      comment: { uid, parentUid },
      author: { data: author },
    } = this.props;

    // If replying to a reply, give the parent id, else give the id.
    return onClickReply({
      uid: parentUid || uid,
      author: author.uid === getLoggedInUid() ? '' : author.username,
    });
  };

  handleClickFistBump = () => this.fistBumpComment();

  render() {
    const {
      comment: { dateCreated, text },
      author: { data: author, isLoading },
      children,
      onClickReply,
    } = this.props;
    const { fistBumping } = this.state;

    const authorProfileUrl =
      author &&
      author.username &&
      injectParamsIntoRoute(routes.user.profile, { username: author.username });

    const isFistBumpedByMe = this.selectIsFistBumpedByMe(
      this.state,
      this.props,
    );

    return (
      <Comment>
        <Comment.Content>
          <Comment.Author as="strong">
            <Loader
              inline
              size="tiny"
              className={classNames('-notinverted', !isLoading && '_hidden')}
              active={isLoading}
            />

            {!isLoading && authorProfileUrl && (
              <Link to={authorProfileUrl} className="semantic-link">
                {author.username}
              </Link>
            )}
          </Comment.Author>
          <Comment.Metadata>{moment(dateCreated).fromNow()}</Comment.Metadata>
          <TextWithContentLinks as={Comment.Text}>{text}</TextWithContentLinks>
          {onClickReply && getLoggedInUid() && (
            <Comment.Actions>
              <Comment.Action
                as="button"
                className="link -tertiary"
                onClick={this.handleClickReply}
                disabled={isLoading}
              >
                Reply
              </Comment.Action>
              <Comment.Action
                as="button"
                className="button -semantic"
                onClick={this.handleClickFistBump}
                disabled={false}
              >
                <AnimatedIcon
                  name="fist-bump"
                  animate={fistBumping.animate && isFistBumpedByMe}
                  animated={isFistBumpedByMe}
                />
              </Comment.Action>
            </Comment.Actions>
          )}
        </Comment.Content>
        {children}
      </Comment>
    );
  }
}

CommentItem.propTypes = propTypes;
CommentItem.defaultProps = defaultProps;

export default CommentItem;
