import { updateCurrentUserProfile } from '../../../services/users';
import { withSubmitApiCall } from '../../../components/higher-order/withSubmitApiCall';
import DetailsForm from '../../../components/user/forms/DetailsForm';

const EditAboutYouForm = withSubmitApiCall(updateCurrentUserProfile)(
  DetailsForm,
);

EditAboutYouForm.defaultProps.parseData = function parseData(data) {
  const { weight, height } = data;

  let parsedWeight;
  if (weight) {
    parsedWeight = parseFloat(weight.kg);
    if (isNaN(parsedWeight)) {
      const error = new Error(`Invalid weight ${weight.kg}`);
      console.error(error);
      throw error;
    }
  }

  let parsedHeight;
  if (height) {
    parsedHeight = parseFloat(height.cm);
    if (isNaN(parsedHeight)) {
      const error = new Error(`Invalid height ${height.cm}`);
      console.error(error);
      throw error;
    }
  }

  return {
    ...data,
    weight: parsedWeight,
    height: parsedHeight,
  };
};

export default EditAboutYouForm;
