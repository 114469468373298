import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Image } from 'semantic-ui-react';

import withLoggedInUser, {
  LoggedInUserContext,
} from '../../components/higher-order/withLoggedInUser';
import { injectParamsIntoRoute, routes } from '../../modules/routes';
import { logout } from '../../services/authentication';

class ProfileDropdown extends Component {
  static propTypes = {
    currentUserState: LoggedInUserContext.propType,
  };
  render() {
    const {
      currentUserState: { isFetching, isLogged, profile },
    } = this.props;
    let trigger;

    if (isLogged && !isFetching && profile) {
      const { username, photoUrl } = profile;
      const profileUrl = injectParamsIntoRoute(routes.user.profile, {
        username,
      });

      trigger = (
        <Link to={profileUrl} style={{ textTransform: 'none' }}>
          {photoUrl && <Image avatar src={photoUrl} />} @{username}
        </Link>
      );
    }

    return (
      <Dropdown item simple trigger={trigger} loading={isFetching}>
        <Dropdown.Menu>
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default withLoggedInUser(ProfileDropdown);
