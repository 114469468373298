import React, { Component } from 'react';
import { oneOfType, string, element, func, oneOf, bool } from 'prop-types';
import classNames from 'classnames';

const browserHasSvgImgFeature = document.implementation.hasFeature(
  'http://www.w3.org/TR/SVG11/feature#Image',
  '1.1',
);

class SvgImage extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
    src: string.isRequired,
    fallback: string.isRequired,
    size: oneOf(['tiny', 'small', 'medium', 'large']),
    icon: bool,
  };

  static defaultProps = {
    as: 'img',
    size: 'medium',
    icon: true,
  };

  render() {
    const {
      as: As,
      className,
      src,
      fallback,
      icon,
      size,
      ...props
    } = this.props;

    return (
      <As
        className={classNames(
          icon ? 'flexpit-svg-icon' : 'flexpit-svg-image',
          `-${size}`,
          className,
        )}
        src={browserHasSvgImgFeature ? src : fallback}
        {...props}
      />
    );
  }
}

export default SvgImage;
