import React, { Component } from 'react';

import ContentContainer from '../../components/ContentContainer';
import EditPreferencesForm from '../../containers/users/forms/EditPreferencesForm';
import withLoggedInUser from '../../components/higher-order/withLoggedInUser';

class EditPreferencesPage extends Component {
  handleSuccess = newProfile => {
    const {
      currentUserState: { refreshCurrentUser },
      onUpdateSuccess,
    } = this.props;

    const { units } = newProfile;
    refreshCurrentUser({ units: { $set: units } });
    onUpdateSuccess(newProfile);
  };

  render() {
    const { user } = this.props;

    return (
      <ContentContainer size="tiny" align="left">
        <EditPreferencesForm
          data={user}
          className="-stacked"
          onSuccess={this.handleSuccess}
          initialValue={{ units: {} }}
        />
      </ContentContainer>
    );
  }
}

export default withLoggedInUser(EditPreferencesPage);
