import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';

import PublicUserProfileContainer from '../containers/users/PublicUserProfileContainer';
import withLoggedInUser, {
  LoggedInUserContext,
} from '../components/higher-order/withLoggedInUser';
import CurrentUserProfileContainer from '../containers/users/CurrentUserProfileContainer';
import UserProfileContainer from '../containers/users/UserProfileContainer';

class ProfilePage extends Component {
  static propTypes = {
    currentUserState: LoggedInUserContext.propType,
  };

  render() {
    const {
      currentUserState: { isFetching, isLogged, profile },
      history,
      location: { search },
      match: { params },
    } = this.props;

    const profileProps = {
      history: history,
      openedPostUid: params.postUid,
    };

    const urlParams = new URLSearchParams(search);
    const useUid = urlParams.get('useUid');

    const userParams = [
      useUid ? { uid: params.username } : { username: params.username },
    ];

    let profileComponent;
    if (isLogged) {
      /**
       * If there's no data besides uid, the app is fetching the logged-in
       * user's profile for the first time
       */
      if (!profile.username) {
        profileComponent = <Loader active={isFetching} size="massive" />;
      } else if (profile.username === params.username) {
        profileComponent = (
          <CurrentUserProfileContainer
            isSelf
            openedPostUid={params.postUid}
            {...profileProps}
          />
        );
      } else {
        profileComponent = (
          <UserProfileContainer
            userParams={userParams}
            history={history}
            {...profileProps}
          />
        );
      }
    } else {
      profileComponent = (
        <PublicUserProfileContainer
          userParams={userParams}
          isPublic
          {...profileProps}
        />
      );
    }

    return profileComponent;
  }
}

export default withLoggedInUser(ProfilePage);
