import withData from '../../components/higher-order/withData';
import SinglePostItem from '../../components/post/SinglePostItem';
import { getPublicPost } from '../../services/posts';

const PublicSinglePost = withData(getPublicPost, {
  dataPropName: 'post',
  paramPropName: 'postUid',
  singleData: true,
})(SinglePostItem);

export default PublicSinglePost;
