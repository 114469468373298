import React, { Component } from 'react';
import {
  oneOfType,
  string,
  element,
  node,
  shape,
  object,
  arrayOf,
  func,
} from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import update from 'immutability-helper';

import HeaderBar from './HeaderBar';
import Notifications from '../../components/Notifications';
import UserHashtagSearchBar from '../../components/hashtags/UserHashtagSearchBar';
import { createSelector } from '../../../node_modules/reselect';

class HeaderbarLayout extends Component {
  static propTypes = {
    as: oneOfType([string, element]),
    children: node,
    notifications: shape({
      data: arrayOf(object).isRequired,
    }),
    onToggleNotificationPopup: func,
  };

  static defaultProps = {
    as: 'div',
    notifications: undefined,
  };

  state = {
    isNotificationPopupOpen: false,
  };

  selectNumberUnseen = createSelector(
    (_, props) => props.notifications,
    notifications => {
      const unseenNotifs = notifications
        ? notifications.data.filter(notif => notif.unseen)
        : [];
      const nb = unseenNotifs.length;
      return nb;
    },
  );

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { isNotificationPopupOpen } = this.state;

    let newState = this.state;

    if (location !== nextProps.location && isNotificationPopupOpen) {
      newState = update(newState, { isNotificationPopupOpen: { $set: false } });
    }

    if (newState !== this.state) {
      this.setState(newState);
    }
  }

  handleMenuItemClick = (_, { target: { name } }) => {
    switch (name) {
      case 'notifications': {
        return this.setState(
          state => ({
            isNotificationPopupOpen: !state.isNotificationPopupOpen,
          }),
          () => {
            const { onToggleNotificationPopup } = this.props;
            const { isNotificationPopupOpen } = this.state;

            if (onToggleNotificationPopup) {
              onToggleNotificationPopup(isNotificationPopupOpen);
            }
          },
        );
      }

      default: {
        console.warn(
          `<HeaderbarLayout />: unrecognised menu item name '${name}'`,
        );
      }
    }
  };

  render() {
    const { as: As, children, className, notifications } = this.props;
    const { isNotificationPopupOpen } = this.state;

    const { data: events, ...notifProps } = notifications || {};
    const nbUnseenNotifications = this.selectNumberUnseen(
      this.state,
      this.props,
    );

    return (
      <As className={classNames('headerbar-layout', className)}>
        <header className="headerbar-container">
          <HeaderBar
            as="nav"
            borderless
            isNotificationPopupOpen={isNotificationPopupOpen}
            nbUnseenNotifications={nbUnseenNotifications}
            onItemClick={this.handleMenuItemClick}
          >
            <Menu.Item className="-fluid -centre">
              <UserHashtagSearchBar className="usersearch" fluid />
            </Menu.Item>
          </HeaderBar>
        </header>
        <div
          className={classNames(
            'notifications-popup',
            isNotificationPopupOpen && '-open',
          )}
        >
          {notifications && <Notifications events={events} {...notifProps} />}
        </div>
        <main className="main-container">{children}</main>
      </As>
    );
  }
}

export default withRouter(HeaderbarLayout);
