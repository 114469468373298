import times from 'lodash/times';

/**
 * Slices the provided array into an array of arrays, each containing n elements.
 *
 * @param { Array } array The array to slice
 * @param { number } n The number of items by slice.
 * @returns { Array[] } A new array containing slices of n items. If the length
 * of the array is not divisible by n, the last slice of the resulting array will
 * contain less than n elements.
 */
export const sliceByN = function sliceByN(array, n, sliceFromEnd = false) {
  let nbSlices = Math.ceil(array.length / n);
  let arrayToSlice = array;
  let head = [];

  if (sliceFromEnd) {
    const headIndex = array.length % n;
    head = array.slice(0, headIndex);
    arrayToSlice = array.slice(headIndex);
    nbSlices -= 1;
  }

  const slices = times(nbSlices, i => {
    const start = i * n;
    const end = start + n;
    const slice = arrayToSlice.slice(start, end);

    return slice;
  });

  return head.length > 0 ? [head, ...slices] : slices;
};

/**
 * Get a basic custom sorting function based on specified field and order.
 * @param { object } params
 * @param { string } params.field The field to order by.
 * @param { string } [ params.order = 'asc' ] The order ('asc' or 'desc').
 * @returns { function } The sorting function.
 */
export const customSort = function customSort({ field, order = 'asc' }) {
  const orderMultiplier = order === 'desc' ? -1 : 1;
  return (a, b) => (a[field] - b[field]) * orderMultiplier;
};
/**
 * Get a basic custom sorting function based on specified field and order for
 * entry type arrays (arrays of [uid, data]).
 * @param { object } params
 * @param { string } params.field The field to order by.
 * @param { string } [ params.order = 'asc' ] The order ('asc' or 'desc').
 * @returns { function } The sorting function.
 */
export const customSortEntries = function customSortEntries({
  field,
  order = 'asc',
}) {
  const orderMultiplier = order === 'desc' ? -1 : 1;
  return ([_a, a], [_b, b]) => (a[field] - b[field]) * orderMultiplier;
};

/**
 * Helper function to create an update object for `immutability-helper` to add
 * to a React state with a Map object the provided data.
 * @param { object[] } data The data.
 * @param { string } data[].uid A unique identifier for the data used the key in the Map.
 * @param { string } [ dataName = 'data' ] The name of field that contains data.
 * @returns { object } A query object for `immutability-helper`.
 */
export const getStateQueryFromData = function getStateQueryFromData(
  data,
  dataName = 'data',
) {
  const piecesToAdd = data.map(piece => [piece.uid, piece]);
  return {
    [dataName]: {
      $add: piecesToAdd,
    },
  };
};

/**
 * Creates a React-renderable list slicing the given text and replacing bits by nodes.
 * @param { string } child The string to replace nodes in.
 * @param { object[] } matches An array of data used for replacing text by nodes.
 * @param { number } matches[].index The index in the text where to start replacing by the node.
 * @param { string } matches[].matchedText The text matched by the RegExp.
 * @param { function } makeNode The function used to make a node. It is passed
 * the corresponding `matches[]`.
 * @returns { Array|String } An array of strings and React nodes that can be rendered
 * if matches were found, else just the string.
 */
export const replaceTextWithNodes = function replaceTextWithNodes(
  child,
  matches,
  makeNode,
) {
  // Split child into substrings to replace matches with nodes
  let startIndex = 0;
  const transformedChild = matches.reduce((acc, match) => {
    const { matchedText, index } = match;
    const stringBefore = child.slice(startIndex, index);

    const node = makeNode(match);

    // Start next string just after the node
    startIndex = index + matchedText.length;
    return acc.concat([stringBefore, node]);
  }, []);

  // Add the substring that's left if reducing did not reach the end of the child
  if (startIndex <= child.length) {
    const endString = child.slice(startIndex);
    transformedChild.push(endString);
  }

  // If `matches` was empty, just return the string.
  return transformedChild.length > 1 ? transformedChild : transformedChild[0];
};

/**
 * Creates a new object containing only the specified fields from the specified
 * object.
 * @param { object } object The object to transform.
 * @param { string[] } fields The fields to put in the resulting object.
 * @returns { object }
 */
export const reduceObject = function reduceObject(object = {}, fields = []) {
  const reducedObject = fields.reduce((acc, field) => {
    acc[field] = object[field];
    return acc;
  }, {});
  return reducedObject;
};

/**
 * Filters the elements from the specified array into two arrays, depending on
 * whether they match the criteria or not.
 * @param { array } array The array to filter.
 * @param { function } predicateFunc The function to call on each item to check
 * if it should be filtered or not.
 * @returns { array[] } An array of two new arrays, the first one containing the
 * elements for which `predicateFunc` returned true and the second one containing
 * the other elements.
 */
export const doubleFilter = function doubleFilter(array, predicateFunc) {
  return array.reduce(
    ([filter, rest], item) => {
      if (predicateFunc(item)) {
        filter.push(item);
      } else {
        rest.push(item);
      }

      return [filter, rest];
    },
    [[], []],
  );
};
