import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import classNames from 'classnames';

/**
 * This component should be used when you expect a modal to be scrollable.
 * It offers a hack to fix the flicking modal bug from Semantic-UI-React (issue #)
 */
class ScrollingModal extends Component {
  static mountNode = document.querySelector('.scrolling-modal-container');

  render() {
    const { className, ...modalProps } = this.props;

    return (
      <Modal
        className={classNames('scrolling', className)}
        mountNode={ScrollingModal.mountNode}
        {...modalProps}
      />
    );
  }
}

export default ScrollingModal;
