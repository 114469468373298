// SVG
import verifiedLiftSvg from './VerifiedLift.png';
import verificationPendingLiftSvg from './VerificationPendingLift.png';
import verifiedAtheleteSvg from './VerifiedAthlete.png';

// PNG
import verifiedLiftPng from './VerifiedLift.png';
import verificationPendingLiftPng from './VerificationPendingLift.png';
import verifiedAtheletePng from './VerifiedAthlete.png';

export const FLEXPIT_ICONS = {
  verifiedLift: {
    png: verifiedLiftPng,
    svg: verifiedLiftSvg,
  },

  verificationPendingLift: {
    png: verificationPendingLiftPng,
    svg: verificationPendingLiftSvg,
  },

  verifiedProfile: {
    png: verifiedAtheletePng,
    svg: verifiedAtheleteSvg,
  },
};
