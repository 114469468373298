export const TROPHIES = {
  personalRecord: 'PR',
  nightOwl: 'NIGHT_OWL',
  earlyBird: 'EARLY_BIRD',
  arnold: 'ARNOLD',
  mrWorldWide: 'MR_WORLD_WIDE',
  mrsWorldWide: 'MRS_WORLD_WIDE',
  gymRat: 'GYM_RAT',
  watchTheThrone: 'WATCH_THE_THRONE',
  pilot: 'PILOT',
  twoPlateBadge: '2_PLATE',
  newPr: 'PR',
  onboardingComplete: 'ONBOARDING_COMPLETE',
};
