import React, { Component } from 'react';
import { oneOfType, string, element, node } from 'prop-types';
import Sidebar from './Sidebar';

class SidebarLayout extends Component {
  static propTypes = {
    as: oneOfType([string, element]),
    children: node,
  };

  static defaultProps = {
    as: 'div',
  };

  render() {
    const { as: As, children } = this.props;

    return (
      <As className="sidebar-layout">
        <aside className="sidebar-container">
          <Sidebar />
        </aside>
        <div className="body-container">{children}</div>
      </As>
    );
  }
}

export default SidebarLayout;
