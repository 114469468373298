import React, { Component } from 'react';
import { Feed } from 'semantic-ui-react';
import classNames from 'classnames';

import BaseEventInfo from './BaseEventInfo';
import SpotButton from '../../containers/relationships/SpotButton';
import { Link } from 'react-router-dom';
import { routes, injectParamsIntoRoute } from '../../modules/routes';

class SpotEvent extends Component {
  render() {
    const {
      event,
      event: { actor, unseen },
      className,
      currentUserState,
      ...props
    } = this.props;

    const userProfileUrl = injectParamsIntoRoute(routes.user.profile, {
      username: actor.username,
    });

    return (
      <Feed.Event
        as={Link}
        className={classNames(
          'event-notification',
          unseen && '-unseen',
          className,
        )}
        to={userProfileUrl}
        {...props}
      >
        <BaseEventInfo event={event} text="started spotting you" />
        <SpotButton
          className="endcontent"
          style={{ alignSelf: 'center' }}
          userUid={actor.uid}
        />
      </Feed.Event>
    );
  }
}

export default SpotEvent;
