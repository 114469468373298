import React, { Component } from 'react';
import { oneOfType, string, element, func, node } from 'prop-types';
import classNames from 'classnames';

class BottomMessage extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
    className: string,
    children: node,
  };

  static defaultProps = {
    as: 'div',
    children: 'Nothing else to see!',
  };

  render() {
    const { as: As, className, children, ...props } = this.props;

    return (
      <As className={classNames('bottom-message', className)} {...props}>
        {children}
      </As>
    );
  }
}

export default BottomMessage;
