import classNames from 'classnames';
import { bool, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import PublicUserPostGridWithModal from '../../../containers/posts/PublicUserPostGridWithModal';
import UserPostGridWithModal from '../../../containers/posts/UserPostGridWithModal';
import { injectParamsIntoRoute, routes } from '../../../modules/routes';
import Page404 from '../../../pages/Page404';
import { getLoggedInUid } from '../../../services/authentication';
import { getRelationshipWith } from '../../../services/relationships';
import ContentContainer from '../../ContentContainer';
import UserHeader from './UserHeader';

class UserProfile extends Component {
  static propTypes = {
    as: string,
    data: shape({ uid: string }),
    isSelf: bool,
    openedPostUid: string,
  };

  static defaultProps = {
    as: 'article',
    isSelf: false,
    isPublic: false,
    openedPostUid: undefined,
  };

  state = {
    isLoadingRelationship: false,
    relationship: null,
    error: null,
  };

  componentDidUpdate(prevProps) {
    // Request to fetch the relationship if userUid is there and different.
    const { data: { uid: newUid } = {} } = this.props;
    const { data: { uid: prevUid } = {} } = prevProps;

    if (newUid !== prevUid && newUid) {
      this.fetchRelationship();
    }
  }

  async fetchRelationship() {
    const { data: { uid, privateProfile } = {} } = this.props;
    const { isLoadingRelationship } = this.state;
    if (!uid) {
      console.error('Called fetchRelationShip with no useruid');
    }
    /**
     * We don't need the relationship if the profile is not private or if
     * the user is viewing their own profile.
     */
    if (!privateProfile || isLoadingRelationship || getLoggedInUid() === uid) {
      return;
    }

    this.setState({ isLoadingRelationship: true });

    let error;
    const relationship = await getRelationshipWith(uid).catch(err => {
      error = err;
    });

    this.setState({
      error,
      relationship,
      isLoadingRelationship: false,
    });
  }

  handleCloseModal = () => {
    const { history, data } = this.props;

    if (data) {
      // When closing the modal, replace the url with just the root
      const { username } = data;
      const userProfileUrl = injectParamsIntoRoute(routes.user.profile, {
        username,
      });
      history.replace(userProfileUrl);
    }
  };

  render() {
    const {
      as: As,
      data,
      isSelf,
      isPublic,
      isLoading,
      openedPostUid,
      className,
      history,
      loading,
      requestRefreshData,
      ...props
    } = this.props;

    if (!isLoading && !data) {
      return <Page404 />;
    }

    const { relationship, isLoadingRelationship } = this.state;
    const { outgoingStatus } = relationship || {};

    const modalProps = {
      closeIcon: true,
      open: Boolean(openedPostUid),
      onClose: this.handleCloseModal,
    };

    const { privateProfile, uid } = data || {};

    // Check if the user is allowed to see the viewed user's posts
    const isAllowedToSeePosts =
      !privateProfile || // The profile is public
      getLoggedInUid() === uid || // It is the user's own profile
      outgoingStatus === 'spotting'; // The user is spotting the other user

    return (
      <As className={classNames('user-profile', className)} {...props}>
        <ContentContainer size="large">
          <UserHeader
            className="userheader"
            data={data}
            isSelf={isSelf}
            isPublic={isPublic}
            isLoading={isLoading}
          />

          {isLoadingRelationship ? (
            <Loader size="large" inline="centered" active />
          ) : (
            <>
              {!isAllowedToSeePosts && (
                <Message className="contrasted">
                  This user's profile is private – you cannot see their posts
                  until you started spotting them.
                </Message>
              )}
              {isAllowedToSeePosts &&
                data &&
                (isPublic ? (
                  <PublicUserPostGridWithModal
                    fetchParams={{ userUid: data.uid }}
                    modalProps={modalProps}
                    limit={9}
                    openedPostUid={openedPostUid}
                  />
                ) : (
                  <UserPostGridWithModal
                    subscriptionParams={{ userUid: data.uid }}
                    modalProps={modalProps}
                    openedPostUid={openedPostUid}
                  />
                ))}
            </>
          )}
        </ContentContainer>
      </As>
    );
  }
}

export default UserProfile;
