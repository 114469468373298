import { getCurrentUser } from '../../services/users';
import withData from '../../components/higher-order/withData';
import AccountPage from '../../pages/AccountPage';

const CurrentUserAccountPage = withData(getCurrentUser, {
  dataPropName: 'user',
  singleData: true,
})(AccountPage);

export default CurrentUserAccountPage;
