import { subscribeToHashtagPosts } from '../../services/hashtags';
import withLazyLoadedReactiveData from '../../components/higher-order/withLazyLoadedReactiveData';
import Feed from '../../components/feed/Feed';

const HashtagFeed = withLazyLoadedReactiveData({
  subscribeToData: subscribeToHashtagPosts,
  dataPropName: 'posts',
  nbByLoad: 5,
})(Feed);

export default HashtagFeed;
