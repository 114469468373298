/**
 * Module for functions related to errors.
 */

/**
 * InternalError is the basic type for any errors related to development.
 * @class
 */
export class InternalError extends Error {
  /**
   * Creates a new InternalError to wrap an error.
   * @param { Error } internalError The internal error to wrap.
   * @constructor
   */
  constructor(internalError) {
    super(`Internal error: ${internalError.reason || internalError.message}`);
    this.internalError = internalError;
  }
}

/**
 * Logs the error, wraps it in a new #InternalError, and throws it.
 * @param { Error } error The error to wrap.
 * @throws { InternalError } A new wrapped error.
 */
export const throwInternalError = function throwInternalError(error) {
  console.error(error);
  throw new InternalError(error);
};

export class DataNotFoundError extends Error {
  constructor(message, params) {
    super(message);
    this.params = params;
  }
}
