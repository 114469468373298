import check from 'check-types';

import { db, firebaseAuth } from '../modules/firebase';
import { checkArguments, basicSnapshotHandler } from '../modules/services';
import { getLoggedInUid } from './authentication';
import { parseFireStorePost } from './posts';

const parseFireStoreNotification = function parseFireStoreNotification({
  dateCreated,
  dateUpdated,
  ...notification
}) {
  return {
    ...notification,
    dateCreated: dateCreated && dateCreated.toDate(),
    dateUpdated: dateUpdated && dateUpdated.toDate(),
  };
};

/*
 * User feed
 */
export const getUserFeed = async (limit = 10) => {
  try {
    const userUid = await firebaseAuth().currentUser.uid;
    const feed = await db
      .collection('users')
      .doc(userUid)
      .collection('feed')
      .orderBy('dateCreated', 'desc')
      .limit(limit)
      .get()
      .then(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          const post = parseFireStorePost(doc.data());
          data.push(post);
        });
        return data;
      });
    return feed;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Subscribes to the specified user's feed and listen to its changes. If no user
 * is specified, subscribes to the currently logged-in user's feed.
 *
 * @param { object } args
 * @param { onReceiveDataFunction } args.onReceiveData The handler for document changes.
 * @param { function } args.onError The handler for errors.
 * @param { string } [ args.userUid = getLoggedInUid() ] Whose feed to subscribe to
 * (defaults to currently logged-in user's).
 * @param { number } [ args.limit = 20 ] The number of documents to listen to.
 * @returns { function } A function to unsuscribe from the created subscription.
 */
export const subscribeToUsersFeed = function subscribeToUsersFeed(args) {
  checkArguments(args, {
    onReceiveData: check.function,
    onError: check.function,
    userUid: check.maybe.string,
    limit: check.maybe.number,
  });

  const {
    userUid = getLoggedInUid(),
    limit = 20,
    onReceiveData,
    onError,
  } = args;

  const userRef = db.collection('users').doc(userUid);
  const feedRef = userRef
    .collection('feed')
    .orderBy('dateCreated', 'desc')
    .limit(limit);

  const onSnapshot = basicSnapshotHandler(onReceiveData, parseFireStorePost);
  const unsuscribe = feedRef.onSnapshot(onSnapshot, onError);

  return unsuscribe;
};

/**
 * Subscribes to the specified user's notification list and listens to its changes.
 * If no user is specified, subscribes to the current logged-in user's notifications.
 *
 * @param { object }} args
 * @param { onReceiveDataFunction } args.onReceiveData The handler for document changes.
 * @param { function } args.onError The handler for errors.
 * @param { string } [ args.userUid = getLoggedInUid() ] Whose notifications to
 * subscribe to (defaults to currently logged-in user's).
 * @param { number } [ args.limit = 20 ] The number of documents to listen to.
 * @returns { function } A function to unsuscribe from the created subscription.
 */
export const subscribeToUsersNotifications = function subscribeToUsersNotifications(
  args,
) {
  checkArguments(args, {
    onReceiveData: check.function,
    onError: check.function,
    userUid: check.maybe.string,
    limit: check.maybe.number,
  });

  const {
    userUid = getLoggedInUid(),
    limit = 20,
    onReceiveData,
    onError,
  } = args;

  const userRef = db.collection('users').doc(userUid);
  const notifRef = userRef
    .collection('notifications')
    .orderBy('dateCreated', 'desc')
    .limit(limit);

  const onSnapshot = basicSnapshotHandler(
    onReceiveData,
    parseFireStoreNotification,
  );
  const unsubscribe = notifRef.onSnapshot(onSnapshot, onError);

  return unsubscribe;
};
