import React, { Component, Fragment } from 'react';
import { shape, func, string, arrayOf, bool } from 'prop-types';
import { createSelector } from 'reselect';

import PostGrid from './PostGrid';
import PostModal from './PostModal';

class PostGridWithModal extends Component {
  static propTypes = {
    modalProps: shape({ onClose: func, open: bool }),
    posts: arrayOf(
      shape({
        uid: string.isRequired,
      }),
    ).isRequired,
    openedPost: shape({
      uid: string.isRequired,
    }),
  };

  static defaultProps = {
    modalProps: {},
    posts: [],
  };

  selectGetModalPost = createSelector(
    (_, props) => props.openedPost,
    openedPost => {
      return openedPost;
    },
  );

  getModalPost(props = this.props) {
    return this.selectGetModalPost(this.state, props);
  }

  render() {
    const { modalProps, isLoading, ...gridProps } = this.props;

    const modalPost = this.getModalPost();

    return (
      <Fragment>
        <PostGrid isLoading={isLoading} {...gridProps} />
        {!isLoading && <PostModal data={modalPost} {...modalProps} />}
      </Fragment>
    );
  }
}

export default PostGridWithModal;
