export const ACTIVITY_TYPES = {
  maxReps: 'MAX_REPS',
  maxWeight: 'MAX_WEIGHT',
  time: 'TIME',
  weighted: 'WEIGHTED',
};

export const ACTIVITY_TYPE_TEXTS = {
  [ACTIVITY_TYPES.maxReps]: 'Max reps',
  [ACTIVITY_TYPES.maxWeight]: 'Max weight',
  [ACTIVITY_TYPES.time]: 'Max reps in 60 seconds',
  [ACTIVITY_TYPES.weighted]: 'Weighted',
};
