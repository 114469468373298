import check from 'check-types';
import isFunction from 'lodash/isFunction';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Waypoint } from 'react-waypoint';
import { createSelector } from 'reselect';
import { Image, Loader, Table } from 'semantic-ui-react';
import Weight from '../Weight';

export const LiftRankingRow = function LiftRankingRow({
  ranking,
  ranking: { postUid, userPhotoUrl },
  fields = [],
  ...props
}) {
  return (
    <Table.Row key={postUid} {...props}>
      {fields.map(({ field, header, ...props }) => {
        const key = `${postUid}-${field}`;
        switch (field) {
          case 'username': {
            return (
              <Table.Cell key={key} {...props}>
                <Image avatar circular src={userPhotoUrl} /> {ranking[field]}
              </Table.Cell>
            );
          }
          case 'weight': {
            return (
              <Table.Cell key={key} {...props}>
                <Weight>{ranking[field]}</Weight>
              </Table.Cell>
            );
          }

          default: {
            return (
              <Table.Cell key={key} {...props}>
                {ranking[field]}
              </Table.Cell>
            );
          }
        }
      })}
    </Table.Row>
  );
};

export const GroupRows = function GroupRows({
  header,
  group,
  group: { data },
  renderRows,
  fields,
}) {
  return (
    <Fragment>
      <Table.Row className="group-header-row">
        <Table.HeaderCell colSpan={fields.length}>
          {isFunction(header) ? header(group) : header}
        </Table.HeaderCell>
      </Table.Row>
      {renderRows(data)}
    </Fragment>
  );
};

class LiftRankingTable extends Component {
  static propTypes = {
    rankings: arrayOf(object),
    fields: arrayOf(
      shape({
        field: string.isRequired,
        header: string,
      }),
    ),
    renderGroupHeader: func,
    groupedBy: bool,
    children: node,
    selectedUids: oneOfType([string, arrayOf(string)]),
    isLoading: bool,
    isFirstBatch: bool,
    isLastBatch: bool,
    requestMoreData: func,
    requestRefreshData: func,
    requestPreviousData: func,
    selectable: bool,
    nbToFetchNextBefore: number,
  };

  static defaultProps = {
    fields: [
      { field: 'rank', header: '#' },
      { field: 'username', header: 'Name' },
      { field: 'repsNo', header: 'Reps', textAlign: 'right' },
    ],
    renderGroupHeader: undefined,
    rankings: [],
    groupedBy: false,
    selectable: false,
    selectedUids: [],
    isLoading: false,
    isFirstBatch: true,
    isLastBatch: true,
    requestMoreData: undefined,
    requestRefreshData: undefined,
    requestPreviousData: undefined,
    nbToFetchNextBefore: undefined,
  };

  selectRankings = createSelector(
    (_, props) => props.rankings,
    rankings => rankings,
  );

  getRankings() {
    return this.selectRankings(this.state, this.props);
  }

  renderRows = (rankings = this.getRankings()) => {
    const { selectedUids, fields } = this.props;

    const uidsToSelect = !check.array(selectedUids)
      ? [selectedUids]
      : selectedUids;

    return rankings.map(ranking => (
      <LiftRankingRow
        key={ranking.uid}
        active={uidsToSelect.includes(ranking.uid)}
        ranking={ranking}
        fields={fields}
      />
    ));
  };

  renderGroups() {
    const { fields, renderGroupHeader } = this.props;
    const weightGroups = this.getRankings();

    return weightGroups.map(group => (
      <GroupRows
        key={`${group.kg}-group`}
        header={renderGroupHeader}
        group={group}
        renderRows={this.renderRows}
        fields={fields}
      />
    ));
  }

  render() {
    const {
      rankings,
      isLoading,
      isLoadingBefore,
      isFirstBatch,
      isLastBatch,
      fields,
      groupedBy,
      requestMoreData,
      requestRefreshData,
      requestPreviousData,
      requestSpecificData,
      children,
      selectedUids,
      nbToFetchNextBefore,
      renderGroupHeader,
      ...tableProps
    } = this.props;

    const rows = groupedBy ? this.renderGroups() : this.renderRows();

    const hasMoreData = Boolean(requestMoreData) && !isLastBatch;
    const nbToFetchNextBeforeStr = nbToFetchNextBefore
      ? `${nbToFetchNextBefore} `
      : '';

    const requestPreviousDataRow = requestPreviousData && !isFirstBatch && (
      <Table.Row>
        <Table.Cell
          colSpan={fields.length}
          onClick={requestPreviousData}
          textAlign="center"
        >
          {!isLoadingBefore && (
            <button className="link" onClick={requestPreviousData}>
              Load previous {nbToFetchNextBeforeStr}rankings
            </button>
          )}
          <Loader size="small" inline active={isLoadingBefore} />
        </Table.Cell>
      </Table.Row>
    );

    return (
      <Fragment>
        <Table {...tableProps}>
          <Table.Header>
            <Table.Row>
              {fields.map(({ header, field, ...props }) => (
                <Table.HeaderCell key={`${field}-header`} {...props}>
                  {header}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {requestPreviousDataRow}
            {rows}
            {children}
          </Table.Body>
        </Table>
        {hasMoreData && !isLoading && <Waypoint onEnter={requestMoreData} />}
        <Loader
          size="large"
          inline="centered"
          active={(isLoading && !isLoadingBefore) || hasMoreData}
        />
      </Fragment>
    );
  }
}

export default LiftRankingTable;
