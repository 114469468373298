import React, { Component } from 'react';
import { shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import ContentContainer from '../components/ContentContainer';
import Page404 from './Page404';
import HashtagFeed from '../containers/feed/HashtagFeed';
import NoDataMessage from '../components/NoDataMessage';
import { getLoggedInUid } from '../services/authentication';
import { routes } from '../modules/routes';
import PostItemWithAuthor, {
  PostItemWithAuthorContainer,
} from '../containers/posts/PostItemWithAuthor';

class HashtagFeedPage extends Component {
  static propTypes = {
    match: shape({
      params: shape({
        hashtag: string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    match: {
      params: {},
    },
  };

  renderPostItem = post =>
    getLoggedInUid() ? (
      <PostItemWithAuthorContainer
        data={post}
        subscriptionParams={{ uid: post.uid }}
      />
    ) : (
      <PostItemWithAuthor data={post} />
    );

  render() {
    const {
      match: {
        params: { hashtag },
      },
    } = this.props;

    const subscriptionParams = {
      hashtag,
    };

    const isLoggedIn = Boolean(getLoggedInUid());

    return !hashtag ? (
      <Page404 />
    ) : (
      <ContentContainer size="big">
        {isLoggedIn && (
          <Link className="navigation-link" to={routes.feed}>
            Back to your feed
          </Link>
        )}
        <HashtagFeed
          renderPost={this.renderPostItem}
          subscriptionParams={subscriptionParams}
          noDataNode={
            <NoDataMessage>
              No post found for <em className="emphasised-info">#{hashtag}</em>.
              {isLoggedIn &&
                ' Create a post with this hashtag to start a trend!'}
            </NoDataMessage>
          }
        />
      </ContentContainer>
    );
  }
}

export default HashtagFeedPage;
