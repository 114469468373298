import React, { Component } from 'react';
import { oneOfType, object, array, string, number } from 'prop-types';
import update from 'immutability-helper';
import { createSelector } from 'reselect';
import check from 'check-types';
import isEqual from 'lodash/isEqual';

/**
 * @callback fetchDataFunction
 * @param { Object } params
 * @returns { Object[] | Object } The data.
 */

/**
 * Gets a new parametised higher-order component with data.
 * The wrapped component is provided with data, and a function to refresh the data.
 *
 * @param { fetchDataFunction } fetchData The API call to subscribe
 * to data.
 * @param { object } config
 * @param { string } [ config.dataPropName = 'data' ] The name of the data prop
 * to pass down to the wrapped component.
 * @param { string } [ config.paramPropName = 'fetchParams' ] The name of the props
 * to get the fetching parameters from in the HoC.
 * @param { boolean } [ config.passDownParams = false ] true to pass down the `fetchParams` props
 * in the wrapped component, false else.
 * @param { boolean } [ config.singleData = false ] true to pass an object in the data prop
 * instead of an array.
 * @param { boolean } [ params.wrapProps = false ] true to wrap all the props passed down
 * in a single prop object.
 * @returns { Function } A higher-order component function to wrap a component with data.
 */
const withData = function withData(
  fetchData,
  {
    dataPropName = 'data',
    paramPropName = 'fetchParams',
    passDownParams = false,
    singleData = false,
    wrapProps = false,
  } = {},
) {
  return WrappedComponent => {
    const wrappedDisplayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const makeGetData = () =>
      createSelector(
        state => state.data,
        dataMap => {
          return [...dataMap.values()];
        },
      );

    class WithData extends Component {
      static displayName = `WithData(${wrappedDisplayName})`;

      static propTypes = {
        [paramPropName]: oneOfType([object, array, string, number]),
      };

      static defaultProps = {
        [paramPropName]: [],
      };

      static get initialState() {
        return {
          data: new Map(),
          error: undefined,
          isLoading: true,
        };
      }

      state = WithData.initialState;

      madeGetData = makeGetData();

      componentDidMount() {
        this.refreshData();
      }

      componentWillReceiveProps(nextProps) {
        const { [paramPropName]: fetchParams } = this.props;

        // If fetch params change, reset whole state
        if (!isEqual(fetchParams, nextProps[paramPropName])) {
          this.setState(WithData.initialState, () => this.refreshData());
        }
      }

      getData() {
        return this.madeGetData(this.state);
      }

      async refreshData() {
        // Show loading state
        this.setState({
          error: undefined,
          isLoading: true,
        });

        let error = null;
        let data = [];

        // Allows to use arrays or one string/number for params instead of only-objects
        const { [paramPropName]: fetchParams } = this.props;
        const args = check.array(fetchParams) ? fetchParams : [fetchParams];

        try {
          data = await fetchData(...args);

          // Handle lazy-loading fetch functions
          if (data.data) {
            data = data.data;
          }
        } catch (internalError) {
          error = internalError;
        }

        const dataUpdateQuery = this.updateStateWithData(
          ...(check.array(data) ? data : [data]),
        );

        // Hide loading state and update error/data
        this.setState(
          update(this.state, {
            error: { $set: error },
            isLoading: { $set: false },
            ...dataUpdateQuery,
          }),
        );
      }

      updateStateWithData(...pieces) {
        const { data: dataMap } = this.state;
        const piecesToAdd = pieces.map(piece => [piece.uid, piece]);
        const piecesToRemove = [...dataMap.keys()].filter(
          uid => !pieces.find(piece => piece.uid === uid),
        );

        return {
          data: {
            $add: piecesToAdd,
            $remove: piecesToRemove,
          },
        };
      }

      handleRequestRefreshData = data => {
        if (data) {
          const args = Array.isArray(data) ? data : [data];
          const updateQuery = this.updateStateWithData(...args);
          this.setState(update(this.state, updateQuery));
        } else {
          this.refreshData();
        }
      };

      render() {
        const {
          [paramPropName]: fetchParams,
          isLoading: isLoadingProp,
          ...wrappedProps
        } = this.props;
        const { isLoading } = this.state;

        const data = this.getData();

        const propWrapper = {
          isLoading: isLoading || isLoadingProp,
          [wrapProps ? 'data' : dataPropName]: singleData ? data[0] : data,
          requestRefreshData: this.handleRequestRefreshData,
        };

        // Pass down parametes if asked to in HoC
        if (passDownParams) {
          propWrapper[paramPropName] = fetchParams;
        }

        // Either wrap all the props with an object, or pass them directly as props
        const extraProps = wrapProps
          ? { [dataPropName]: propWrapper }
          : propWrapper;

        return <WrappedComponent {...extraProps} {...wrappedProps} />;
      }
    }

    return WithData;
  };
};

export default withData;
