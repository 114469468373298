import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import { Image } from 'semantic-ui-react';
import playStoreBadge from '../../assets/images/google_play_badge.png';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const PlayStoreBadge = function AppStoreBadge({ className }) {
  return (
    <a
      className={classNames('play-store-badge', className)}
      href="https://play.google.com/store/apps/details?id=com.flexpit"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image src={playStoreBadge} alt="Download on the Play store" />
    </a>
  );
};

PlayStoreBadge.propTypes = propTypes;
PlayStoreBadge.defaultProps = defaultProps;

export default PlayStoreBadge;
