import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';

import UserLeaderboardPositions from '../../containers/activities/UserLeaderboardPositions';
import RouterMenuItem from '../../components/RouterMenuItem';
import { injectParamsIntoRoute, routes } from '../../modules/routes';
import { getLoggedInUid } from '../../services/authentication';

class YourLeaderboardPositions extends Component {
  handleMakeItemProps = ranking => {
    const { activity } = ranking;
    const to = injectParamsIntoRoute(routes.leaderboard.activityPersonal, {
      activity: activity.uid,
      type: activity.type,
      userUid: getLoggedInUid(),
    });

    return {
      to,
    };
  };

  render() {
    const props = this.props;

    return (
      <UserLeaderboardPositions
        as={Menu}
        itemAs={RouterMenuItem}
        makeItemProps={this.handleMakeItemProps}
        header="Your leaderboards"
        headerAs={Menu.Item}
        headerProps={{ header: true }}
        {...props}
      />
    );
  }
}

export default YourLeaderboardPositions;
