import React, { Component } from 'react';
import {
  oneOfType,
  string,
  element,
  object,
  bool,
  oneOf,
  node,
} from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import classNames from 'classnames';

class HeaderSegment extends Component {
  static propTypes = {
    header: node.isRequired,
    as: oneOfType([string, element]),
    children: node,
    headerProps: object,
    className: string,
    attached: oneOfType([bool, oneOf(['top', 'bottom'])]),
  };

  static defaultProps = {
    as: 'div',
    headerProps: {
      as: 'header',
      attached: 'top',
    },
    attached: 'bottom',
  };

  render() {
    const {
      as: As,
      header,
      headerProps,
      attached,
      children,
      className,
      segmentClassName,
      ...otherProps
    } = this.props;

    return (
      <As className={classNames('header-segment', className)}>
        <Header {...headerProps}>{header}</Header>
        <Segment
          attached={attached}
          className={segmentClassName}
          {...otherProps}
        >
          {children}
        </Segment>
      </As>
    );
  }
}

export default HeaderSegment;
