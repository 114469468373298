import React, { Component } from 'react';
import { oneOfType, string, element, func, shape, bool } from 'prop-types';
import { Switch, Route, withRouter } from 'react-router';
import { Menu, Sticky, Select } from 'semantic-ui-react';
import identity from 'lodash/identity';

import { getLoggedInUid } from '../../services/authentication';
import ActivitySegment from './ActivitySegment';
import ActivityOverviewLeaderboardTable from '../../containers/activities/ActivityOverviewLeaderboardTable';
import ActivityCompleteLeaderboardTable from '../../containers/activities/ActivityCompleteLeaderboardTable';
import ActivityPersonalLeaderboardTable from '../../containers/activities/ActivityPersonalLeaderboardTable';
import UserSearchBar from '../user/UserSearchBar';
import RouterMenuItem from '../RouterMenuItem';
import { headerBarHeight } from '../../modules/css';
import { createSelector } from 'reselect';
import {
  ACTIVITY_TYPE_TEXTS,
  ACTIVITY_TYPES,
} from '../../modules/constants/activities';
import UserSearchResult, {
  makeLinkUserSearchResult,
} from '../user/UserSearchResult';
import Page404 from '../../pages/Page404';
import { injectParamsIntoRoute } from '../../modules/routes';

const activityTypeFields = {
  [ACTIVITY_TYPES.maxWeight]: [
    { field: 'rank', header: '#' },
    { field: 'username', header: 'Name' },
  ],
};

class ActivityLeaderboard extends Component {
  static propTypes = {
    match: shape({
      url: string.isRequired,
    }).isRequired,
    activity: shape({
      uid: string.isRequired,
    }),
    as: oneOfType([string, element, func]),
    isLoading: bool,
  };

  static defaultProps = {
    as: 'div',
    activity: {},
    criteria: {},
    isLoading: false,
  };

  state = {
    activityType: '',
  };

  containerRef = React.createRef();

  selectActivityTypeOptions = createSelector(
    (_, props) => props.activity,
    activity => {
      const { type = [] } = activity || {};
      return type
        .map(key => {
          const text = ACTIVITY_TYPE_TEXTS[key];

          if (!text) {
            console.warn(
              `<ActivityLeaderboard />: unrecognised activity type '${key}'`,
            );
            return undefined;
          }

          return {
            key,
            text,
            value: key,
          };
        })
        .filter(identity);
    },
  );

  selectCriteria = createSelector(
    (_, props) => props.activity,
    (_, props) => props.match.params.type,
    (activity, type) =>
      activity && type
        ? {
            type,
            activityUid: activity.uid,
            media: true,
            groupBy: 'lbs',
          }
        : undefined,
  );

  renderLinkUserResult = UserSearchResult;

  componentWillMount() {
    const {
      match: { url },
    } = this.props;
    this.renderLinkUserResult = makeLinkUserSearchResult(`${url}/:userUid`);
  }

  componentWillReceiveProps(nextProps) {
    const {
      activity,
      match: { url },
    } = this.props;

    // Set default type when loaded activity
    const justReceivedActivity =
      !(activity && activity.type) &&
      nextProps.activity &&
      nextProps.activity.type;

    if (justReceivedActivity) {
      const {
        activity: {
          type: [activityType],
        },
      } = nextProps;
      this.setState({ activityType });
    }

    if (nextProps.match.url !== url) {
      this.renderLinkUserResult = makeLinkUserSearchResult(
        `${nextProps.match.url}/:userUid`,
      );
    }
  }

  handleChangeActivityType = ({ target }, data = target) => {
    const {
      history,
      match: { path, params },
    } = this.props;
    const { value } = data;

    const newUrl = injectParamsIntoRoute(path, { ...params, type: value });
    history.replace(newUrl);
  };

  handleRenderGroupHeader = ({ lbs, kg }) => {
    const { activityType } = this.state;
    return activityType === ACTIVITY_TYPES.time
      ? ACTIVITY_TYPE_TEXTS[activityType]
      : `${lbs}lbs/${kg}kg`;
  };

  render() {
    const { as: As, activity, loadingActivity, match } = this.props;

    const { type: activityType } = match.params;

    if (
      !loadingActivity &&
      activityType &&
      !activity.type.includes(activityType)
    ) {
      return <Page404 />;
    }

    const currentUserUid = getLoggedInUid();

    const fullLeaderboardUrl = `${match.path}/all`;
    const personalLeaderboardUrl = `${match.path}/:userUid`;

    const baseCriteria = this.selectCriteria(this.state, this.props);

    const activityTypeOptions = this.selectActivityTypeOptions(
      this.state,
      this.props,
    );

    const activityTypeDropdown = (
      <Select
        placeholder="Select the activity type..."
        loading={loadingActivity}
        options={activityTypeOptions}
        name="activityType"
        onChange={this.handleChangeActivityType}
        value={activityType}
      />
    );

    return (
      <As className="golden-container" ref={this.containerRef}>
        <ActivitySegment
          activity={activity}
          isLoading={loadingActivity}
          className="asection activity-leaderboard-segment"
          segmentClassName="-padless"
          header={activityTypeDropdown}
        >
          {baseCriteria && (
            <Switch>
              <Route
                exact
                path={match.path}
                render={() => (
                  <ActivityOverviewLeaderboardTable
                    criteria={baseCriteria}
                    limit={10}
                    fullLeaderboardUrl={`${match.url}/all`}
                    selectedUids={currentUserUid}
                    renderGroupHeader={this.handleRenderGroupHeader}
                    fields={activityTypeFields[activityType]}
                  />
                )}
              />

              <Route
                path={fullLeaderboardUrl}
                render={() => (
                  <ActivityCompleteLeaderboardTable
                    criteria={baseCriteria}
                    limit={20}
                    selectedUids={currentUserUid}
                    renderGroupHeader={this.handleRenderGroupHeader}
                    groupedBy
                    fields={activityTypeFields[activityType]}
                  />
                )}
              />

              <Route
                path={personalLeaderboardUrl}
                render={({
                  match: {
                    params: { userUid },
                  },
                }) => (
                  <ActivityPersonalLeaderboardTable
                    criteria={{
                      ...baseCriteria,
                      userUid: userUid === currentUserUid ? undefined : userUid,
                      limit: 5,
                    }}
                    limit={[10, 20]}
                    selectedUids={userUid}
                    renderGroupHeader={this.handleRenderGroupHeader}
                    groupedBy
                    fields={activityTypeFields[activityType]}
                  />
                )}
              />
            </Switch>
          )}
        </ActivitySegment>
        <Sticky
          as="aside"
          className="bsection -padded"
          context={this.containerRef.current}
          offset={headerBarHeight}
        >
          <Menu as="nav" text vertical>
            <Menu.Item header size="medium">
              This leaderboard
            </Menu.Item>

            <Menu.Item>
              <UserSearchBar
                placeholder="View user's ranking"
                size={undefined}
                input={{
                  ...UserSearchBar.defaultProps.input,
                  className: 'contrasted',
                }}
                resultRenderer={this.renderLinkUserResult}
              />
            </Menu.Item>

            <RouterMenuItem
              className="link -primary"
              to={`${match.url}/${currentUserUid}`}
            >
              View your ranking
            </RouterMenuItem>

            <RouterMenuItem exact className="link" to={match.url}>
              View overview
            </RouterMenuItem>

            <RouterMenuItem className="link" to={`${match.url}/all`}>
              View all
            </RouterMenuItem>
          </Menu>
        </Sticky>
      </As>
    );
  }
}

export default withRouter(ActivityLeaderboard);
