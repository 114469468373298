import React, { Component } from 'react';
import { oneOfType, string, element, shape, func } from 'prop-types';
import { Image, Header } from 'semantic-ui-react';
import classNames from 'classnames';

import { activityExerciseAsset } from '../../assets/images/exercises';

class ActivityCard extends Component {
  static propTypes = {
    data: shape({
      name: string.isRequired,
    }).isRequired,
    className: string,
    as: oneOfType([string, element, func]),
  };

  static defaultProps = {
    as: 'div',
    className: '',
    data: {},
  };

  render() {
    const {
      as: As,
      className,
      data: { name },
      ...otherProps
    } = this.props;

    const icon = activityExerciseAsset(name);

    return (
      <As className={classNames('activity-card', className)} {...otherProps}>
        <Image className="illustration" src={icon} size="tiny" />
        <Header className="name" as="h4" textAlign="center">
          {name}
        </Header>
      </As>
    );
  }
}

export default ActivityCard;
