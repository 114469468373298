import check from 'check-types';

import { db } from '../modules/firebase';
import { throwInternalError } from '../modules/errors';
import { checkArguments } from '../modules/services';

/**
 * Gets all activities.
 * @returns { Promise<Object[]> } A Promise that resolves with an array of activties.
 * @throws { InternalError }
 */
export const getActivities = function getActivities() {
  const activitiesRef = db.collection('activities');

  return activitiesRef
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      return data;
    })
    .catch(throwInternalError);
};

/**
 * Retrieves an activity's data.
 * @param { string } uid The activity's uid.
 * @returns { Promise<Object> } A Promise that resolves with the activity's data.
 * @throws { InternalError }
 */
export const getActivity = function getActivity(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  const activityRef = db.collection('activities').doc(uid);

  return activityRef
    .get()
    .then(querySnapshot => querySnapshot.data())
    .catch(throwInternalError);
};
