import React from 'react';
import classNames from 'classnames';

const AboutLinks = ({ className, ...props }) => {
  return (
    <ul className={classNames('about-link-list', className)} {...props}>
      <li className="about-link-item">
        <a
          className="link"
          href="https://about.flexpit.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          About
        </a>
      </li>
      <li className="about-link-item">
        <a
          className="link"
          href="https://about.flexpit.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </a>
      </li>
      <li className="about-link-item">
        <a
          className="link"
          href="https://about.flexpit.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
      </li>
    </ul>
  );
};

export default AboutLinks;
