import classNames from 'classnames';
import { element, func, oneOfType, string } from 'prop-types';
import React, { Component } from 'react';
import { Header, Image } from 'semantic-ui-react';
import flexpitLogo from '../../assets/images/logo_wordmark_black.png';
import LoginFormContainer from '../../containers/login/LoginFormContainer';
import { loginWithFacebook } from '../../services/authentication';
import AboutLinks from '../AboutLinks';
import StoreBadges from '../store-badges/StoreBadges';

class LoginBlock extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
  };

  static defaultProps = {
    as: 'div',
  };

  signInWithFacebook() {
    loginWithFacebook();
  }

  handleClickFacebookSignIn = () => {
    this.signInWithFacebook();
  };

  render() {
    const { as: As, className, text, ...formProps } = this.props;

    return (
      <As className={classNames('login-block', className)}>
        <Image src={flexpitLogo} alt="FLEXPIT" className="flexpitlogo" />
        <Header size="medium">Sign in</Header>
        {text && <p className="welcometext">{text} </p>}
        <LoginFormContainer
          className="login-form"
          {...formProps}
          onClickFacebookSignIn={this.handleClickFacebookSignIn}
        />
        <StoreBadges className="-small" />
        <AboutLinks style={{ marginTop: '15px' }} />
      </As>
    );
  }
}

export default LoginBlock;
