import firebase from 'firebase/app';
import check from 'check-types';
import axios from 'axios';

import { db } from '../modules/firebase';
import { getProfile } from './authentication';
import { throwInternalError } from '../modules/errors';
import { basicSnapshotHandler, checkArguments } from '../modules/services';
import { getApiEndpoint, getTokenedConfig } from '../modules/api';
import { addMissingUserMentionsUsingApi } from '../modules/user-mentions';
import { getLoggedInUid } from './authentication';

export const parseFireStorePost = function parseFireStorePost({
  dateCreated,
  dateUpdated,
  ...post
}) {
  return {
    ...post,
    dateCreated: dateCreated && dateCreated.toDate(),
    dateUpdate: dateUpdated && dateUpdated.toDate(),
  };
};

const parseFireStoreComment = function parseFireStoreComment({
  dateCreated,
  ...comment
}) {
  return {
    ...comment,
    dateCreated: dateCreated && dateCreated.toDate(),
  };
};

/**
 * Gets the post with the specified uid.
 * @param { string } uid The post to retrieve's uid.
 * @returns { Promise<object> } The Promise of the post's data.
 * @throws { InternalError }
 */
export const getPost = function getPost(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  const postRef = db.collection('posts').doc(uid);

  return postRef
    .get()
    .then(doc => parseFireStorePost(doc.data()))
    .catch(throwInternalError);
};

/**
 * Gets the specified user's posts, ordered by descending date.
 * @param { String } userUid the user id
 * @param { Number } limit the number of posts to get (default: 18)
 */
export const getUserPosts = async function getUserPosts(
  userUid,
  limit = 18,
  startAfter,
) {
  let posts = db
    .collection('posts')
    .where('userUid', '==', userUid)
    .where('public', '==', true)
    .limit(limit + 1) // Fetch one extra document to tiptoe if it is the last batch of docs
    .orderBy('dateCreated', 'desc');

  if (startAfter) {
    posts = posts.startAfter(startAfter);
  }

  const results = posts
    .get()
    .then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => data.push(parseFireStorePost(doc.data())));

      /**
       * We fetched one extra document, if there is less or the same number of
       * documents than the actual provided limit, it means this is the last
       * batch of documents
       */
      const isLastBatch = data.length < limit + 1;

      /**
       * And if this is not the last batch, just remove the last document to have the
       * appropriate number of documents.
       */
      if (!isLastBatch) {
        data.pop();
      }

      // Get last document's snapshot to fetch next batch
      const snapshotLast = querySnapshot.docs[querySnapshot.docs.length - 2];
      return {
        data,
        snapshotLast,
        isLastBatch,
      };
    })
    .catch(error => {
      console.error('Error getUserPosts', error);
      throw error;
    });

  return results;
};

/**
 * Fistbumps or unfistbumps a post for the currently logged in user.
 * @param { string } uid The uid of the post to fistbump/unfistbump
 * @param { boolean } [value = true] `true` to fistbump, `false` to unfistbump.
 *
 * @returns { Promise } The Promise of the post being fistbumped or unfistbumped.
 *
 * @throws { InternalError }
 */
export const fistBumpPost = function fistBumpPost(uid, value = true) {
  // Check parameters
  const userUid = getProfile().uid;
  const params = { uid, userUid, value };
  checkArguments(params, {
    uid: check.nonEmptyString,
    userUid: check.nonEmptyString,
    value: check.boolean,
  });

  const postRef = db.collection('posts').doc(uid);
  const fistBumpRef = postRef.collection('fistBumps').doc(userUid);

  const actionPromise = value
    ? fistBumpRef.set({
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      })
    : fistBumpRef.delete();

  return actionPromise.catch(throwInternalError);
};

/**
 * Reports a post.
 * @param { string } uid The post to report's uid.
 * @returns { Promise<object> } A Promise of the responsive given by axios.
 */
export const reportPost = async function reportPost(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  const url = getApiEndpoint.posts.report(uid);

  let response;

  try {
    const config = await getTokenedConfig();
    response = await axios.post(url, undefined, config);
  } catch (error) {
    throwInternalError(error);
  }

  return response;
};

/**
 * Delete a post.
 * @param { string } uid The post's uid to delete.
 * @returns { Promise<object> } A Promise of the responsive given by axios.
 */
export const deletePost = async function deletePost(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  const postRef = db.collection('posts').doc(uid);

  return postRef.delete().catch(throwInternalError);
};

/**
 * Adds a new comment written by the currently logged in user.
 * @param { Object } comment The data of the new comment to add.
 * @param { string } comment.uid The uid of the post to comment.
 * @param { string } comment.text The text of the comment.
 * @param { string } [ comment.parentUid ] The uid of the parent comment.
 *
 * @returns { Promise } The Promise of the new comment being saved.
 *
 * @throws { InternalError }
 */
export const commentPost = async function commentPost(comment) {
  const userUid = getProfile().uid;
  const params = {
    ...comment,
    userUid,
  };
  checkArguments(params, {
    uid: check.nonEmptyString,
    userUid: check.nonEmptyString,
    text: check.nonEmptyString,
    parentUid: check.maybe.string,
  });

  const { uid, text, parentUid } = comment;

  const postRef = db.collection('posts').doc(uid);
  const postCommentsRef = postRef.collection('comments');

  let result;

  try {
    const finalText = await addMissingUserMentionsUsingApi(text);

    const newComment = {
      userUid,
      text: finalText,
      ...(parentUid ? { parentUid } : {}),
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
    };

    result = await postCommentsRef.add(newComment);
  } catch (error) {
    throwInternalError(error);
  }

  return result;
};

/**
 * Fist bumps or un-fist bumps a comment in a post.
 * @param { object } comment
 * @param { string } comment.uid The uid of the comment to fist bump.
 * @param { string } comment.postUid The uid of the post where the comment is from.
 * @param { boolean } comment.value `true` to fist bump the comment, `false` to
 * un-fist-bump the comment.
 */
export const fistBumpComment = async function fistBumpComment(comment) {
  checkArguments(comment, {
    uid: check.nonEmptyString,
    postUid: check.nonEmptyString,
    value: check.boolean,
  });

  const { uid, postUid, value } = comment;
  const url = getApiEndpoint.posts.fistBumpComment(postUid, uid);

  try {
    const config = await getTokenedConfig();
    await (value
      ? axios.post(url, undefined, config)
      : axios.delete(url, config));
  } catch (error) {
    throwInternalError(error);
  }
};

/**
 * Subscribes to a post's comments.
 * @param { Object } params
 * @param { string } params.uid The uid of the post whose comments to subscribe to.
 * @param { onReceiveDataFunction } params.onReceiveData The handler for document changes.
 * @param { Function } params.onError The error handler.
 * @param { number } [ params.limit ] The number of comments to subscribe to.
 *
 * @returns { Function } A function to unsuscribe from the created subscription.
 */
export const subscribeToPostComments = function subscribeToPostComments(
  params,
) {
  checkArguments(params, {
    uid: check.nonEmptyString,
    onReceiveData: check.function,
    onError: check.function,
    limit: check.maybe.number,
  });

  const { uid, limit, onReceiveData, onError } = params;

  const postRef = db.collection('posts').doc(uid);
  let postCommentsRef = postRef.collection('comments');

  if (limit) {
    postCommentsRef = postCommentsRef.limit(limit);
  }

  postCommentsRef = postCommentsRef.orderBy('dateCreated', 'desc');

  const onSnapshot = basicSnapshotHandler(onReceiveData, parseFireStoreComment);
  const unsuscribe = postCommentsRef.onSnapshot(onSnapshot, onError);

  return unsuscribe;
};

/**
 * Subscribes to a post's fistbumps.
 * @param { Object } params
 * @param { string } uid The uid of the post whose comments to subscribe to.
 * @param { onReceiveDataFunction } onReceiveData The handler for document changes.
 * @param { Function } onError The error handler.
 * @param { number } [ limit ] The number of comments to subscribe to.
 *
 * @returns { Function } A function to unsuscribe from the created subscription.
 */
export const subscribeToPostFistBumps = function subscribeToPostFistBumps(
  params,
) {
  checkArguments(params, {
    uid: check.nonEmptyString,
    onReceiveData: check.function,
    onError: check.function,
    limit: check.maybe.number,
  });

  const { uid, limit, onReceiveData, onError } = params;

  const postRef = db.collection('posts').doc(uid);
  let postFistBumpsRef = postRef.collection('fistBumps');

  if (limit) {
    postFistBumpsRef = postFistBumpsRef.limit(limit);
  }

  postFistBumpsRef = postFistBumpsRef.orderBy('dateCreated', 'desc');

  const onSnapshot = basicSnapshotHandler(onReceiveData);
  const unsuscribe = postFistBumpsRef.onSnapshot(onSnapshot, onError);

  return unsuscribe;
};

/**
 * Subscribes to a user's posts.
 * @param { Object } params
 * @param { string } userUid The uid of the user whose posts to subscribe to.
 * @param { onReceiveDataFunction } onReceiveData The handler for document changes.
 * @param { Function } onError The error handler.
 * @param { number } [ limit ] The number of comments to subscribe to.
 *
 * @returns { Function } A function to unsuscribe from the created subscription.
 */
export const subscribeToUsersPosts = function subscribeToUsersPosts(params) {
  checkArguments(params, {
    userUid: check.nonEmptyString,
    onReceiveData: check.function,
    onError: check.function,
    limit: check.maybe.number,
  });

  const { userUid, limit, onReceiveData, onError } = params;

  let postsRef = db
    .collection('posts')
    .where('userUid', '==', userUid)
    .where('public', '==', true);

  if (limit) {
    postsRef = postsRef.limit(limit);
  }

  postsRef = postsRef.orderBy('dateCreated', 'desc');

  const onSnapshot = basicSnapshotHandler(onReceiveData, parseFireStoreComment);
  const unsuscribe = postsRef.onSnapshot(onSnapshot, onError);

  return unsuscribe;
};

const convertData = function convertPost(data = {}) {
  const { dateCreated, dateUpdated, ...otherData } = data;

  return {
    dateCreated: dateCreated && new Date(dateCreated),
    dateUpdated: dateUpdated && new Date(dateUpdated),
    ...otherData,
  };
};

/**
 * Gets a post without the need of being logged in.
 * @param { string } uid The post to retrieve's uid.
 * @returns { Promise<object> } The Promise of the post's data.
 * @throws { InternalError }
 */
export const getPublicPost = function getPublicPost(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  return axios
    .get(getApiEndpoint.public.posts.get(uid))
    .then(({ data }) => convertData(data))
    .catch(throwInternalError);
};

export const getPublicUsersPosts = function getPublicUsersPosts(args) {
  checkArguments(args, {
    userUid: check.nonEmptyString,
    limit: check.maybe.number,
    offset: check.maybe.number,
  });

  const { userUid, limit = 20, offset } = args;

  const params = {
    offset,
    limit: limit && limit + 1,
  };

  return axios
    .get(getApiEndpoint.public.posts.getUsers(userUid), { params })
    .then(({ data: { data: rawData } }) => {
      const data = rawData.map(convertData);
      const results = limit
        ? {
            data: data.slice(0, limit),
            isLastBatch: data.length <= limit,
          }
        : {
            data,
            isLastBatch: true,
          };

      return results;
    })
    .catch(throwInternalError);
};

export const getPublicPostsComments = function getPublicPostsComments(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  return axios
    .get(getApiEndpoint.public.posts.getComments(uid))
    .then(({ data: { comments } }) => comments.map(convertData))
    .catch(throwInternalError);
};

/**
 * To know if the post belongs to the current user logged in
 * @param { string } userUid - post user uid
 */
export const getIsCurrentUserPost = function getIsCurrentUserPost(userUid) {
  return getLoggedInUid() === userUid;
};
