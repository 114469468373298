import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import { ERROR_DATA } from '../modules/constants/messages';

class ErrorMessage extends Component {
  static defaultProps = {
    floating: true,
  };

  render() {
    const { error, ...props } = this.props;

    const {
      type = 'error',
      message = error.message || error.reason,
      extraChildren,
    } = ERROR_DATA[error.code] || {};

    const typeProp = {
      [type]: true,
    };

    return (
      <Message className="error-message" {...typeProp} {...props}>
        {message} {extraChildren}
      </Message>
    );
  }
}

export default ErrorMessage;
