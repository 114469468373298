import React, { Component, Fragment } from 'react';
import { shape, bool, arrayOf, string, func, node } from 'prop-types';
import { createSelector } from 'reselect';
import debounce from 'lodash/debounce';

import PostItemContainer from '../../containers/posts/PostItemContainer';
import BottomMessage from '../BottomMessage';
import NoDataMessage from '../NoDataMessage';
import VirtualisedNodes from '../VirtualisedNodes';
import { computePostMediumRatio } from '../../modules/css';

class Feed extends Component {
  static propTypes = {
    posts: arrayOf(
      shape({
        uid: string.isRequired,
      }),
    ).isRequired,
    isLastBatch: bool,
    isLoading: bool,
    noDataNode: node,
    requestMoreData: func,
    renderPost: func,
  };

  static defaultProps = {
    posts: [],
    isLastBatch: true,
    isLoading: false,
    noDataNode: <NoDataMessage>No post found to display.</NoDataMessage>,
    renderPost: post => (
      <PostItemContainer data={post} subscriptionParams={{ uid: post.uid }} />
    ),
  };

  static get POST_MAX_WIDTH() {
    return 950;
  }

  static get POST_MARGIN_BOTTOM() {
    return 40;
  }

  state = {
    documentWidth: document.body.clientWidth,
  };

  /**
   * @type { function }
   * Selects the padding bottom value, used to adjust the height of the post
   * based on the format of the post.
   *
   * See: FLEX-828 Allow landscape and portrait images
   */
  selectRatioHelperValues = createSelector(
    (_, props) => props.posts,
    posts => {
      // Create values for each posts, [uid, value]
      const entries = posts.map(post => [
        post.uid,
        computePostMediumRatio(post),
      ]);

      // Create the map, uid => value
      const ratioHelperMap = new Map(entries);

      return ratioHelperMap;
    },
  );

  selectFeedWidth = createSelector(
    state => state.documentWidth,
    documentWidth => {
      // Side padding of 5%
      const pageWidth = 0.9 * documentWidth;

      // There's a max-width
      const feedWidth = Math.min(pageWidth, Feed.POST_MAX_WIDTH);
      return feedWidth;
    },
  );

  selectElementsHeights = createSelector(
    this.selectFeedWidth,
    this.selectRatioHelperValues,
    (feedWidth, ratios) => {
      // Calculate post heights using the ratio
      const heights = [...ratios.values()].map(
        ratio => (feedWidth * ratio) / 100 + Feed.POST_MARGIN_BOTTOM,
      );

      return heights;
    },
  );

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleUpdateDocumentWidth = documentWidth => this.setState({ documentWidth });

  handleResize = debounce(() => {
    this.handleUpdateDocumentWidth(document.body.clientWidth);
  }, 400);

  renderPosts() {
    const { posts, renderPost } = this.props;
    const ratioHelperMap = this.selectRatioHelperValues(this.state, this.props);

    return posts.map(post => (
      <li key={post.uid} className="post-item">
        <div style={{ paddingBottom: `${ratioHelperMap.get(post.uid)}%` }} />
        {renderPost(post)}
      </li>
    ));
  }

  render() {
    const { isLastBatch, isLoading, requestMoreData, noDataNode } = this.props;

    const postNodes = this.renderPosts();
    const elementHeight = this.selectElementsHeights(this.state, this.props);

    return (
      <Fragment>
        <VirtualisedNodes
          as="ol"
          className="post-list"
          isLastBatch={isLastBatch}
          requestMoreData={requestMoreData}
          isLoading={isLoading}
          loader={{ inline: 'centered', size: 'big' }}
          elements={postNodes}
          elementHeight={elementHeight}
          nbPreLoad={5}
          useWindow
        >
          {isLastBatch && postNodes.length > 0 && (
            <BottomMessage>
              You've reached the last post in this feed!
            </BottomMessage>
          )}
        </VirtualisedNodes>
        {!isLoading && postNodes.length === 0 && noDataNode}
      </Fragment>
    );
  }
}

export default Feed;
