import React, { Component, Fragment } from 'react';
import { Form, Icon, Image, Input } from 'semantic-ui-react';
import { string, shape, func } from 'prop-types';
import update from 'immutability-helper';
import { createSelector } from 'reselect';
import nanoid from 'nanoid';

import { uploadFile, storagePaths } from '../../../services/files';
import { injectParamsIntoRoute } from '../../../modules/routes';
import { Map } from 'core-js';

const dataPropType = shape({
  firstName: string,
  lastName: string,
  email: string,
  bio: string,
});

class VerifyProfileForm extends Component {
  static propTypes = {
    formData: dataPropType.isRequired,
    onChange: func,
    onSubmit: func,
    data: dataPropType,
    id: string,
  };

  static defaultProps = {
    id: 'profile-form',
    formData: {},
    data: {},
  };

  state = {
    documents: new Map([[nanoid(), undefined]]),
    progresses: new Map(),
    form: {},
    isLoading: false,
  };

  fileInputsRef = {};

  selectFormData = createSelector(
    state => state.form,
    (_, props) => props.formData,
    (form, formData) => {
      return {
        ...formData,
        ...form,
      };
    },
  );

  selectDocumentFields = createSelector(
    state => state.documents,
    documentsMap => {
      return [...documentsMap.entries()].map(([id, data]) => ({ id, data }));
    },
  );

  componentWillMount() {
    const [doc] = this.selectDocumentFields(this.state);

    this.fileInputsRef[doc.id] = React.createRef();
  }

  async requestSubmit(evt) {
    evt.preventDefault();
    const {
      onChange,
      onSubmit,
      data: { uid },
    } = this.props;

    // If no documents, stop submitting
    const files = Object.keys(this.fileInputsRef)
      .map(id => {
        const ref = this.fileInputsRef[id];
        const file = ref && ref.current.inputRef.current.files[0];
        return file
          ? {
              id,
              data: file,
            }
          : undefined;
      })
      .filter(a => a);

    if (files.length === 0) {
      console.warn(
        '<VerifyProfileForm />: attempted submitting with no documents',
      );
      return;
    }

    this.setState({ isLoading: true });

    // Put the picture in the user's folder
    const basePath = injectParamsIntoRoute(
      storagePaths.usersVerificationFiles,
      {
        userUid: uid,
      },
    );

    const fileRefs = [];
    let error;

    try {
      for (let i = 0; i < files.length; i += 1) {
        const { id, data } = files[i];
        const extension = data.name.slice(data.name.lastIndexOf('.'));
        const path = `${basePath}/${id}${extension}`;
        const file = {
          data,
          path,
        };

        // Upload document
        await uploadFile(file, progress => {
          const { onProgressChange } = this.props;
          const totalProgress = (i * 100 + progress) / files.length;
          onProgressChange(totalProgress);
        });
        fileRefs.push(path);
      }
    } catch (internalError) {
      error = internalError;
      console.error(error);
    }

    if (!error) {
      // Update profile's reference to the picture, and send submit request
      const artificialEvent = { name: 'files', value: fileRefs };
      onChange({ target: artificialEvent }, artificialEvent, () => {
        const { onProgressChange } = this.props;
        onProgressChange(undefined);
        this.setState(
          update(this.state, {
            isLoading: { $set: false },
          }),
        );
        onSubmit();
      });
    }
  }

  handleFileInputChange = ({ target: { name, files } }) => {
    const file = files[0];

    // If not an image, just indicate the state that a file has been picked
    if (!file.type.startsWith('image/')) {
      const newId = nanoid();
      this.fileInputsRef[newId] = React.createRef();
      this.setState(
        update(this.state, {
          documents: {
            $add: [[name, null], [newId, undefined]],
          },
        }),
      );
      return;
    }

    // Read file as url
    const fileReader = new FileReader();

    // When filereader has finished reading the file, update the state with the result
    fileReader.addEventListener('load', ({ target: { result } }) => {
      const newId = nanoid();
      this.fileInputsRef[newId] = React.createRef();
      this.setState(
        update(this.state, {
          documents: {
            $add: [[name, result], [newId, undefined]],
          },
        }),
      );
    });

    fileReader.readAsDataURL(file);
  };

  handleSubmit = (...args) => this.requestSubmit(...args);

  render() {
    const {
      id,
      data,
      onChange,
      formData: _,
      loading,
      onSubmit,
      initialValue,
      onProgressChange,
      ...props
    } = this.props;
    const { isLoading } = this.state;

    const formData = this.selectFormData(this.state, this.props);
    const documentFields = this.selectDocumentFields(this.state);
    const nonEmptyDocuments = documentFields.filter(
      doc => doc.data !== undefined,
    );

    const getFieldProps = name => ({
      onChange,
      name,
      id: `${id}-${name}`,
      value: formData[name] !== undefined ? formData[name] : data[name] || '',
    });

    const documentFieldNodes = documentFields.map(doc => (
      <Form.Field key={doc.id}>
        <Input
          id={doc.id}
          type="file"
          onChange={this.handleFileInputChange}
          name={doc.id}
          ref={this.fileInputsRef[doc.id]}
        >
          {doc.data ? (
            <Image
              avatar
              alt="Document preview"
              style={{ alignSelf: 'center ' }}
              src={doc.data}
            />
          ) : (
            <Icon
              name={doc.data !== undefined ? 'file outline' : 'add'}
              size="large"
              style={{ alignSelf: 'center ' }}
            />
          )}
          <input />
        </Input>
      </Form.Field>
    ));

    return (
      <Fragment>
        <Form id={id} loading={loading} onSubmit={this.handleSubmit} {...props}>
          <div className="formrow">
            <label className="leftlabel">Documents</label>
            <div className="inputs">{documentFieldNodes}</div>
          </div>
          <Form.TextArea
            className="formrow"
            placeholder="Why do you want to be verified?"
            label="Why"
            {...getFieldProps('why')}
          />
          <Form.Button
            primary
            type="submit"
            className="formrow"
            disabled={isLoading || nonEmptyDocuments.length === 0}
          >
            Submit
          </Form.Button>
        </Form>
      </Fragment>
    );
  }
}

export default VerifyProfileForm;
