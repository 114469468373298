/**
 * Module about units and conversion.
 */

const kgToLb = 2.2046226218488;
const lbToKg = 0.45359237;

/**
 * Converts a weight in kilograms to pounds.
 * @param { number|string } kg The weight in kilograms.
 * @returns { number } The weight converted in pounds.
 */
export const convertKgToLbs = function convertKgToLbs(kg) {
  const nbKg = parseFloat(kg);
  if (isNaN(nbKg)) return undefined;
  const lbs = nbKg * kgToLb;
  return lbs;
};

/**
 * Converts a weight in pounds to kilograms.
 * @param { number|string } lbs The weight in pounds.
 * @returns { number } The weight converted in kilograms
 */
export const convertLbsToKg = function convertLbsToKg(lbs) {
  const nbLbs = parseFloat(lbs);
  if (isNaN(nbLbs)) return undefined;
  const kg = nbLbs * lbToKg;
  return kg;
};

/**
 * Rounds the weight to the closest .0 or .5 kilogram.
 * @param { number|string } kg The weight in kgs.
 * @returns { number } The weight in kgs rounded to .5 or .0.
 */
export const roundKg = function roundKg(kg) {
  const decimalKg = parseFloat(kg);
  if (isNaN(decimalKg)) return undefined;
  const roundedKg = Math.round(decimalKg * 2) / 2;
  return roundedKg;
};

/**
 * Converts and rounds (to .5 or .0) the weight in pounds to kilograms.
 * @param { number|string } lbs The weight in pounds.
 * @returns { number } The weight converted in kilograms, rounded to .5 or .0.
 */
export const convertLbsToRoundedKg = function convertLbsToRoundedKg(lbs) {
  const nbLbs = parseFloat(lbs);
  if (isNaN(nbLbs)) return undefined;
  const decimalKg = nbLbs * lbToKg;
  const roundedKg = Math.round(decimalKg * 2) / 2;
  return roundedKg;
};

/**
 * Converts and rounds a weight in kilograms to pounds.
 * @param { number|string } kg The weight in kilograms.
 * @returns The weight converted in pounds and rounded.
 */
export const convertKgToRoundedLbs = function convertKgToRoundedLbs(kg) {
  const nbKg = parseFloat(kg);
  if (isNaN(nbKg)) return undefined;
  const lbs = convertKgToLbs(nbKg);
  return Math.round(lbs);
};

const inchToCm = 2.54;

/**
 * Converts a length in centimeters to inches.
 * @param { number|string } cm The length in centimeters.
 * @returns { number } The length converted in inches.
 */
export const convertCmToInch = function convertCmToInch(cm) {
  const nbCm = parseFloat(cm);
  if (isNaN(nbCm)) return undefined;
  const inches = nbCm / inchToCm;
  return inches;
};

/**
 * Converts a length in centimeters to feet and inches.
 * @param { number|string } cm The length in centimeters.
 * @returns { object } An object with the length converted in floored feet and
 * floored inches. Example: { feet: 5, inches: 10 }
 */
export const convertCmToFeetAndInches = function convertCmToFeetAndInches(cm) {
  const nbCm = parseFloat(cm);
  if (isNaN(nbCm)) return undefined;
  const totalInches = convertCmToInch(nbCm);
  const feet = Math.floor(totalInches / 12);
  const inches = Math.floor(totalInches % 12);
  return { feet, inches };
};

/**
 * Converts a length in feet and inches to centimeters.
 * @param { object } measures
 * @param { number|string } measures.feet The length in feet.
 * @param { number|string } measures.inches The length in inches.
 * @returns { number } The length converted in centimeters.
 */
export const convertFeetAndInchesToCm = function convertFeetAndInchesToCm({
  feet,
  inches,
}) {
  const nbFeet = parseFloat(feet);
  const nbInches = parseFloat(inches);
  if (isNaN(nbFeet) || isNaN(nbInches)) return undefined;
  const totalInches = nbFeet * 12 + nbInches;
  const cm = totalInches * inchToCm;
  return cm;
};

/**
 * Rounds a decimal number while keeping decimals
 * @param { number } number The number to round.
 * @param { number } precision The number of decimals to keep after the dot.
 * @returns { number } The round number with the appropriate number of decimals.
 */
export const precisionRound = function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

/**
 * Converts a number in bytes to megabytes.
 * @param { number } bytes The number of bytes.
 * @returns { number } The number of bytes in megabytes.
 */
export const convertBytesToMegaBytes = function convertBytesToMegaBytes(bytes) {
  const megabytes = bytes / 1000000;
  return megabytes;
};
