import React, { Component, Fragment } from 'react';
import PropTypes, { shape, number, string, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Icon,
  Image,
  Header,
  Loader,
  Dimmer,
  Dropdown,
  Modal,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import classNames from 'classnames';

import CommentsContainer from '../../containers/posts/CommentsContainer';
import { getIsPublic, getLoggedInUid } from '../../services/authentication';
import { getIsCurrentUserPost } from '../../services/posts';
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { fistBumpPost, reportPost, deletePost } from '../../services/posts';
import AddCommentForm from '../../containers/posts/AddCommentForm';
import PublicCommentsContainer from '../../containers/posts/PublicCommentsContainer';
import Video from '../Video';
import withLoggedInUser from '../higher-order/withLoggedInUser';
import { pluralise } from '../../modules/humanise';
import { injectParamsIntoRoute, routes } from '../../modules/routes';
import TextWithContentLinks from '../TextWithContentLinks';
import { ACTIVITY_TYPES } from '../../modules/constants/activities';
import SvgImage from '../SvgImage';
import { FLEXPIT_ICONS } from '../../assets/icons/flexpit';
import AnimatedIcon from '../icons/AnimatedIcon';
import ScrollingModal from '../ScrollingModal';
import FistBumpList from '../../containers/posts/FistBumpList';
import Weight from '../Weight';
import { TROPHY_ASSETS } from '../../assets/images/trophies';
import { TROPHIES } from '../../modules/constants/trophies';

const newPrAsset = TROPHY_ASSETS[TROPHIES.newPr];

/**
 * Factory to create new selectors to check if the post was fist bumped by the currently
 * logged in user.
 * @param { string } loggedInUserUid The logged-in user's uid.
 * @returns { Function } A new hoisted selector.
 */
const makeGetIsFistBumpedByMe = loggedInUserUid =>
  createSelector(
    (_, props) => props.fistBumps,
    fistBumps => {
      return Boolean(
        fistBumps.find(fistBump => fistBump.uid === loggedInUserUid),
      );
    },
  );

const orderComments = {
  field: 'dateCreated',
  order: 'asc',
};

const postMenuOptions = [
  {
    text: 'Report post',
    value: 'reporting',
    isPublic: false,
    isLoggedInMenu: false,
  },
  {
    text: 'Delete post',
    value: 'deleting',
    isPublic: false,
    isLoggedInMenu: true,
  },
];

// const menuActionsText = {
//   reporting: 'Post successfully reported!',
//   deleting: 'Post successfully deleted!',
// };

class PostItem extends Component {
  static propTypes = {
    data: shape({
      uid: string.isRequired,
      userUid: string.isRequired,
      username: string,
      profileThumbUrl: string,
      locationName: string,
      imageMeta: shape({
        height: number,
        width: number,
        isLandscape: bool,
      }),
    }).isRequired,
    fistBumps: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired,
      }),
    ),
    maxSize: number,
  };

  static defaultProps = {
    fistBumps: undefined,
    maxSize: undefined,
  };

  state = {
    fistBumpModal: {
      isOpen: false,
    },
    fistBumping: {
      animate: false,
      error: undefined,
      isLoading: false,
      result: undefined,
    },
    menu: {
      type: '',
      error: undefined,
      isLoading: false,
      result: false,
    },
  };

  /**
   * The component instance's selector.
   * @type { Function }
   */
  madeGetIsFistBumpedByMe = makeGetIsFistBumpedByMe(getLoggedInUid());

  selectFistBumpUids = createSelector(
    (_, props) => props.fistBumps,
    (fistBumps = []) => fistBumps.map(fistBump => fistBump.uid),
  );

  selectNbFistBumps = createSelector(
    (_, props) => props.data,
    (_, props) => props.fistBumps,
    (_, props) => props.isLoading,
    (data, fistBumps, isLoading) =>
      fistBumps && !isLoading ? fistBumps.length : data.fistBumpsNo,
  );

  selectAuthor = createSelector(
    (_, props) => props.data,
    (_, props) => props.cachedUsers,
    (data, cachedUsers = []) => {
      const cachedUser = cachedUsers.find(u => u.uid === data.userUid);
      const author = cachedUser || {
        isLoading: false,
        data: {
          username: data.username,
          thumbnailUrl: data.profileThumbUrl,
        },
      };

      return author;
    },
  );

  selectVerifiedByCoach = createSelector(
    (_, props) => props.data,
    (_, props) => props.cachedUsers,
    (data, cachedUsers = []) => {
      let coach;

      if (data.verifiedByCoach) {
        const cachedUser = cachedUsers.find(
          u => u.uid === data.verifiedByCoach,
        );
        coach = cachedUser || {
          isLoading: false,
          data: {
            username: data.verifiedByCoachUsername,
          },
        };
      }

      return coach;
    },
  );

  selectMomentCreated = createSelector(
    (_, props) => props.data.dateCreated,
    moment,
  );

  // FLEX-828 Handle different format of images

  /**
   * @type { function }
   * Selects the format of the post.
   */
  selectFormat = createSelector(
    (_, props) => props.data.imageMeta,
    (imageMeta = { isLandscape: false }) => {
      let format = 'square';
      if (imageMeta && imageMeta.isLandscape) {
        const { width, height } = imageMeta;
        if (width > height) {
          format = 'landscape';
        } else if (width < height) {
          format = 'portrait';
        }
      }
      return format;
    },
  );

  popupTimer = undefined;

  // menuOptionRef = React.createRef();

  componentWillReceiveProps(nextProps) {
    const { isLoading } = this.props;
    const {
      fistBumping: { animate },
    } = this.state;

    // Loaded for first time
    if (animate === undefined && isLoading && !nextProps.isLoading) {
      this.setState(
        update(this.state, { fistBumping: { animate: { $set: false } } }),
      );
    } else if (!animate && !isLoading && !nextProps.isLoading) {
      this.setState(
        update(this.state, { fistBumping: { animate: { $set: true } } }),
      );
    }
  }

  componentWillUnmount() {
    if (this.popupTimer) {
      clearTimeout(this.popupTimer);
    }
  }

  /**
   * Popup timer
   * @param { number } time Popup time on screen, default is 3000ms
   */
  onCallbackSetPopupTimer(time = 3000) {
    this.popupTimer = setTimeout(() => {
      this.setState(
        update(this.state, {
          menu: {
            result: { $set: false },
            error: { $set: undefined },
          },
        }),
      );

      this.popupTimer = undefined;
    }, time);
  }

  /**
   * Gets whether the post has been fist bumped by the current user or not,
   * using the hoisted selector.
   *
   * @returns { boolean } true if the post was fist bumped by the currently
   * logged-in user, false else.
   */
  getIsFistBumpedByMe() {
    return this.madeGetIsFistBumpedByMe(this.state, this.props);
  }

  /**
   * Fist bumps (or un-fist bumps) the post by the currently logged in user.
   */
  async fistBumpPost() {
    const {
      data: { uid },
    } = this.props;
    const isFistBumped = this.getIsFistBumpedByMe();

    if (!uid) return;

    // Show loading state
    this.setState(
      update(this.state, {
        fistBumping: {
          error: { $set: undefined },
          isLoading: { $set: true },
        },
      }),
    );

    let error = null;
    let result = undefined;

    // Request to toggle the fist bump value
    try {
      await fistBumpPost(uid, !isFistBumped);
      result = !isFistBumped;
    } catch (internalError) {
      console.error(internalError);
      error = internalError;
    }

    // Remove loading state and set error in state
    this.setState(
      update(this.state, {
        fistBumping: {
          error: { $set: error },
          isLoading: { $set: false },
          result: { $set: result },
        },
      }),
    );
  }

  /** Bound click listener */
  handleRequestFistBump = () => this.fistBumpPost();

  handleSelectOption = async ({ target }, data) => {
    const { value } = data || target;

    this.setState(
      update(this.state, {
        menu: {
          type: { $set: value },
          error: { $set: undefined },
          isLoading: { $set: true },
          result: { $set: false },
        },
      }),
    );

    let error = null;
    let status = false;

    const {
      data: { uid },
    } = this.props;

    try {
      switch (value) {
        case 'reporting': {
          await reportPost(uid).then(result => {
            // this.onCallbackSetPopupTimer(result);
            status = true;
          });
          break;
        }
        case 'deleting': {
          await deletePost(uid).then(result => {
            // this.onCallbackSetPopupTimer(result);
            status = true;
          });
          break;
        }
        default: {
          console.warn(`<PostItem />: unrecognised option '${value}'`);
        }
      }
    } catch (internalError) {
      error = internalError;
    }

    this.setState(
      update(this.state, {
        menu: {
          isLoading: { $set: false },
          error: { $set: error },
          result: { $set: status },
        },
      }),
    );
  };

  handleOpenFistBumpModal = () => {
    this.setState(
      update(this.state, {
        fistBumpModal: {
          isOpen: { $set: true },
        },
      }),
    );
  };

  handleCloseFistBumpModal = () => {
    this.setState(
      update(this.state, {
        fistBumpModal: {
          isOpen: { $set: false },
        },
      }),
    );
  };

  renderComments() {
    const {
      data: { uid },
    } = this.props;

    const isPublic = getIsPublic();

    return isPublic ? (
      <PublicCommentsContainer postUid={uid} />
    ) : (
      <CommentsContainer
        subscriptionParams={{ uid }}
        orderBy={orderComments}
        load="before"
        postUid={uid}
      />
    );
  }

  renderUserHeader() {
    const {
      data: { locationName, userUid },
    } = this.props;
    const { menu } = this.state;

    const isCurrentUserPost = getIsCurrentUserPost(userUid);

    const { data: authorData, isLoading } = this.selectAuthor(
      this.state,
      this.props,
    );

    const { username, photoUrl, thumbnailUrl = photoUrl } = authorData || {};
    const momentCreated = this.selectMomentCreated(this.state, this.props);

    const userProfileUrl =
      username &&
      injectParamsIntoRoute(routes.user.profile, {
        username,
      });

    const menuOptionNodes = postMenuOptions
      .filter(
        option =>
          // If not public, require to be logged in
          (option.isPublic || Boolean(getLoggedInUid())) &&
          // If logged in option, requires to the be the current user's post
          (!option.isLoggedInMenu || isCurrentUserPost),
      )
      .map(({ isPublic, isLoggedInMenu, ...props }) => (
        <Dropdown.Item
          key={props.value}
          {...props}
          onClick={this.handleSelectOption}
        />
      ));

    const trigger = menu.isLoading ? (
      <Loader size="tiny" active inline />
    ) : (
      <Icon name="ellipsis horizontal" size="large" />
    );

    return (
      <div className="userheader">
        <Header size="small" color="grey">
          {isLoading && (
            <Loader inline active={isLoading} className="-notinverted" />
          )}
          {thumbnailUrl && userProfileUrl && (
            <Link to={userProfileUrl} className="image">
              <Image circular src={thumbnailUrl} />
            </Link>
          )}
          {!isLoading && userProfileUrl && (
            <Header.Content>
              <Link className="semantic-link" to={userProfileUrl}>
                @{username}
              </Link>{' '}
              <time className="date">
                {momentCreated.format('MM/DD/YYYY HH:mm')}
              </time>
              {locationName && (
                <Header.Subheader>{locationName}</Header.Subheader>
              )}
            </Header.Content>
          )}
        </Header>
        {menuOptionNodes.length > 0 && (
          <Dropdown
            // ref={this.menuOptionRef}
            className="optionmenu"
            direction="right"
            icon={null}
            selectOnBlur={false}
            trigger={trigger}
          >
            <Dropdown.Menu>{menuOptionNodes}</Dropdown.Menu>
          </Dropdown>
        )}
        {/* <Popup
          context={this.menuOptionRef && this.menuOptionRef.current}
          open={menu.error || menu.result}
          openOnTriggerClick={false}
          on={[]}
        >
          {menu.error ? 'Something went wrong.' : menuActionsText[menu.type]}
        </Popup> */}
      </div>
    );
  }

  renderVerificationStatus() {
    const {
      data: { status },
    } = this.props;

    let icon;
    let child;

    switch (status) {
      case 'unverified': {
        icon = FLEXPIT_ICONS.verificationPendingLift;
        child = 'Pending verification';
        break;
      }

      case 'correct': {
        const { data: coach, isLoading } =
          this.selectVerifiedByCoach(this.state, this.props) || {};
        icon = FLEXPIT_ICONS.verifiedLift;
        child = (
          <Fragment>
            Verified{' '}
            <Dimmer active={isLoading} inverted>
              <Loader inline active={isLoading} size="mini" />
            </Dimmer>
            {coach && (
              <Link
                to={injectParamsIntoRoute(routes.user.profile, {
                  username: coach.username,
                })}
              >
                @{coach.username}
              </Link>
            )}
          </Fragment>
        );
        break;
      }

      default: {
        icon = {};
      }
    }

    return (
      <div className="verifiedstatus">
        <SvgImage
          src={icon.svg}
          fallback={icon.png}
          icon
          size="small"
          style={{
            marginRight: child && '0.67em',
          }}
        />
        {child}
      </div>
    );
  }

  render() {
    const {
      data: {
        uid,
        isLift,
        mediaType,
        mediaUrl,
        thumbnail,
        personalRecord,
        activity,
        activityType,
        repsNo,
        weight,
        caption,
        status,
        imageMeta,
      },
      isLoading,
    } = this.props;

    const { fistBumping, fistBumpModal } = this.state;

    const format = this.selectFormat(this.state, this.props);

    const isPublic = getIsPublic();
    const isFistBumpedByMe = !isPublic && this.getIsFistBumpedByMe();
    const fistBumpsNo = this.selectNbFistBumps(this.state, this.props);
    const uiWeight = activityType !== ACTIVITY_TYPES.time &&
      weight !== undefined && (
        <Fragment>
          @ <Weight>{weight}</Weight>
        </Fragment>
      );

    const nbFistBumpNode = (
      <strong className="strong-info">
        {fistBumpsNo} {pluralise('fist bump', fistBumpsNo)}
      </strong>
    );

    return (
      <Fragment>
        <article className="post-block">
          <div
            className={classNames(
              'mediacontainer',
              mediaType === 'I' && `-${format}`,
            )}
          >
            {mediaType === 'I' && <Image className="media" src={mediaUrl} />}
            {mediaType === 'V' && (
              <Video
                className="media"
                poster={
                  thumbnail && thumbnail.standard && thumbnail.standard.url
                }
                source={mediaUrl}
                meta={imageMeta}
              >
                {['unverified', 'correct'].includes(status) &&
                  this.renderVerificationStatus()}
              </Video>
            )}
          </div>

          <div className="content">
            {this.renderUserHeader()}

            {!isPublic && !isLoading && (
              <div className="header">
                <div className="button-list -block actions">
                  <button
                    className="button -semantic"
                    disabled={fistBumping.isLoading}
                    onClick={this.handleRequestFistBump}
                  >
                    <AnimatedIcon
                      name="fist-bump"
                      size="medium"
                      animate={fistBumping.animate && isFistBumpedByMe}
                      animated={isFistBumpedByMe}
                    />
                  </button>
                </div>
                {personalRecord && (
                  <div className="personalrecord">
                    <SvgImage src={newPrAsset.svg} fallback={newPrAsset.png} />{' '}
                    New PR
                  </div>
                )}
              </div>
            )}

            <div className="body">
              {getLoggedInUid() && fistBumpsNo > 0 ? (
                <button
                  className="semantic-link"
                  onClick={this.handleOpenFistBumpModal}
                  aria-label="Open fist bump modal"
                >
                  {nbFistBumpNode}
                </button>
              ) : (
                nbFistBumpNode
              )}

              {isLift && (
                <Header className="activity" size="medium">
                  {activity} {repsNo} reps {uiWeight}
                </Header>
              )}

              <TextWithContentLinks as="p" className="caption">
                {caption}
              </TextWithContentLinks>
              <div className="comments">{this.renderComments()}</div>
            </div>

            {!isPublic && (
              <div className="addcomment">
                <AddCommentForm extraParams={{ uid }} contrasted simple />
              </div>
            )}
          </div>
        </article>
        {getLoggedInUid() && (
          <ScrollingModal
            size="tiny"
            open={fistBumpModal.isOpen}
            onClose={this.handleCloseFistBumpModal}
          >
            <Modal.Header>
              <Button
                floated="right"
                className="tertiary"
                onClick={this.handleCloseFistBumpModal}
              >
                Close
              </Button>
              Fist bumps
            </Modal.Header>
            <Modal.Content>
              <FistBumpList
                userUids={this.selectFistBumpUids(this.state, this.props)}
                withSpottingButton
              />
            </Modal.Content>
          </ScrollingModal>
        )}
      </Fragment>
    );
  }
}

export default PostItem;

export const PostItemWithCurrentUserState = withLoggedInUser(PostItem);
