/**
 * Helper functions for services.
 */

import check from 'check-types';
import identity from 'lodash/identity';

/**
 * @callback onReceiveDataFunction
 * @param { Object } params
 * @param { Object[] } params.added The subscribed documents that were added.
 * @param { Object[] } params.removed The subscribed documents that were removed.
 * @param { Object[] } params.updated The subscribed documents that were updated.
 */

/**
 * Gets a basic snapshot handler for realtime subscription.
 * @param { onReceiveDataFunction } onReceiveData The handler to pass the data to.
 * @returns { Function }
 */
export const basicSnapshotHandler = function basicSnapshotHandler(
  onReceiveData,
  parseData = identity,
) {
  return querySnapshot => {
    const data = querySnapshot.docChanges().reduce(
      (sortedData, change) => {
        // Get the array of corresponding changes (added, modified, removed)
        const array = sortedData[change.type];

        // Get data, and add an id if none found (subcollections for example)
        const data = parseData(change.doc.data());
        const idData = !data.uid ? { uid: change.doc.id, ...data } : data;

        // Add the id to the array
        array.push(idData);

        // Return the object for the next iteration
        return sortedData;
      },
      {
        added: [],
        modified: [],
        removed: [],
      },
    );

    onReceiveData(data);
  };
};

/**
 * Checks all arguments using the check-types library, and throws an error if
 * one of the argument does not pass the test.
 *
 * @param { Object } params The parameters to check
 * @param { Object } types An object associating the property name with a check function.
 * For example: { uid: check.nonEmptyString, text: check.string }
 * @param { ...boolean } [ extraCondition ] Extra conditions result to fulfill.
 * @returns { boolean } true if the arguments were properly checked.
 * @throws { Error } if one of the parameters did not pass the test.
 */
export const checkArguments = function checkArguments(
  params,
  types,
  ...extraConditions
) {
  if (
    !check.all(check.map(params, types)) ||
    !extraConditions.every(identity)
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = params;
    console.error(error);
    throw error;
  }

  return true;
};
