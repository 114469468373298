import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';

import iphoneXMock from '../assets/images/mobile_app_iphonex.png';
import ContentContainer from '../components/ContentContainer';
import LoginBlock from '../components/login/LoginBlock';

class HomePage extends Component {
  render() {
    const props = this.props;

    return (
      <ContentContainer className="home-page" size="big">
        <div className="golden-container">
          <div className="asection -padded">
            <Image src={iphoneXMock} alt="iOS application screen" />
          </div>
          <LoginBlock
            className="bsection -padded"
            text="Welcome to FLEXPIT! Please use the form below to sign in."
            contrasted
            subtilLoading
            {...props}
          />
        </div>
      </ContentContainer>
    );
  }
}

export default HomePage;
