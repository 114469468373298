export const USER_SEXES = {
  female: 'F',
  male: 'M',
  other: 'O',
};

export const USER_SEX_TEXTS = {
  [USER_SEXES.female]: 'Female',
  [USER_SEXES.male]: 'Male',
  [USER_SEXES.other]: 'Other',
};

export const USER_SPORTS = {
  baseball: 'Baseball',
  basketball: 'Basketball',
  crossCountry: 'Cross Country',
  football: 'Football',
  golf: 'Golf',
  gymnastics: 'Gymnastics',
  hockey: 'Ice Hockey',
  lacross: 'Lacrosse',
  skiing: 'Skiing',
  soccer: 'Soccer',
  swimming: 'Swimming',
  tennis: 'Tennis',
  trackAndField: 'Track and Field',
  volleyball: 'Volleyball',
  waterPolo: 'Water Polo',
  wrestling: 'Wrestling',
  other: 'Other',
};
