import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import debounce from 'lodash/debounce';
import MediaQuery from 'react-responsive';

import '../styles/App.scss';

import { firebaseAuth } from '../modules/firebase';
import { getProfile, logout } from '../services/authentication';
import {
  getCurrentUser,
  subscribeToUser,
  checkIsUserRegistered,
} from '../services/users';

// Route and utils
import ProtectedRoute from './ProtectedRoute';
import { routes } from '../modules/routes';
import { LoggedInUserContext } from '../components/higher-order/withLoggedInUser';
import ErrorMessage from '../components/ErrorMessage';
import { ERROR_CODES } from '../modules/constants/messages';

// Layouts and wrappers
import SidebarLayout from '../layouts/SidebarLayout';
import PublicHeaderbarLayout from '../layouts/HeaderbarLayout';
import UserHeaderbarLayout from '../containers/HeaderbarLayoutContainer';
import LoggedInUserCache from '../containers/users/UserCache';
import PublicUserCache from '../containers/users/PublicUserCache';

// Pages
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import ProfilePage from '../pages/ProfilePage';
import HashtagFeedPage from '../pages/HashtagFeedPage';
import FeedPage from '../pages/FeedPage';
import LeaderboardPage from '../pages/LeaderboardPage';
import ActivityLeaderboardPageContainer from '../containers/pages/ActivityLeaderboardPageContainer';
import CurrentUserAccountPage from '../containers/pages/CurrentUserAccountPage';
import FlexpitStandardPageContainer from '../containers/pages/FlexpitStandardPageContainer';
import StoreLinksPage from '../pages/StoreLinksPage';
import SinglePostPage from '../pages/SinglePostPage';

class App extends Component {
  state = {
    currentUser: LoggedInUserContext.initialState,
    error: undefined,
  };

  timeClearingError = debounce(() => this.setState({ error: undefined }), 5000);

  unsuscribeCheckSuspended = () =>
    console.warn('<App />: trying to unsuscribe before having subscribed');

  selectIsUserLoggedIn = createSelector(
    state => state.currentUser.isLogged,
    state => state.currentUser.isRegistered,
    state => state.currentUser.isSuspended,
    (isLogged, isRegistered, isSuspended) => {
      if (isLogged === undefined) {
        return undefined;
      }

      if (isLogged && isRegistered === undefined) {
        return undefined;
      }

      if (isLogged && isRegistered && isSuspended === undefined) {
        return undefined;
      }

      return isLogged && isRegistered && !isSuspended;
    },
  );

  componentDidMount() {
    firebaseAuth().onAuthStateChanged(this.handleAuthStateChange);
  }

  componentWillUnmount() {
    this.unsuscribeCheckSuspended();
  }

  async checkIsUserAuthorised() {
    // Has user completed onboarding?
    let isRegistered;
    let error = null;

    try {
      isRegistered = await checkIsUserRegistered();
    } catch (internalError) {
      error = internalError;
    }
    if (isRegistered === false) {
      const otherError = new Error('User is not registered');
      otherError.code = ERROR_CODES.userNotRegistered;
      this.handleLoginError(otherError);
      logout();
    }

    this.setState(
      update(this.state, {
        currentUser: {
          isRegistered: { $set: isRegistered },
          error: { $set: error },
        },
      }),
    );

    if (isRegistered) {
      this.unsuscribeCheckSuspended = subscribeToUser(
        this.handleUserDataChange,
      );
      this.refreshCurrentUserProfile();
    }
  }

  /**
   * Requests to refresh the user using the API, or to update the data manually.
   * @param { object } [updateQuery] The update query for the profile. If none is given,
   * the function will call the API.
   */
  async refreshCurrentUserProfile(updateQuery) {
    let error = null;
    let profile = updateQuery;
    let lastUpdatedAt = {};

    if (!profile) {
      try {
        const data = await getCurrentUser();
        profile = { $set: data };
        lastUpdatedAt = { lastUpdatedAt: { $set: Date.now() } };
      } catch (internalError) {
        error = internalError;
      }
    }

    this.setState(
      update(this.state, {
        currentUser: {
          profile,
          isFetching: { $set: false },
          error: { $set: error },
          ...lastUpdatedAt,
        },
      }),
    );
  }

  handleAuthStateChange = authUser => {
    // Update the state with the logged in status and the information we already have (uid)
    const isLogged = Boolean(authUser);
    const isFetching = isLogged;
    const user = isLogged ? { uid: getProfile().uid } : null;

    this.setState(
      update(this.state, {
        currentUser: {
          isLogged: { $set: isLogged },
          isRegistered: { $set: undefined },
          isSuspended: { $set: undefined },
          isFetching: { $set: isFetching },
          profile: { $set: user },
          error: { $set: undefined },
        },
      }),
    );

    if (isLogged) {
      this.checkIsUserAuthorised();
    }
  };

  handleUserDataChange = user => {
    if (user.suspendedProfile) {
      const error = new Error('Your account has been suspended!');
      error.code = 'auth/user-disabled';
      this.setState(
        update(this.state, {
          currentUser: {
            isSuspended: { $set: true },
          },
          error: { $set: error },
        }),
      );
      this.timeClearingError();

      this.unsuscribeCheckSuspended();
      logout();
    } else {
      const {
        currentUser: { isSuspended },
      } = this.state;
      if (isSuspended === undefined) {
        this.setState(
          update(this.state, {
            currentUser: {
              isSuspended: { $set: false },
            },
          }),
        );
      }
    }
  };

  handleRequestRefreshCurrentUser = (...args) => {
    const {
      currentUser: { isLogged },
    } = this.state;
    return isLogged
      ? this.refreshCurrentUserProfile(...args)
      : Promise.resolve(null);
  };

  handleLoginError = error => {
    this.setState({ error });
    this.timeClearingError();
  };

  handleLoginSuccess = () =>
    this.setState(
      update(this.state, {
        error: { $set: null },
        currentUser: {
          isLogged: { $set: true },
        },
      }),
    );

  render() {
    const { currentUser, error } = this.state;

    const currentUserState = {
      ...currentUser,
      refreshCurrentUser: this.handleRequestRefreshCurrentUser,
    };

    const isLoggedIn = this.selectIsUserLoggedIn(this.state);

    // Display a loader until the current status of logged in is known
    if (isLoggedIn === undefined) {
      return <Loader active indeterminate size="massive" />;
    }

    let HeaderbarLayout = UserHeaderbarLayout;
    let UserCache = LoggedInUserCache;

    // Use different components if logged out
    if (!isLoggedIn) {
      HeaderbarLayout = PublicHeaderbarLayout;
      UserCache = PublicUserCache;
    }

    const mainContent = (
      <HeaderbarLayout>
        <ul className="floating-message-list">
          {error && <ErrorMessage as="li" error={error} />}
        </ul>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              isLoggedIn ? (
                <FeedPage />
              ) : (
                <HomePage
                  onError={this.handleLoginError}
                  onSuccess={this.handleLoginSuccess}
                />
              )
            }
          />
          <Route
            path={routes.login}
            render={props =>
              isLoggedIn ? <Redirect to="/" /> : <LoginPage {...props} />
            }
          />
          <ProtectedRoute
            exact
            path={routes.leaderboard.root}
            component={LeaderboardPage}
            currentUserState={currentUser}
            loginText="You need to sign in to gain access to the leaderboards."
            onLoginSuccess={this.handleLoginSuccess}
            onLoginError={this.handleLoginError}
          />
          <ProtectedRoute
            path={routes.leaderboard.activity}
            component={ActivityLeaderboardPageContainer}
            currentUserState={currentUser}
            loginText="You need to sign in to gain access to the leaderboards."
            onLoginSuccess={this.handleLoginSuccess}
            onLoginError={this.handleLoginError}
          />
          <ProtectedRoute
            path={routes.standard.activity}
            component={FlexpitStandardPageContainer}
            currentUserState={currentUser}
            loginText="You need to sign in to view the FLEXPIT standard."
            onLoginSuccess={this.handleLoginSuccess}
            onLoginError={this.handleLoginError}
          />
          <ProtectedRoute
            path={routes.account.root}
            component={CurrentUserAccountPage}
            currentUserState={currentUser}
            loginText="You need to sign in to update your account information."
            onLoginSuccess={this.handleLoginSuccess}
            onLoginError={this.handleLoginError}
          />
          <Route path={routes.post} component={SinglePostPage} />
          <Route path={routes.hashtagFeed} component={HashtagFeedPage} />
          <Route path={routes.user.profile} component={ProfilePage} />
          <Route component={Page404} />
        </Switch>
      </HeaderbarLayout>
    );

    return (
      <MediaQuery minDeviceWidth={768}>
        {isScreenBigEnough =>
          isScreenBigEnough ? (
            <LoggedInUserContext.Provider value={currentUserState}>
              <UserCache expirationTime={5 * 60}>
                <BrowserRouter>
                  {isLoggedIn ? (
                    <SidebarLayout>{mainContent}</SidebarLayout>
                  ) : (
                    mainContent
                  )}
                </BrowserRouter>
              </UserCache>
            </LoggedInUserContext.Provider>
          ) : (
            <StoreLinksPage />
          )
        }
      </MediaQuery>
    );
  }
}

export default App;
