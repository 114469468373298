import React, { Component } from 'react';
import { shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import { routes } from '../../modules/routes';
import Page404 from '../Page404';
import ContentContainer from '../../components/ContentContainer';
import ActivityLeaderboard from '../../components/activities/ActivityLeaderboard';

class ActivityLeaderboardPage extends Component {
  static propTypes = {
    activityUid: string.isRequired,
    activity: shape({
      uid: string.isRequired,
      name: string.isRequired,
    }),
  };

  static defaultProps = {
    activity: undefined,
  };

  render() {
    const { activityUid, activity, isLoading } = this.props;

    if (!isLoading && !activity) {
      return <Page404 />;
    }

    return (
      <ContentContainer size="big">
        <Link className="navigation-link" to={routes.leaderboard.root}>
          Back to Activities
        </Link>

        <ActivityLeaderboard
          activity={activity || { uid: activityUid }}
          loadingActivity={isLoading}
        />
      </ContentContainer>
    );
  }
}

export default ActivityLeaderboardPage;
