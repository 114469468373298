import React, { Component } from 'react';
import { shape, arrayOf, object } from 'prop-types';
import { createSelector } from 'reselect';
import update from 'immutability-helper';

import withLazyLoadedReactiveData from '../components/higher-order/withLazyLoadedReactiveData';
import { subscribeToUsersNotifications } from '../services/feed';
import HeaderbarLayout from '../layouts/HeaderbarLayout';

const HeaderbarLayoutContainer = withLazyLoadedReactiveData({
  subscribeToData: subscribeToUsersNotifications,
  nbByLoad: 10,
  dataPropName: 'notifications',
  wrapProps: true,
})(
  class HeaderbarLayoutComponent extends Component {
    static propTypes = {
      notifications: shape({
        data: arrayOf(object).isRequired,
      }),
    };

    state = {
      lastSeenNotification: undefined,
    };

    selectUnseenNotifications = createSelector(
      (_, props) => props.notifications.data,
      state => state.lastSeenNotification,
      (notifs, lastSeen) => {
        const indexOfLastSeen = notifs.findIndex(
          notif => notif.uid === lastSeen,
        );
        const unseen =
          indexOfLastSeen !== -1 ? notifs.slice(0, indexOfLastSeen) : [];

        const data = notifs.slice(indexOfLastSeen);

        const updatedData = unseen
          .map(notif => ({
            ...notif,
            unseen: true,
          }))
          .concat(data);

        return updatedData;
      },
    );

    componentWillReceiveProps(nextProps) {
      const { notifications } = this.props;

      // Notifications were loaded for the first time
      if (
        notifications.data.length === 0 &&
        notifications.isLoading &&
        !nextProps.notifications.isLoading
      ) {
        this.setState(
          update(this.state, this.resetLastSeenNotification(nextProps)),
        );
      }
    }

    resetLastSeenNotification(props = this.props) {
      // Memorise the first seen notification
      const {
        notifications: { data },
      } = props;
      const { uid } = data[0] || {};
      return { lastSeenNotification: { $set: uid } };
    }

    handleResetLastSeenNotifications = (open, ...args) => {
      if (!open) {
        this.setState(update(this.state, this.resetLastSeenNotification()));
      }

      const { onToggleNotificationPopup } = this.props;
      if (onToggleNotificationPopup) {
        onToggleNotificationPopup(open, ...args);
      }
    };

    render() {
      const { notifications, ...props } = this.props;

      const data = this.selectUnseenNotifications(this.state, this.props);
      const betterNotifications = { ...notifications, data };

      return (
        <HeaderbarLayout
          notifications={betterNotifications}
          onToggleNotificationPopup={this.handleResetLastSeenNotifications}
          {...props}
        />
      );
    }
  },
);

const orderNotifications = {
  field: 'dateCreated',
  order: 'desc',
};

HeaderbarLayoutContainer.defaultProps = {
  orderBy: orderNotifications,
};

export default HeaderbarLayoutContainer;
