import React, { Component, Fragment } from 'react';
import { string, number, shape, bool } from 'prop-types';
import {
  Button,
  Menu,
  Rating,
  Grid,
  Header,
  Image,
  Loader,
} from 'semantic-ui-react';
import classNames from 'classnames';

import { kifyNumber } from '../../../modules/humanise';
import roles from '../../../modules/roles';
import SpotButton from '../../../containers/relationships/SpotButton';
import UserSportDetails from './UserSportDetails';
import SpottingUserListModal from '../SpottingUserListModal';
import SpotterListModal from '../SpotterListModal';
import TextWithContentLinks from '../../TextWithContentLinks';
import { Link } from 'react-router-dom';
import { routes } from '../../../modules/routes';
import SvgImage from '../../SvgImage';
import { FLEXPIT_ICONS } from '../../../assets/icons/flexpit';

class UserHeader extends Component {
  static propTypes = {
    as: string,
    data: shape({
      photoUrl: string,
      uid: string,
      username: string,
      liftsNo: number,
      postsNo: number,
      spottedByNo: number,
      spottingNo: number,
      sport: string,
      verified: bool,
      bio: string,
      locationName: string,
      sportTeam: string,
      rating: number,
    }),
    isLoading: bool,
    isSelf: bool,
    isPublic: bool,
  };

  static defaultProps = {
    as: 'header',
    data: {},
    isLoading: false,
    isSelf: false,
    isPublic: false,
  };

  state = {
    modalOpen: '',
  };

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;

    if (data.uid !== nextProps.uid) {
      this.setState({ modalOpen: '' });
    }
  }

  handleContentMenuClick = (_, { name }) => {
    this.setState({
      modalOpen: name,
    });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: '' });
  };

  renderTrainerRating() {
    const {
      data: { rating },
    } = this.props;

    return (
      <Fragment>
        <strong className="strong">{rating}</strong>{' '}
        <Rating maxRating={5} rating={rating} disabled />
      </Fragment>
    );
  }

  renderUsername() {
    const {
      data: { username, verified },
    } = this.props;

    return (
      <Header as="h2" textAlign="left" className="username">
        {username}{' '}
        {verified && (
          <SvgImage
            icon
            aria-label="Verified profile"
            src={FLEXPIT_ICONS.verifiedProfile.svg}
            fallback={FLEXPIT_ICONS.verifiedProfile.png}
            className="verifiedicon"
          />
        )}
      </Header>
    );
  }

  renderStatsMenu() {
    const {
      data: { liftsNo, postsNo, spottedByNo, spottingNo, privateProfile },
      isPublic,
    } = this.props;

    return (
      <Menu text widths="4" stackable>
        <Menu.Item as="div" className="-vertical">
          <Header size="medium">
            {liftsNo >= 10000 ? kifyNumber(liftsNo) : liftsNo}
          </Header>
          Lifts
        </Menu.Item>

        <Menu.Item as="div" className="-vertical">
          <Header size="medium">
            {postsNo >= 10000 ? kifyNumber(postsNo) : postsNo}
          </Header>
          Posts
        </Menu.Item>

        <Menu.Item
          as={isPublic ? 'div' : undefined}
          className="-vertical"
          name="spotters"
          onClick={
            isPublic || privateProfile ? undefined : this.handleContentMenuClick
          }
        >
          <Header size="medium">
            {spottedByNo >= 10000 ? kifyNumber(spottedByNo) : spottedByNo}
          </Header>
          Spotters
        </Menu.Item>

        <Menu.Item
          as={isPublic ? 'div' : undefined}
          className="-vertical"
          name="spotting"
          onClick={
            isPublic || privateProfile ? undefined : this.handleContentMenuClick
          }
        >
          <Header size="medium">
            {spottingNo >= 10000 ? kifyNumber(spottingNo) : spottingNo}
          </Header>
          Spotting
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const {
      as: As,
      data,
      isLoading,
      isSelf,
      isPublic,
      className,
      ...otherProps
    } = this.props;

    const { modalOpen } = this.state;

    const { photoUrl, bio, rating, uid, userType, privateProfile } = data;

    const isTrainer = userType === roles.trainer;

    return (
      <Fragment>
        <Grid
          as={As}
          doubling
          relaxed="very"
          className={classNames('user-header', className)}
          {...otherProps}
        >
          <Grid.Column width="6">
            <Loader size="large" active={isLoading} />
            <Image src={photoUrl} circular />
          </Grid.Column>

          {!isLoading && (
            <Grid.Column className="details" width="10">
              <div className="golden-a">
                {this.renderUsername()}

                {!isPublic &&
                  (isSelf ? (
                    <Button
                      as={Link}
                      to={routes.account.root}
                      fluid
                      className="contrasted"
                    >
                      Edit profile
                    </Button>
                  ) : (
                    <SpotButton
                      userUid={uid}
                      privateProfile={privateProfile}
                      fluid
                      activeClassName="contrasted"
                    />
                  ))}

                {this.renderStatsMenu()}

                {isTrainer &&
                  rating !== undefined &&
                  this.renderTrainerRating()}

                <UserSportDetails data={data} />
              </div>

              {bio && (
                <TextWithContentLinks as="p" className="bio">
                  {bio}
                </TextWithContentLinks>
              )}
            </Grid.Column>
          )}
        </Grid>

        {uid && !privateProfile && !isPublic && (
          <SpottingUserListModal
            userUid={uid}
            open={modalOpen === 'spotting'}
            onClose={this.handleCloseModal}
            withSpottingButton
            size="tiny"
          />
        )}

        {uid && !privateProfile && !isPublic && (
          <SpotterListModal
            userUid={uid}
            open={modalOpen === 'spotters'}
            onClose={this.handleCloseModal}
            withSpottingButton
            size="tiny"
          />
        )}
      </Fragment>
    );
  }
}

export default UserHeader;
