import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';

import ContentContainer from '../components/ContentContainer';

class Page404 extends Component {
  static defaultProps = {
    message:
      "Maybe the link you've followed is broken, or the page may have been removed, or maybe this page has never even existed!",
  };

  render() {
    const { children, message } = this.props;
    return (
      <ContentContainer className="page-404" size="medium">
        <Header as="h2">Sorry, we could not find this page.</Header>
        {children || (
          <Fragment>
            <p className="paragraph">{message}</p>
            <Button as={Link} to="/" primary>
              Go back home
            </Button>
          </Fragment>
        )}
      </ContentContainer>
    );
  }
}

export default Page404;
