import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';
import { oneOfType, string, number, element, func } from 'prop-types';

class CroppedImage extends Component {
  static propTypes = {
    src: string.isRequired,
    ratio: number,
    as: oneOfType([string, element, func]),
  };

  static defaultProps = {
    as: 'div',
    ratio: 1,
  };

  render() {
    const { src, alt, style, className, ratio, ...props } = this.props;
    const styleWithBg = {
      ...style,
      backgroundImage: `url('${src}')`,
    };

    const crophelperStyle = {
      paddingBottom: `${ratio * 100}%`,
    };

    return (
      <Image
        className={classNames('cropped-image', className)}
        style={styleWithBg}
        {...props}
      >
        <div className="crophelper" style={crophelperStyle} />
        <img src={src} alt={alt} className="image" />
      </Image>
    );
  }
}

export default CroppedImage;
