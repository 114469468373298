import React, { Component } from 'react';
import update from 'immutability-helper';

import { withSubmitApiCall } from '../../components/higher-order/withSubmitApiCall';
import { commentPost } from '../../services/posts';
import CommentForm from '../../components/post/CommentForm';
import { replaceUsernameByMentions } from '../../modules/user-mentions';
import { withUserCache } from '../../components/higher-order/withCachedUser';
import withLoggedInUser from '../../components/higher-order/withLoggedInUser';
import { getLoggedInUid } from '../../services/authentication';

const CommentFormContainer = withSubmitApiCall(commentPost)(CommentForm);

class AddCommentForm extends Component {
  state = {
    userMentions: new Map(),
  };

  handleCacheUserMention = user => {
    this.setState(
      update(this.state, {
        userMentions: {
          $add: [[user.username, user]],
        },
      }),
    );
  };

  handleParseData = data => {
    const {
      getCachedUser,
      currentUserState: { profile },
    } = this.props;
    const { userMentions } = this.state;

    const { text } = data;
    const textWithMentions = replaceUsernameByMentions(text, username => {
      if (profile && username === profile.username) {
        return { uid: getLoggedInUid() };
      }

      return userMentions.get(username) || getCachedUser({ username }, false);
    });

    return {
      ...data,
      text: textWithMentions,
    };
  };

  render() {
    const { getCachedUser, currentUserState, ...props } = this.props;
    return (
      <CommentFormContainer
        parseData={this.handleParseData}
        cacheUserMention={this.handleCacheUserMention}
        {...props}
      />
    );
  }
}

export default withUserCache(withLoggedInUser(AddCommentForm));
