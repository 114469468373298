import React, { Component, Fragment } from 'react';
import { Form, Image, Input } from 'semantic-ui-react';
import { string, shape, func } from 'prop-types';
import update from 'immutability-helper';
import { createSelector } from 'reselect';

import ImageCropperModal from '../../ImageCropperModal';
import { uploadFile, storagePaths } from '../../../services/files';
import { injectParamsIntoRoute } from '../../../modules/routes';

const dataPropType = shape({
  firstName: string,
  lastName: string,
  email: string,
  bio: string,
});

class ProfileForm extends Component {
  static propTypes = {
    formData: dataPropType.isRequired,
    onChange: func,
    onSubmit: func,
    data: dataPropType,
    id: string,
  };

  static defaultProps = {
    id: 'profile-form',
    formData: {},
    data: {},
  };

  state = {
    form: {
      photoUrl: undefined,
    },
    imageCropperModal: {
      open: false,
      originalImage: '',
      croppedImageBlob: '',
      croppedImageUrl: '',
    },
    isLoading: false,
  };

  selectFormData = createSelector(
    state => state.form,
    (_, props) => props.formData,
    (form, formData) => {
      return {
        ...formData,
        ...form,
      };
    },
  );

  async requestSubmit(evt) {
    evt.preventDefault();
    const {
      onChange,
      onSubmit,
      data: { uid },
      onProgressChange,
    } = this.props;
    const {
      imageCropperModal: { croppedImageBlob },
    } = this.state;

    // If no image update, just use the HoC's submit
    if (!croppedImageBlob) {
      return onSubmit();
    }

    this.setState({ isLoading: true });

    // Put the picture in the user's folder
    const path = injectParamsIntoRoute(storagePaths.usersProfilePicture, {
      userUid: uid,
    });

    const file = {
      path,
      data: croppedImageBlob,
    };

    let url = '';
    let error = null;

    // Upload profile picture
    try {
      url = await uploadFile(file, onProgressChange);
    } catch (internalError) {
      error = internalError;
      console.error(error);
    }

    // Update profile's reference to the picture, and send submit request
    const artificialEvent = { name: 'photoUrl', value: url };
    onChange({ target: artificialEvent }, artificialEvent, () => {
      onProgressChange(undefined);
      this.setState({ isLoading: false });
      onSubmit();
    });
  }

  handleFileInputChange = ({ target: { name, files } }) => {
    const file = files[0];

    // If not an image gtfo
    if (!file.type.startsWith('image/')) return;

    // Read file as url
    const fileReader = new FileReader();

    // When filereader has finished reading the file, update the state with the result
    fileReader.addEventListener('load', ({ target: { result } }) => {
      this.setState(
        update(this.state, {
          imageCropperModal: {
            originalImage: { $set: result },
            open: { $set: true },
          },
        }),
      );
    });

    fileReader.readAsDataURL(file);
  };

  handleCropImage = croppedImageBlob => {
    const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
    this.setState(
      update(this.state, {
        imageCropperModal: {
          open: { $set: false },
          croppedImageBlob: { $set: croppedImageBlob },
          croppedImageUrl: { $set: croppedImageUrl },
        },
      }),
    );
  };

  handleCloseCropperModal = () =>
    this.setState(
      update(this.state, {
        imageCropperModal: {
          open: { $set: false },
          image: { $set: '' },
        },
      }),
    );

  handleSubmit = (...args) => this.requestSubmit(...args);

  render() {
    const {
      id,
      data,
      onChange,
      formData: _,
      loading,
      onSubmit,
      onProgressChange,
      initialValue,
      ...props
    } = this.props;
    const { imageCropperModal, isLoading } = this.state;

    const formData = this.selectFormData(this.state, this.props);

    const getFieldProps = name => ({
      onChange,
      name,
      id: `${id}-${name}`,
      value: formData[name] !== undefined ? formData[name] : data[name] || '',
    });

    return (
      <Fragment>
        <Form
          id={id}
          loading={isLoading || loading}
          onSubmit={this.handleSubmit}
          {...props}
        >
          <Form.Field className="formrow">
            <label htmlFor={`${id}-photoUrl`} className="leftlabel">
              Profile picture
            </label>
            <Input
              type="file"
              onChange={this.handleFileInputChange}
              name="photoUrl"
              className="-basic"
            >
              <Image
                avatar
                src={imageCropperModal.croppedImageUrl || data.photoUrl || ''}
                alt="Your profile picture"
                style={{ alignSelf: 'center ' }}
              />
              <input />
            </Input>
          </Form.Field>
          <Form.Input
            className="formrow"
            label="First name"
            placeholder="First name"
            {...getFieldProps('firstName')}
            autoComplete="given-name"
            required
          />
          <Form.Input
            className="formrow"
            label="Last name"
            placeholder="Last name"
            {...getFieldProps('lastName')}
            autoComplete="family-name"
            required
          />
          <Form.TextArea
            className="formrow"
            placeholder="Tell us about yourself..."
            label="Bio"
            {...getFieldProps('bio')}
          />
          <Form.Input
            className="formrow"
            label="Email"
            placeholder="john@doe.com"
            type="email"
            {...getFieldProps('email')}
            autoComplete="email"
            required
          />
          <Form.Button primary type="submit" className="formrow">
            Submit
          </Form.Button>
        </Form>
        {imageCropperModal.originalImage && (
          <ImageCropperModal
            size="mini"
            aspect={1}
            imageUrl={imageCropperModal.originalImage}
            open={imageCropperModal.open}
            header="Update profile picture"
            onClose={this.handleCloseCropperModal}
            onCropImage={this.handleCropImage}
          />
        )}
      </Fragment>
    );
  }
}

export default ProfileForm;
