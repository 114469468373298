import React, { Component } from 'react';
import { arrayOf, string } from 'prop-types';

class CriteriaList extends Component {
  static propTypes = {
    criteria: arrayOf(string).isRequired,
  };

  static defaultProps = {
    criteria: [],
  };

  render() {
    const { criteria } = this.props;

    const criteriaNodes = criteria.map((criterion, index) => (
      <li key={index} className="criteria-item">
        {criterion}
      </li>
    ));

    return <ul className="criteria-list">{criteriaNodes}</ul>;
  }
}

export default CriteriaList;
