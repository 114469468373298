import check from 'check-types';

import { db } from '../modules/firebase';
import { checkArguments, basicSnapshotHandler } from '../modules/services';
import { throwInternalError } from '../modules/errors';
import { parseFireStorePost } from './posts';

/**
 * Gets hashtag suggestions for the given string.
 * @param { string } partialHashtag The partial hashtag to get suggestions from.
 * @param { number } [ number = 10 ] The number of suggestions.
 */
export const getHashtagSuggestions = function getHashtagSuggestions(
  partialHashtag,
  number = 10,
) {
  checkArguments(
    { partialHashtag, number },
    { partialHashtag: check.nonEmptyString, number: check.number },
  );

  /**
   * Create a string with the last character changed to the one after in the unicode base
   * e.g. `foo` => `fop`
   * See: https://stackoverflow.com/questions/46573804/firestore-query-documents-startswith-a-string
   */
  const hashtag = partialHashtag.toLowerCase();
  const startHashtagAfter = hashtag.slice(0, -1);
  const lastChar = hashtag[hashtag.length - 1];
  const charAfterLastChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
  const hashtagAfter = `${startHashtagAfter}${charAfterLastChar}`;

  const hashtagsRef = db.collection('hashtags');

  const promise = hashtagsRef
    .where('name', '>=', hashtag)
    .where('name', '<', hashtagAfter)
    .orderBy('name')
    .limit(number)
    .get()
    .then(snapshot => snapshot.docs.map(doc => doc.data()))
    .catch(throwInternalError);

  return promise;
};

/**
 * Subscribes to the feed of posts with the given hashtag.
 * @param { Object } params
 * @param { string } params.hashtag The hashtag to subscribe to.
 * @param { onReceiveDataFunction } params.onReceiveData The handler for document changes.
 * @param { Function } params.onError The error handler.
 * @param { number } [ params.limit ] The number of comments to subscribe to.
 *
 * @returns { function } A function to unsuscribe from the created subscription.
 */
export const subscribeToHashtagPosts = function subscribeToHashtagPost(params) {
  checkArguments(params, {
    hashtag: check.nonEmptyString,
    onReceiveData: check.function,
    onError: check.function,
    limit: check.maybe.number,
  });

  const { hashtag, limit, onReceiveData, onError } = params;

  const postRef = db.collection('posts');
  let postRefWithHashtag = postRef
    .where('hashtags', 'array-contains', hashtag.toLowerCase())
    .where('public', '==', true)
    .orderBy('dateCreated', 'desc');

  if (limit) {
    postRefWithHashtag = postRefWithHashtag.limit(limit);
  }

  const onSnapshot = basicSnapshotHandler(onReceiveData, parseFireStorePost);
  const unsuscribe = postRefWithHashtag.onSnapshot(onSnapshot, onError);

  return unsuscribe;
};
