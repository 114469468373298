import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import classNames from 'classnames';

class MiscellaneousMenu extends Component {
  render() {
    const { className, ...props } = this.props;
    return (
      <Menu className={classNames('misc-menu', className)} {...props}>
        <Menu.Item href="mailto:contact@flexpit.com" link>
          Report a problem
        </Menu.Item>
      </Menu>
    );
  }
}

export default MiscellaneousMenu;
