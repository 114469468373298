import React, { Component, Fragment } from 'react';
import {
  string,
  number,
  shape,
  oneOf,
  bool,
  oneOfType,
  element,
  func,
} from 'prop-types';
import classNames from 'classnames';
import { Icon, Header } from 'semantic-ui-react';

import { pluralise } from '../../modules/humanise';
import { ACTIVITY_TYPES } from '../../modules/constants/activities';
import Weight from '../Weight';

class PostCard extends Component {
  static propTypes = {
    post: shape({
      commentsNo: number.isRequired,
      fistBumpsNo: number.isRequired,
      isLift: bool.isRequired,
      mediaType: oneOf(['I', 'V']).isRequired,
      mediaUrl: string.isRequired,
      activity: string,
      thumbnail: shape({
        medium: shape({
          url: string.isRequired,
        }).isRequired,
      }),
      repsNo: number,
      weight: number,
    }).isRequired,
    as: oneOfType([string, element, func]),
    className: string,
  };

  static defaultProps = {
    as: 'figure',
  };

  render() {
    const {
      className,
      post: {
        activity,
        activityType,
        commentsNo,
        fistBumpsNo,
        thumbnail,
        mediaType,
        mediaUrl,
        isLift,
        repsNo,
        weight,
      },
      as: As,
      ...otherProps
    } = this.props;
    const urls = [
      thumbnail && `url(${thumbnail.medium.url})`,
      `url(${mediaUrl})`,
    ].filter(isTruey => isTruey);

    const style = {
      backgroundImage: urls.join(', '),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    };

    const uiWeight = activityType !== ACTIVITY_TYPES.time &&
      weight !== undefined && (
        <Fragment>
          @ <Weight>{weight}</Weight>
        </Fragment>
      );

    return (
      <As
        className={classNames('post-card', className)}
        style={style}
        {...otherProps}
      >
        <div className="overlay">
          <div className="top">
            {isLift && (
              <Header as="h3" className="name" inverted>
                {activity}:
              </Header>
            )}
            {isLift && (
              <span className="repetitions">
                {repsNo} reps {uiWeight}
              </span>
            )}
          </div>

          <div className="bottom">
            <strong className="strong fistbumps">
              {fistBumpsNo}&nbsp;{pluralise('fistbump', fistBumpsNo)}
            </strong>{' '}
            <strong className="strong comments">
              {commentsNo}&nbsp;{pluralise('comment', commentsNo)}
            </strong>
          </div>
        </div>
        {mediaType === 'V' && (
          <Icon className="-elevated" inverted name="play" size="huge" />
        )}
      </As>
    );
  }
}

export default PostCard;
