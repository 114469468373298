import React, { Component } from 'react';
import {
  oneOfType,
  string,
  element,
  func,
  arrayOf,
  bool,
  shape,
  node,
} from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import classNames from 'classnames';

import ActivityRankingItem from './ActivityRankingItem';
import NoDataMessage from '../NoDataMessage';
import { getLoggedInUid } from '../../services/authentication';

const intervalRefreshing = 5 * 60 * 1000; // 5 minutes
// const intervalRefreshing = 10 * 1000; // 5 minutes

class ActivityRankingList extends Component {
  static propTypes = {
    rankings: arrayOf(
      shape({
        uid: string.isRequired,
      }),
    ).isRequired,
    header: node,
    as: oneOfType([string, element, func]),
    itemAs: oneOfType([string, element, func]),
    isLoading: bool,
    makeItemProps: func,
  };

  static defaultProps = {
    headerAs: 'div',
    headerProps: {},
    as: 'ul',
    isLoading: false,
    makeItemProps: () => ({}),
  };

  selectExistingRankings = createSelector(
    (_, props) => props.rankings,
    rankings => {
      return rankings.filter(ranking => ranking.position !== null);
    },
  );

  selectProppedRankings = createSelector(
    this.selectExistingRankings,
    (_, props) => props.makeItemProps,
    (rankings, makeItemProps) => {
      return rankings.map(ranking => ({ ranking, ...makeItemProps(ranking) }));
    },
  );

  refreshInterval = undefined;

  componentWillMount() {
    if (this.props.requestRefreshData) {
      this.refreshInterval = setInterval(() => {
        const { requestRefreshData } = this.props;
        if (getLoggedInUid()) {
          requestRefreshData();
        }
      }, intervalRefreshing);
    }
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  render() {
    const {
      as: As,
      itemAs,
      headerAs: HeaderAs,
      headerProps,
      className,
      header,
      rankings: _,
      makeItemProps,
      requestRefreshData,
      isLoading,
      ...props
    } = this.props;

    const rankings = this.selectProppedRankings(this.state, this.props);

    const itemNodes = rankings.map(({ ranking, ...itemProps }) => (
      <ActivityRankingItem
        key={ranking.uid}
        as={itemAs}
        {...itemProps}
        ranking={ranking}
      />
    ));

    return (
      <As className={classNames('activity-ranking-list', className)} {...props}>
        {header && (
          <HeaderAs {...headerProps}>
            {header} <Loader inline active={isLoading} size="tiny" />
          </HeaderAs>
        )}
        {!isLoading && itemNodes.length === 0 && (
          <NoDataMessage>
            You are currently not participating in any leaderboard. Take part by
            posting a lift with the mobile application!
          </NoDataMessage>
        )}
        {itemNodes}
      </As>
    );
  }
}

export default ActivityRankingList;
