import { subscribeToUsersFeed } from '../../services/feed';
import withLazyLoadedReactiveData from '../../components/higher-order/withLazyLoadedReactiveData';
import Feed from '../../components/feed/Feed';

const UserFeed = withLazyLoadedReactiveData({
  subscribeToData: subscribeToUsersFeed,
  dataPropName: 'posts',
  nbByLoad: 5,
})(Feed);

export default UserFeed;
