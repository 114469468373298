import React, { Component } from 'react';
import { oneOfType, string, element, func } from 'prop-types';
import classNames from 'classnames';

import PostEvent from './PostEvent';

class SegmentEvent extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
  };

  static defaultProps = {
    as: PostEvent,
  };

  render() {
    const {
      as: As,
      className,
      segment,
      children,
      currentUserState,
      ...props
    } = this.props;

    const {
      event: { unseen },
    } = props;

    const verifiedBlock = (
      <div className="information-block -small">{segment}</div>
    );

    return (
      <As
        className={classNames(
          'event-notification',
          '-segment',
          unseen && '-unseen',
          className,
        )}
        text={verifiedBlock}
        {...props}
      >
        {children}
      </As>
    );
  }
}

export default SegmentEvent;
