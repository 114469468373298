import axios from 'axios';
import update from 'immutability-helper';

import { firebaseAuth } from './firebase';
import { customApi } from '../config';

export const API_ROOT_URL = `${customApi.apiUrl}${customApi.apiVersion}/`;

export const initApiClient = function initApiClient() {
  axios.defaults.baseURL = API_ROOT_URL;
};

/**
 * Adds the authorization token to a config object.
 * Use this function before each API call to be authorised.
 * @param { object } [ config = {} ] A base config object to modify. If none is
 * specified, the function will return a config object containing the authorization token.
 * @returns { Promise<object> } A new config object, based on the provided parameter, containing
 * the authorization token.
 */
export const getTokenedConfig = async function getTokenedConfig(config = {}) {
  let token;

  if (firebaseAuth().currentUser) {
    token = await firebaseAuth().currentUser.getIdToken();
  }

  const bearerString = token && `Bearer ${token}`;
  const tokenedConfig = bearerString
    ? update(config, {
        headers: headers =>
          update(headers || {}, {
            Authorization: { $set: bearerString },
          }),
      })
    : config;
  return tokenedConfig;
};

export const getApiEndpoint = {
  public: {
    users: {
      search() {
        return `users/search`;
      },
      get(uid) {
        return `public/users/${uid}`;
      },

      getByUsername(username) {
        return `public/users/@${username}`;
      },
    },

    posts: {
      get(uid) {
        return `public/posts/${uid}`;
      },

      getUsers(userUid) {
        return `public/users/${userUid}/posts`;
      },

      getComments(uid) {
        return `public/posts/${uid}/comments`;
      },
    },
  },

  users: {
    getCurrent() {
      return `users/self`;
    },

    get(uid) {
      return `users/${uid}`;
    },

    getByUsername(username) {
      return `users/@${username}`;
    },

    search() {
      return `users/search`;
    },

    updateCurrent() {
      return `users/self/edit`;
    },

    requestVerification() {
      return `users/self/request-verification`;
    },

    checkRegistered() {
      return `users/new/check-uid`;
    },
  },

  posts: {
    report(uid) {
      return `posts/${uid}/report`;
    },

    fistBumpComment(uid, commentUid) {
      return `posts/${uid}/comments/${commentUid}/fistbump`;
    },
  },

  relationships: {
    getCurrent(uid) {
      return `users/${uid}/relationship`;
    },

    updateCurrent(uid) {
      return `users/${uid}/relationship`;
    },

    getSpotting(uid) {
      return `users/${uid}/spotting`;
    },

    getSpottedBy(uid) {
      return `users/${uid}/spotted-by`;
    },
  },

  leaderboards: {
    getForActivity(activityUid) {
      return `leaderboard/${activityUid}`;
    },

    getUserRankingForActivity(activityUid) {
      return `leaderboard/${activityUid}/close-standings`;
    },
  },
};

/**
 * @typedef CancellableRequest
 * @property { Promise<object []> } data The data resulting from the call.
 * @property { CancelTokenSource } cancelToken The cancel token. Calling its function
 * @method cancel Cancels the request.
 */

/**
 * Class representing a CancellableRequest.
 */
export class CancellableRequest {
  constructor(promise, cancelTokenSource) {
    this.promise = promise;
    this.cancelTokenSource = this.cancelTokenSource;
  }

  cancel() {
    return this.cancelTokenSource && this.cancelTokenSource.cancel();
  }
}

/**
 * @typedef DataWithBatchCheck Type for request result that carries data and
 * checks if there is more data to fetch.
 * @property { object[] } data The data resulting from the call.
 * @property { boolean } isLastBatch true if there is more data to fetch, false else.
 */
