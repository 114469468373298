import React, { Component } from 'react';
import withLoggedInUser, {
  LoggedInUserContext,
} from '../../components/higher-order/withLoggedInUser';
import UserProfile from '../../components/user/UserProfile';

const minTimeBeforeRefresh = 5 * 1000;

class CurrentUserProfile extends Component {
  static propTypes = {
    currentUserState: LoggedInUserContext.propType,
  };

  componentDidMount() {
    const {
      currentUserState: { isFetching, refreshCurrentUser, lastUpdatedAt },
    } = this.props;

    if (!isFetching && Date.now() - lastUpdatedAt > minTimeBeforeRefresh) {
      refreshCurrentUser();
    }
  }

  render() {
    const {
      currentUserState: { profile, isFetching },
      ...otherProps
    } = this.props;
    return (
      <UserProfile
        loading={isFetching && !profile.username}
        data={profile}
        {...otherProps}
      />
    );
  }
}

const CurrentUserProfileContainer = withLoggedInUser(CurrentUserProfile);

export default CurrentUserProfileContainer;
