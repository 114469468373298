import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

import ContentContainer from '../../components/ContentContainer';
import VerifyProfileFormContainer from '../../containers/users/forms/VerifyProfileFormContainer';

class RequestVerifiedAccountPage extends Component {
  handleSuccess = () => {
    const { onUpdateSuccess } = this.props;
    this.setState({ sentRequest: true });
    return onUpdateSuccess();
  };

  render() {
    const {
      user,
      user: { verificationDocuments, verified },
      onProgressChange,
    } = this.props;

    const pendingVerification =
      verificationDocuments && verificationDocuments.length > 0;
    return (
      <ContentContainer
        size="tiny"
        align={verified || pendingVerification ? 'centre' : 'left'}
      >
        {verified || pendingVerification ? (
          <Message success>
            {verified
              ? 'Your profile is verified!'
              : "Your verification request has been sent! We'll let you know once it has been reviewed."}
          </Message>
        ) : (
          <VerifyProfileFormContainer
            className="-stacked"
            data={user}
            extraParams={{ type: 'athlete' }}
            onSuccess={this.handleSuccess}
            onProgressChange={onProgressChange}
          />
        )}
      </ContentContainer>
    );
  }
}

export default RequestVerifiedAccountPage;
