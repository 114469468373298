import React, { Component } from 'react';
import { func, string, instanceOf, oneOfType } from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  formatDate as formatDateDefault,
  parseDate as parseDateDefault,
} from 'react-day-picker/moment';
import update from 'immutability-helper';
import makeClassName from 'classnames';
import 'react-day-picker/lib/style.css';

class DateInput extends Component {
  static propTypes = {
    onChange: func.isRequired,
    value: oneOfType([instanceOf(Date), string]),
    formatDate: func,
    parseDate: func,
    placeholder: string,
    format: string,
  };

  static defaultProps = {
    formatDate: formatDateDefault,
    parseDate: parseDateDefault,
    placeholder: 'DD/MM/YYYY',
    format: 'DD/MM/YYYY',
    classNames: {
      container: 'DayPickerInput',
      overlayWrapper: 'DayPickerInput-OverlayWrapper',
      overlay: 'DayPickerInput-Overlay',
    },
  };

  handleDayChange = (value = '') => {
    const { name, onChange } = this.props;
    const target = { name, value };
    onChange({ target }, target);
  };

  render() {
    const {
      // DayPickerInput props, see http://react-day-picker.js.org/api/DayPickerInput
      classNames,
      clickUnselectsDay,
      component,
      dayPickerProps,
      format,
      formatDate,
      keepFocus,
      hideOnDayClick,
      inputProps,
      onDayChange,
      overlayComponent,
      parseDate,
      placeholder,
      showOverlay,
      value,
      onChange,
      ...props
    } = this.props;

    const dayPickerInputProps = {
      onDayChange: this.handleDayChange,
      classNames: update(classNames, {
        container: container => makeClassName(container, 'input'),
      }),
      clickUnselectsDay,
      component,
      dayPickerProps,
      format,
      formatDate,
      keepFocus,
      hideOnDayClick,
      inputProps,
      overlayComponent,
      parseDate,
      placeholder,
      showOverlay,
      value,
    };

    const dayPickerPraps = {
      selectedDays: value,
      ...dayPickerProps,
    };

    return (
      <DayPickerInput
        {...dayPickerInputProps}
        dayPickerProps={dayPickerPraps}
        inputProps={props}
      />
    );
  }
}

export default DateInput;
