import axios, { CancelToken } from 'axios';
import check from 'check-types';
import moment from 'moment';

import {
  getApiEndpoint,
  CancellableRequest,
  getTokenedConfig,
} from '../modules/api';
import { throwInternalError } from '../modules/errors';
import { checkArguments } from '../modules/services';
import { getLoggedInUid } from './authentication';
import { db } from '../modules/firebase';

/**
 * Converts a user represented as JSON data into JavaScript objects.
 * Use this function for public endpoints as they cannot provide JavaScript objects
 * (for example Dates).
 * @param { object } data The data to convert.
 * @returns { object } A new object with converted data.
 */
const convertUser = function convertUser({
  dateCreated,
  dateUpdated,
  dateOfBirth,
  ...otherData
}) {
  return {
    dateCreated: dateCreated && new Date(dateCreated),
    dateUpdated: dateUpdated && new Date(dateUpdated),
    dateOfBirth: dateOfBirth && new Date(dateOfBirth),
    ...otherData,
  };
};

/**
 * Searches for users based on provided critieria.
 * @param { object } criteria The criteria to search for.
 * @param { string } criteria.username The username to search for (can be partial).
 * @param { bool } [ criteria.coach ] If true, limits the search to trainers.
 * @param { bool } [ criteria.approved ] If true, limits the search to certified trainers
 * (criteria.coach must be true).
 * @param { number } [ limit = 5 ] Limits the number of results.
 * @returns { CancellableRequest } A cancellable request.
 *
 */
export const searchUsers = function searchUsers(criteria) {
  checkArguments(criteria, {
    username: check.nonEmptyString,
    coach: check.maybe.boolean,
    approved: check.maybe.boolean,
    limit: check.maybe.number,
  });

  const { username, coach, approved, limit = 5 } = criteria;

  const url = getApiEndpoint.public.users.search();
  const params = { q: username, coach, approved, limit };

  const cancelTokenSource = CancelToken.source();

  const promise = getTokenedConfig({
    params,
    cancelToken: cancelTokenSource.token,
  })
    .then(config => axios.get(url, config))
    .then(({ data: { data = [] } }) => data)
    .catch(err => {
      // Don't bubble error if the request was just cancelled
      if (!axios.isCancel(err)) {
        throwInternalError(err);
      }
    });

  return new CancellableRequest(promise, cancelTokenSource);
};

export const getPublicUser = function getPublicUser(user) {
  if (
    !check.any(
      check.map(user, {
        username: check.nonEmptyString,
        uid: check.nonEmptyString,
      }),
    )
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = { user };
    console.error(error);
    throw error;
  }

  const { uid, username } = user;

  // Get endpoint depending on provided data
  let endpoint;

  if (uid) {
    endpoint = getApiEndpoint.public.users.get(uid);
  } else if (username) {
    endpoint = getApiEndpoint.public.users.getByUsername(username);
  } else {
    const error = new Error('No endpoint given to get user');
    console.error(error);
    throw error;
  }

  return axios
    .get(endpoint)
    .then(({ data }) => convertUser(data))
    .catch(throwInternalError);
};

/**
 * Gets the currently logged-in user's profile data.
 * @returns { Promise } A Promise that resolves with the user's data.
 * @throws { InternalError }
 */
export const getCurrentUser = async function getCurrentUser() {
  const url = getApiEndpoint.users.getCurrent();
  let data;

  try {
    const config = await getTokenedConfig();
    data = await axios.get(url, config).then(({ data }) => convertUser(data));
  } catch (error) {
    throwInternalError(error);
  }

  return data;
};

/**
 * Gets a user using the provided data (by uid or username). Provide only one parameter
 * in the user object (either a uid, or a username).
 * @param { object } user The user data to fetch.
 * @param { string } [user.uid] The uid of the user to fetch.
 * @param { string } [user.username] The username of the user to fetch.
 * @param { string } [ filter ] Set to 'basic' to get only the username and photo
 * of the user.
 * @returns { Promise } A Promise that resolves with the user's profile.
 */
export const getUser = async function getUser(user, filter = '') {
  if (
    !check.any(
      check.map(user, {
        username: check.nonEmptyString,
        uid: check.nonEmptyString,
      }),
    ) ||
    !check.string(filter) ||
    !['', 'basic'].includes(filter)
  ) {
    const error = new Error('Internal error: malformed arguments');
    error.params = { user, filter };
    console.error(error);
    throw error;
  }

  const { username, uid } = user;

  // Get endpoint depending on provided data
  let endpoint;

  if (uid) {
    endpoint = getApiEndpoint.users.get(uid);
  } else if (username) {
    endpoint = getApiEndpoint.users.getByUsername(username);
  } else {
    const error = new Error('No endpoint given to get user');
    console.error(error);
    throw error;
  }

  // Add filter if available
  let params;
  if (filter) {
    params = { filter };
  }

  let data;

  try {
    const config = await getTokenedConfig({ params });
    data = await axios
      .get(endpoint, config)
      .then(({ data }) => convertUser(data));
  } catch (error) {
    throwInternalError(error);
  }

  return data;
};

/**
 * Updates the logged-in user's profile.
 * @param { object } update An object containing the fields and values to update
 * on the profile.
 * @returns { object } The updated user.
 */
export const updateCurrentUserProfile = async function updateCurrentUserProfile(
  update,
) {
  checkArguments(update, {
    username: check.maybe.string,
    firstName: check.maybe.string,
    lastName: check.maybe.string,
    email: check.maybe.string,
    sex: check.maybe.string,
    dateOfBirth: check.maybe.date,
    bio: check.maybe.string,
    weight: check.maybe.number,
    height: check.maybe.number,
    photoRef: check.maybe.string,
    sport: check.maybe.string,
    sportLevel: check.maybe.string,
    location: check.maybe.object,
    locationName: check.maybe.string,
    sponsor: check.maybe.object,
    socialLinks: check.maybe.object,
    privateProfile: check.maybe.boolean,
    team: check.maybe.string,
    trainerLevel: check.maybe.string,
    userType: check.maybe.string,
  });

  const url = getApiEndpoint.users.updateCurrent();
  const { dateOfBirth, ...restUpdate } = update;
  const data = {
    dateOfBirth: dateOfBirth && moment(dateOfBirth).toISOString(),
    ...restUpdate,
  };

  let updatedProfile;

  try {
    const config = await getTokenedConfig();
    updatedProfile = await axios
      .post(url, data, config)
      .then(({ data: { data } }) => convertUser(data));
  } catch (error) {
    throwInternalError(error);
  }

  return updatedProfile;
};

/**
 * Request a profile verification or a trainer certification.
 * @param { object } verification
 * @param { string } verification.type 'trainer' to request a certification, or
 * 'athlete' to request a profile verification.
 * @param { string[] } verification.files The list of references to documents.
 * @returns { Promise<object> } A Promise resolving with the API response.
 */
export const requestVerification = async function requestVerification(
  verification,
) {
  checkArguments(
    verification,
    {
      type: check.nonEmptyString,
      files: check.array.of.nonEmptyString,
    },
    ['trainer', 'athlete'].includes(verification.type),
  );

  const { type, files } = verification;

  const data = {
    type,
    files,
  };
  const url = getApiEndpoint.users.requestVerification();
  let response;

  try {
    const config = await getTokenedConfig();
    response = await axios.post(url, data, config);
  } catch (error) {
    throwInternalError(error);
  }

  return response;
};

/**
 * Subscribes to a user's data and its changes.
 * @param { object } params
 * @param { function } onChange The function to call with the user's data
 * when changes occur.
 * @param { string } [ uid = logged-in user's uid ] The user to get's uid.
 * @returns { function } The unsuscribe function.
 */
export const subscribeToUser = function subscribeToUser(
  onChange,
  uid = getLoggedInUid(),
) {
  check.nonEmptyString(uid);
  check.function(onChange);

  const userRef = db.collection('users').doc(uid);

  const unsuscribe = userRef.onSnapshot(doc => {
    onChange(doc.data());
  });

  return unsuscribe;
};

/**
 * Checks if the currently logged-in user is registered (meaning that they have
 * gone through onboarding).
 * @returns { Promise<boolean> } A Promise that resolves with true if the user is
 * registered, false else.
 */
export const checkIsUserRegistered = async function checkIsUserRegistered() {
  check.nonEmptyString(getLoggedInUid());

  const url = getApiEndpoint.users.checkRegistered();
  let result;

  try {
    const config = await getTokenedConfig();
    await axios.get(url, config);
    result = false;
  } catch (error) {
    // Conflict detected means that the user is already registered
    if (error.response && error.response.status === 409) {
      result = true;
    } else {
      console.error(error);
      throw error;
    }
  }

  return result;
};
