import React from 'react';

import { withCachedUsers } from '../../components/higher-order/withCachedUser';
import PostItem from '../../components/post/PostItem';
import PostItemContainer from './PostItemContainer';

const CachedPostItem = withCachedUsers()(PostItem);
const CachedPostItemContainer = withCachedUsers()(PostItemContainer);

const PostItemWithAuthor = function PostItemWithAuthor(props) {
  const {
    data: { userUid, verifiedByCoach },
  } = props;

  // Remove undefined values
  const cachedUserUids = [userUid, verifiedByCoach].filter(i => i);
  return <CachedPostItem cachedUserUids={cachedUserUids} {...props} />;
};

export const PostItemWithAuthorContainer = function PostItemWithAuthorContainer(
  props,
) {
  const {
    data: { userUid, verifiedByCoach },
  } = props;
  const cachedUserUids = [userUid, verifiedByCoach].filter(i => i);

  return <CachedPostItemContainer cachedUserUids={cachedUserUids} {...props} />;
};

export default PostItemWithAuthor;
