import React, { Component } from 'react';
import { arrayOf, string, bool, shape } from 'prop-types';
import { List, Image, Loader, Dimmer } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import SpotButton from '../../containers/relationships/SpotButton';
import PartialLoadingList from '../PartialLoadingList';
import SvgImage from '../SvgImage';
import { FLEXPIT_ICONS } from '../../assets/icons/flexpit';
import { getLoggedInUid } from '../../services/authentication';
import { injectParamsIntoRoute, routes } from '../../modules/routes';

class UserList extends Component {
  static propTypes = {
    users: arrayOf(
      shape({
        uid: string.isRequired,
        username: string,
        firstName: string,
        lastName: string,
        photoUrl: string,
        verified: bool,
      }),
    ).isRequired,
    isLoading: bool,
    withSpottingButton: bool,
  };

  static defaultProps = {
    users: [],
    isLoading: false,
    withSpottingButton: false,
  };

  renderItem = ({ uid, username, firstName, lastName, photoUrl, verified }) => {
    const { isLoading, withSpottingButton } = this.props;
    const url = injectParamsIntoRoute(routes.user.profile, { username });

    return (
      <List.Item key={uid} as={Link} to={url}>
        {withSpottingButton && uid && uid !== getLoggedInUid() && (
          <List.Content floated="right">
            <SpotButton userUid={uid} size="small" />
          </List.Content>
        )}

        {photoUrl && <Image avatar src={photoUrl} />}

        <List.Content>
          <Dimmer active={isLoading} inverted>
            <Loader inline="centered" size="large" inverted />
          </Dimmer>

          {username && (
            <List.Header>
              {username}{' '}
              {verified && (
                <SvgImage
                  icon
                  aria-label="Verified profile"
                  src={FLEXPIT_ICONS.verifiedProfile.svg}
                  fallback={FLEXPIT_ICONS.verifiedProfile.png}
                  className="verifiedicon"
                  size="tiny"
                />
              )}
            </List.Header>
          )}

          {(firstName || lastName) && (
            <List.Description>
              {firstName} {lastName}
            </List.Description>
          )}
        </List.Content>
      </List.Item>
    );
  };

  render() {
    const {
      users,
      requestRefreshData,
      withSpottingButton,
      ...otherProps
    } = this.props;

    return (
      <PartialLoadingList
        items={users}
        itemAs={this.renderItem}
        {...otherProps}
      />
    );
  }
}

export default UserList;
