import React, { Component } from 'react';
import {
  string,
  number,
  arrayOf,
  func,
  bool,
  object,
  node,
  oneOfType,
} from 'prop-types';
import debounce from 'lodash/debounce';
import times from 'lodash/times';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';

/**
 * Calculates sequentially which element is the last in a container of a
 * specified height.
 * @param { number | number[] } elementHeight The height of the element, or a list
 * of the height of each element ordered in display order.
 * @param { number } containerHeight The height of the container.
 * @param { number } [ startIndex = 0 ] The index where to start calculating in
 * the element list.
 * @returns { number } The index of the last visible element in the container.
 */
const findLastElementIndex = function findVisibleElementIndex(
  elementHeight,
  containerHeight,
  startIndex = 0,
) {
  let index = startIndex;
  if (!Array.isArray(elementHeight)) {
    index = startIndex + Math.floor(containerHeight / elementHeight);
    return index;
  }

  // Sum of heights of the elements before the visible window
  let accumulatedHeights = 0;

  for (let i = startIndex; i < elementHeight.length; i += 1) {
    // Add the height to the height sum
    const height = elementHeight[i];
    accumulatedHeights += height;
    index = i;

    /**
     * If the sum is higher than the distance scrolled, it means this element
     * is outside of the specified block. Stop there.
     */
    if (accumulatedHeights >= containerHeight) {
      break;
    }
    // Else, go to the next element
  }

  return index;
};

class VirtualisedNodes extends Component {
  static propTypes = {
    as: string,
    /**
     * @type { number | number[] }
     * The height of the elements to display. Can use a list of heights in order
     * of display.
     */
    elementHeight: oneOfType([number, arrayOf(number)]).isRequired,

    /**
     * @type { React.Node[] }
     * The list of elements to display.
     */
    elements: arrayOf(node).isRequired,

    /**
     * @type { [number] }
     * The height of the container. Required if not using `useWindow`.
     */
    height: number,

    /**
     * @type { [number = 1] }
     * Number of elements to load before the visible elements, and after the
     * visible elements. E.g. nbPreLoad = 2 will load 2 before, and 2 after.
     */
    nbPreLoad: number,

    /**
     * @type { [useWindow = false] }
     * Requests to use the window as the main container and element to scroll in.
     */
    useWindow: bool,

    /**
     * @type { [function] }
     * The function to call to request more data, for infinite scrolling container.
     */
    requestMoreData: func,
    isLastBatch: bool,
    isLoading: bool,
    style: object,
    children: node,
    loader: object,
  };

  static defaultProps = {
    as: 'div',
    nbPreLoad: 1,
    useWindow: false,
    style: {},
    loader: {
      inline: 'centered',
    },
  };

  state = {
    /**
     * @type { number }
     * The number of pixels scrolled from the top.
     */
    scrollTop: 0,
  };

  selectContainerHeight = createSelector(
    (_, props) => props.useWindow,
    (_, props) => props.height,
    (useWindow, height) => (useWindow ? window.innerHeight : height),
  );

  /**
   * @type { (state, props) => number }
   * Selects the index of the first visible node.
   */
  selectMaxStartIndex = createSelector(
    state => state.scrollTop,
    (_, props) => props.elementHeight,
    (scrollTop, elementHeight) => {
      // Index of the first visible element
      const maxStart = findLastElementIndex(elementHeight, scrollTop);
      return maxStart;
    },
  );

  /**
   * @type { (state, props) => number }
   * Selects the index where to start displaying nodes, including preloaded ones.
   */
  selectStartIndex = createSelector(
    this.selectMaxStartIndex,
    (_, props) => props.nbPreLoad,
    (maxStart, nbPreLoad) => {
      // Load extra if possible
      const start = Math.max(0, maxStart - nbPreLoad);
      return start;
    },
  );

  /**
   * @type { (state, props) => number }
   * Selects the end index where to stop displaying nodes, including preloaded ones.
   */
  selectEndIndex = createSelector(
    this.selectMaxStartIndex,
    (_, props) => props.elementHeight,
    this.selectContainerHeight,
    (_, props) => props.nbPreLoad,
    (maxStart, elementHeight, containerHeight, nbPreLoad) => {
      // Display another element in case
      const minEnd = findLastElementIndex(
        elementHeight,
        containerHeight,
        maxStart + 1, // First element needs to be displayed
        true,
      );

      const end = minEnd + nbPreLoad;
      return end;
    },
  );

  /**
   * @type { (state, props) => React.Nodes[] }
   * Slices the element nodes to select only the one to display.
   */
  selectElementsToDisplay = createSelector(
    (_, props) => props.elements,
    this.selectStartIndex,
    this.selectEndIndex,
    (elements, start, end) => {
      const elementsToDisplay = elements.slice(start, end);
      return elementsToDisplay;
    },
  );

  /**
   * @type { (state, props) => { before: number, after: number } }
   * Calculates the sizes in pixels of the filler blocks.
   */
  selectFillersSizes = createSelector(
    this.selectStartIndex,
    this.selectEndIndex,
    (_, props) => props.elementHeight,
    (_, props) => props.elements,
    (start, end, elementHeight, elements) => {
      const nbElements = elements.length;

      const before = times(
        start,
        index =>
          Array.isArray(elementHeight) ? elementHeight[index] : elementHeight,
        // Sum the heights of the elements before the displayed elements
      ).reduce((sum, value) => sum + value, 0);

      const after = times(nbElements - Math.min(nbElements, end), index =>
        // Sum the heights of the elements before the displayed elements
        Array.isArray(elementHeight)
          ? elementHeight[end + index]
          : elementHeight,
      ).reduce((sum, value) => sum + value, 0);

      return { before, after };
    },
  );

  componentDidMount() {
    const { useWindow } = this.props;
    if (useWindow) window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    const { useWindow } = this.props;
    if (useWindow) window.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * @type { () => any }
   * Debouncely checks if the scroll has reached the bottom, and requests more
   * data if it has, and if it's not the last batch. Used for infinite-scrolling
   * containers.
   */
  handleCheckRequestNextData = debounce(() => {
    const { requestMoreData, isLastBatch, elements } = this.props;
    if (requestMoreData && !isLastBatch) {
      const end = this.selectEndIndex(this.state, this.props);
      if (end >= elements.length) {
        requestMoreData();
      }
    }
  }, 500);

  /**
   * @type { () => any }
   * Debouncely updates the scrolling value from the top.
   */
  handleUpdateScrolling = debounce(
    scrollTop => {
      this.setState({ scrollTop }, this.handleCheckRequestNextData);
    },
    250,
    { leading: false, trailing: true },
  );

  handleScroll = evt => {
    const { useWindow } = this.props;
    const scrollTop = useWindow ? window.scrollY : evt.target.scrollTop;
    this.handleUpdateScrolling(scrollTop);
  };

  render() {
    const {
      as: As,
      height,
      elements,
      elementHeight,
      nbPreLoad,
      style,
      isLastBatch,
      handleRequestNextData,
      children,
      loader,
      useWindow,
      ...props
    } = this.props;

    const elementNodes = this.selectElementsToDisplay(this.state, this.props);
    const { before, after } = this.selectFillersSizes(this.state, this.props);

    const mainProps = useWindow
      ? {
          style,
        }
      : {
          onScroll: this.handleScroll,
          style: { overflowY: 'auto', ...style },
        };

    return (
      <As {...mainProps} {...props}>
        <div className="before-helper" style={{ height: `${before}px` }} />
        {elementNodes}
        <div className="after-helper" style={{ paddingTop: `${after}px` }}>
          {!isLastBatch && <Loader {...loader} active />}
        </div>
        {children}
      </As>
    );
  }
}

export default VirtualisedNodes;
