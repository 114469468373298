import React, { Component } from 'react';
import { oneOfType, string, element, func, node } from 'prop-types';
import classNames from 'classnames';

class NoDataMessage extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
    children: node,
  };

  static defaultProps = {
    as: 'p',
    children: 'No data could be found.',
  };

  render() {
    const { as: As, className, ...props } = this.props;

    return (
      <As className={classNames('no-data-message', className)} {...props} />
    );
  }
}

export default NoDataMessage;
