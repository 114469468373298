import React, { Component } from 'react';
import { oneOfType, string, element, func, bool, oneOf } from 'prop-types';
import classNames from 'classnames';
import { createSelector } from 'reselect';

import icomoonLibrary from '../../assets/icons/icomoon/icons-data.json';

export const ICON_NAMES = {
  posts: 'posts',
  camera: 'camera',
  closeexpanded: 'closeexpanded',
  comment: 'comment',
  decrease: 'decrease',
  delete: 'delete',
  expand: 'expand',
  filter: 'filter',
  goals: 'goals',
  home: 'home',
  increase: 'increase',
  leaderboard: 'leaderboard',
  notifications: 'notifications',
  profile: 'profile',
  search: 'search',
  settings: 'settings',
  share: 'share',
};

class Icomoon extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
    name: oneOf(Object.keys(ICON_NAMES)),
    size: oneOf(['small', 'medium', 'large']),
    className: string,
    filled: bool,
    'aria-hidden': bool,
    viewBox: string,
  };

  static defaultProps = {
    as: 'span',
    size: 'medium',
    filled: false,
    'aria-hidden': true,
    viewBox: '0 0 1024 1024',
  };

  selectSvgPath = createSelector(
    props => props.name,
    props => props.filled,
    (name, filled) => {
      const iconFullName = `${name}${filled ? 'Filled' : 'Line'}`;
      const iconData = icomoonLibrary.icons.find(
        icon => icon.properties.name === iconFullName,
      );

      if (!iconData) {
        console.error(`Invalid name '${iconFullName}' given to <Icomoon />`);
        return '';
      }

      const {
        icon: { paths },
      } = iconData;

      return paths.join(' ');
    },
  );

  getPath() {
    return this.selectSvgPath(this.props);
  }

  render() {
    const { as: As, name, className, filled, size, ...props } = this.props;

    const path = this.getPath();

    return (
      <svg className={classNames('ico-moon', `-${size}`, className)} {...props}>
        <path d={path} />
      </svg>
    );
  }
}

export default Icomoon;
