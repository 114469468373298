import React, { Component, Fragment } from 'react';
import { string, shape, instanceOf, node } from 'prop-types';
import { Feed } from 'semantic-ui-react';
import moment from 'moment';
import { createSelector } from 'reselect';

class BaseEventInfo extends Component {
  static propTypes = {
    event: shape({
      actor: shape({
        thumbnailUrl: string,
        username: string.isRequired,
      }).isRequired,
      dateCreated: instanceOf(Date).isRequired,
    }).isRequired,
    children: node,
  };

  static defaultProps = {
    event: {
      author: {},
      dateCreated: new Date(),
    },
    children: undefined,
  };

  selectMomentedDate = createSelector(
    props => props.event,
    event => moment(event.dateCreated),
  );

  render() {
    const {
      event: { actor },
      children,
      text,
    } = this.props;

    const dateCreated = this.selectMomentedDate(this.props);

    return (
      <Fragment>
        {actor.thumbnailUrl && <Feed.Label image={actor.thumbnailUrl} />}
        <Feed.Content>
          <Feed.Summary>
            <Feed.User as="strong">{actor.username}</Feed.User> {text}
            <Feed.Date>{dateCreated.fromNow(true)}</Feed.Date>
          </Feed.Summary>
          {children}
        </Feed.Content>
      </Fragment>
    );
  }
}

export default BaseEventInfo;
