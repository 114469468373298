import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Comment, Loader } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import update from 'immutability-helper';

import CachedAuthorCommentItem from '../../containers/posts/CachedAuthorCommentItem';
import FetchedAuthorCommentItem from '../../containers/posts/FetchedAuthorCommentItem';
import { doubleFilter } from '../../modules/utils';
import AddCommentForm from '../../containers/posts/AddCommentForm';
import { makeSelectOrderedByDate } from '../../modules/selectors';
import { getLoggedInUid } from '../../services/authentication';

const findReplies = function findReplies(parentUid, comments) {
  const [replies, orphanReplies] = doubleFilter(
    comments,
    comment => comment.parentUid === parentUid,
  );

  return [replies, orphanReplies];
};

class Comments extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired,
        dateCreated: PropTypes.instanceOf(Date).isRequired,
      }),
    ).isRequired,
    requestMoreData: PropTypes.func.isRequired,
    postUid: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    isLastBatch: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
    isLastBatch: false,
    minimal: true,
  };

  state = {
    replyingTo: new Map(), // uid => author
  };

  selectOtherProps = createSelector(
    (_, props) => props,
    props => {
      const {
        data,
        isLoading,
        isLastBatch,
        requestMoreData,
        requestRefreshData,
        noCache,
        postUid,
        ...otherProps
      } = props;
      return otherProps;
    },
  );

  selectSeparatedCommentsAndReplies = createSelector(
    (_, props) => props.data,
    comments => {
      const filteredData = doubleFilter(
        comments,
        comment => !Boolean(comment.parentUid),
      );
      return filteredData;
    },
  );

  selectCommentsAndReplies = createSelector(
    this.selectSeparatedCommentsAndReplies,
    ([comments, replies]) => {
      // Orphan replies are replies that have not been matched with a parent comment
      let orphanReplies = replies;
      const commentsWithReplies = comments.map(comment => {
        const [repliesToThis, newOrphanReplies] = findReplies(
          comment.uid,
          orphanReplies,
        );

        orphanReplies = newOrphanReplies;

        return {
          ...comment,
          replies: repliesToThis,
        };
      });

      /**
       * Add the orphan replies that are left to the list to display them.
       * The existence of orphan replies is due to the lazy-loading of comments:
       * if you load the replies to a comment, but not the parent comment, these
       * replies end up being orphan, but we still need to display them.
       */
      return commentsWithReplies.concat(orphanReplies);
    },
  );

  selectOrderedComments = makeSelectOrderedByDate(
    this.selectCommentsAndReplies,
    'dateCreated',
    'asc',
  );

  handleClickReply = ({ uid, author }) => {
    const { replyingTo } = this.state;

    if (!replyingTo.has(uid)) {
      this.setState(
        update(this.state, {
          replyingTo: { $add: [[uid, author]] },
        }),
      );
    }
  };

  /**
   * Handler to render a comment and its replies OR an orphan reply.
   * @type { function }
   */
  renderComment = ({ replies = [], ...comment }) => {
    const { noCache, postUid } = this.props;
    const otherProps = this.selectOtherProps(this.state, this.props);
    const { replyingTo } = this.state;

    const CommentItem = noCache
      ? FetchedAuthorCommentItem
      : CachedAuthorCommentItem;

    // Prepare rendering of the replies
    const replyNodes = replies.map(reply => (
      <CommentItem
        key={reply.uid}
        authorUid={reply.userUid}
        comment={reply}
        onClickReply={this.handleClickReply}
        postUid={postUid}
      />
    ));

    // Check if we are replying to this comment to display the form or not
    const uidMention = replyingTo.get(comment.uid);

    /**
     * We also need to check if we are replying to the parent comment because
     * orphan replies are rendered on their own and we still want to render the reply
     * form just under them.
     */
    const replyMention =
      uidMention !== undefined ? uidMention : replyingTo.get(comment.parentUid);
    const isReplying = Boolean(getLoggedInUid()) && replyMention !== undefined;

    return (
      <CommentItem
        key={comment.uid}
        authorUid={comment.userUid}
        comment={comment}
        onClickReply={this.handleClickReply}
        postUid={postUid}
      >
        {(replyNodes.length > 0 || isReplying) && (
          <Comment.Group {...otherProps}>
            {replyNodes}
            {isReplying && (
              <AddCommentForm
                id={`add-comment-${comment.parentUid || comment.uid}`}
                initialValue={{ text: replyMention && `@${replyMention} ` }}
                autoFocus
                replyTo={replyMention || isReplying}
                extraParams={{
                  uid: postUid,
                  // For orphan reply, give their parent uid, else just give the uid
                  parentUid: comment.parentUid || comment.uid,
                }}
                simple
              />
            )}
          </Comment.Group>
        )}
      </CommentItem>
    );
  };

  render() {
    const { isLoading, isLastBatch, requestMoreData } = this.props;

    const otherProps = this.selectOtherProps(this.state, this.props);

    const commentsWithReplies = this.selectOrderedComments(
      this.state,
      this.props,
    );

    const commentNodes = commentsWithReplies.map(this.renderComment);

    return (
      <Fragment>
        {!isLastBatch && (
          <button
            className="link -tertiary"
            onClick={requestMoreData}
            disabled={isLoading}
          >
            Load more comments <Loader inline size="tiny" active={isLoading} />
          </button>
        )}
        <Comment.Group {...otherProps}>{commentNodes}</Comment.Group>
      </Fragment>
    );
  }
}

export default Comments;
