import { getPublicPostsComments } from '../../services/posts';
import withData from '../../components/higher-order/withData';
import PartiallyLoadedComments from '../../components/post/PartiallyLoadedComments';

const PublicCommentsContainer = withData(getPublicPostsComments, {
  paramPropName: 'postUid',
  passDownParams: true,
})(PartiallyLoadedComments);

export default PublicCommentsContainer;
