import React, { Component } from 'react';
import classNames from 'classnames';
import { Form } from 'semantic-ui-react';

import UserMentionInput from '../user/UserMentionInput';
import { bool, object, string, oneOfType } from 'prop-types';

class CommentForm extends Component {
  static propTypes = {
    initialValue: object,
    contrasted: bool,
    replyTo: oneOfType([string, bool]),
    simple: bool,
  };

  handleInputKeyPress = evt => {
    const { onSubmit } = this.props;
    const { charCode } = evt;
    if (charCode === 13) {
      evt.preventDefault();
      onSubmit(evt);
    }
  };

  handleButtonKeyPress = evt => {
    const { onSubmit } = this.props;
    const { charCode } = evt;
    if (charCode === 32 || charCode === 13) {
      evt.preventDefault();
      onSubmit(evt);
    }
  };

  render() {
    const {
      id,
      autoFocus,
      className,
      formData,
      loading,
      cacheUserMention,
      onChange,
      contrasted,
      initialValue,
      replyTo,
      simple,
      ...props
    } = this.props;

    const { onSubmit } = props;

    return (
      <Form
        className={classNames('comment-form', simple && '-simple')}
        reply={Boolean(replyTo)}
        {...props}
      >
        <Form.Field>
          <UserMentionInput
            autoFocus={autoFocus}
            cacheUserMention={cacheUserMention}
            action={{
              className: '-tertiary',
              content: 'Post',
              disabled: loading || !formData.text,
              loading: loading,
              onKeyPress: this.handleButtonKeyPress,
              onClick: onSubmit,
              type: 'submit',
            }}
            className={classNames('-fluid', className)}
            disabled={loading}
            name="text"
            fluid
            id={`${id}-text`}
            onChange={onChange}
            onKeyPress={this.handleInputKeyPress}
            placeholder={
              replyTo
                ? `Reply${replyTo !== true ? ` to @${replyTo}` : ''}...`
                : 'Add a comment...'
            }
            value={formData.text || ''}
            contrasted
          />
        </Form.Field>
      </Form>
    );
  }
}

export default CommentForm;
