import React, { Component, Fragment } from 'react';
import { arrayOf, shape, string, bool, func } from 'prop-types';
import { Feed, Icon } from 'semantic-ui-react';
import classNames from 'classnames';

import { makeSelectOrderedByDate } from '../../modules/selectors';
import BottomMessage from '../BottomMessage';
import SpotEvent from './SpotEvent';
import PostEvent from './PostEvent';
import InfoEvent from './InfoEvent';
import TrophyEvent from './TrophyEvent';
import SegmentEvent from './SegmentEvent';
import { FLEXPIT_ICONS } from '../../assets/icons/flexpit';
import SvgImage from '../SvgImage';
import withLoggedInUser from '../higher-order/withLoggedInUser';
import VirtualisedNodes from '../VirtualisedNodes';

const EVENT_ACTIONS = {
  info: 'INFO',
  groupInfo: 'GROUP_INFO',
  spot: 'FOLLOW',
  fistbump: 'LIKE',
  mentionPost: 'MENTION_POST',
  comment: 'COMMENT',
  fistbumpComment: 'COMMENT_LIKE',
  mentionComment: 'MENTION_COMMENT',
  liftVerificationRequest: 'LIFT_VERIFICATION_REQUEST',
  liftApproval: 'LIFT_APPROVAL',
  trophy: 'TROPHY',
};

const EVENT_COMPONENTS = {
  [EVENT_ACTIONS.spot]: SpotEvent,

  [EVENT_ACTIONS.fistbump]: function FistBumpEvent({
    currentUserState: { profile },
    ...props
  }) {
    const {
      event: {
        target: {
          post: { isLift },
        },
      },
    } = props;
    return (
      <PostEvent
        text={`fist bumped your ${isLift ? 'lift' : 'post'}.`}
        postAuthor={profile}
        {...props}
      />
    );
  },

  [EVENT_ACTIONS.mentionPost]: function MentionPostEvent(props) {
    const {
      event: {
        target: {
          post: { isLift },
        },
      },
    } = props;
    return (
      <PostEvent
        text={`mentioned you in a ${isLift ? 'lift' : 'post'}`}
        {...props}
      />
    );
  },

  [EVENT_ACTIONS.comment]: function CommentEvent({
    currentUserState: { profile },
    ...props
  }) {
    const {
      event: {
        target: {
          post: { isLift },
        },
      },
    } = props;
    return (
      <PostEvent
        text={`commented on your ${isLift ? 'lift' : 'post'}.`}
        postAuthor={profile}
        {...props}
      />
    );
  },

  [EVENT_ACTIONS.fistbumpComment]: function FistBumpCommentEvent(props) {
    return <PostEvent text="fist bumped your comment." {...props} />;
  },

  [EVENT_ACTIONS.mentionComment]: function MentionCommentEvent(props) {
    return <PostEvent text="mentioned you in a comment." {...props} />;
  },

  [EVENT_ACTIONS.liftApproval]: function LiftApprovalEvent({
    currentUserState: { profile },
    ...props
  }) {
    const {
      event: {
        data: { approved, feedback },
      },
    } = props;

    const verifiedBlock = (
      <Fragment>
        {approved ? (
          <SvgImage
            src={FLEXPIT_ICONS.verifiedLift.svg}
            fallback={FLEXPIT_ICONS.verifiedLift.png}
          />
        ) : (
          <Icon name="remove circle" color="red" size="large" />
        )}
        {approved ? 'Verified' : 'Rejected'} your lift
      </Fragment>
    );

    return (
      <SegmentEvent segment={verifiedBlock} postAuthor={profile} {...props}>
        {feedback && (
          <Feed.Extra>
            <q>{feedback}</q>
          </Feed.Extra>
        )}
      </SegmentEvent>
    );
  },

  [EVENT_ACTIONS.liftVerificationRequest]: function LiftVerificationRequest(
    props,
  ) {
    const requestBlock = (
      <Fragment>
        <Icon name="question circle" color="blue" /> Requested a lift
        verification
      </Fragment>
    );

    return <SegmentEvent segment={requestBlock} {...props} />;
  },

  [EVENT_ACTIONS.info]: InfoEvent,
  [EVENT_ACTIONS.groupInfo]: InfoEvent,
  [EVENT_ACTIONS.trophy]: TrophyEvent,
};

class Notifications extends Component {
  static propTypes = {
    events: arrayOf(
      shape({
        uid: string.isRequired,
        action: string.isRequired,
      }),
    ).isRequired,
    isLastBatch: bool,
    isLoading: bool,
    requestMoreData: func,
  };

  static defaultProps = {
    events: [],
    isLastBatch: true,
    isLoading: false,
    requestMoreData: undefined,
  };

  static get LIST_PROPS() {
    return {
      height: 250,
      elementHeight: 72,
    };
  }

  selectEvents = makeSelectOrderedByDate((_, props) => props.events);

  renderEvent = event => {
    const { currentUserState } = this.props;
    const { uid, action } = event;
    const EventComponent = EVENT_COMPONENTS[action];

    if (!EventComponent) {
      console.warn(`<Notifications />: unrecognised type of event '${action}'`);
      return undefined;
    }

    return (
      <EventComponent
        key={uid}
        event={event}
        currentUserState={currentUserState}
      />
    );
  };

  render() {
    const { events: _, className, currentUserState, ...props } = this.props;
    const { isLoading, isLastBatch } = props;

    const events = this.selectEvents(this.state, this.props);
    const eventNodes = events.map(this.renderEvent);

    return (
      <Fragment>
        <VirtualisedNodes
          className={classNames('ui feed', 'notification-feed', className)}
          {...Notifications.LIST_PROPS}
          elements={eventNodes}
          nbPreLoad={15}
          {...props}
        >
          {!isLoading && isLastBatch && <BottomMessage />}
        </VirtualisedNodes>
      </Fragment>
    );
  }
}

export default withLoggedInUser(Notifications);
