import React, { Component } from 'react';
import { object } from 'prop-types';
import { Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import { createSelector } from 'reselect';

import { getIsPublic } from '../../services/authentication';
import ScrollingModal from '../ScrollingModal';
import Page404 from '../../pages/Page404';
import PostItemWithAuthor, {
  PostItemWithAuthorContainer,
} from '../../containers/posts/PostItemWithAuthor';

class PostModal extends Component {
  static propTypes = {
    data: object,
  };

  static defaultProps = {
    data: undefined,
  };

  /**
   * @type { function }
   * Selects the padding bottom value, used to adjust the height of the post
   * based on the format of the post.
   *
   * See: FLEX-828 Allow landscape and portrait images
   */
  selectRatioHelperValue = createSelector(
    (_, props) => props.data,
    post => {
      // Create values for each posts, [uid, value]
      const { isLandscape, width = 1, height = 1 } = post.imageMeta || {
        isLandscape: false,
      };

      // We want the image to be half the width of the post
      const baseRatio = 50;

      // For square images, no computation needed
      let totalBottom = baseRatio;

      if (isLandscape) {
        const heightRelativeToWidth = height / width;
        totalBottom = heightRelativeToWidth * baseRatio;
      }
      return totalBottom;
    },
  );

  render() {
    const { data, isLoading, className, ...otherProps } = this.props;
    const isPublic = getIsPublic();

    const helperValue =
      data && this.selectRatioHelperValue(this.state, this.props);

    // TODO remove scrollingmodal when post has new layout
    // TODO These ugly consumers can be removed when we upgrade to the new SUIR version
    return (
      <ScrollingModal
        className={classNames('post-modal', '-padless', className)}
        {...otherProps}
      >
        <div style={helperValue && { paddingBottom: `${helperValue}%` }} />
        <Modal.Content>
          {data ? (
            isPublic ? (
              <PostItemWithAuthor data={data} />
            ) : (
              <PostItemWithAuthorContainer
                data={data}
                subscriptionParams={{ uid: data.uid }}
              />
            )
          ) : (
            <Page404 />
          )}
        </Modal.Content>
      </ScrollingModal>
    );
  }
}

export default PostModal;
