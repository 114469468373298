import React, { Component } from 'react';
import {
  func,
  string,
  arrayOf,
  node,
  shape,
  oneOfType,
  bool,
} from 'prop-types';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';

class ButtonSwitch extends Component {
  static propTypes = {
    options: arrayOf(
      oneOfType([
        string,
        shape({
          value: string,
          text: node,
        }),
      ]),
    ).isRequired,
    name: string,
    onChange: func,
    toggle: bool,
    value: string,
  };

  static defaultProps = {
    options: [],
    type: 'button',
    toggle: true,
  };

  render() {
    const { className, onChange, value, options, ...moreProps } = this.props;

    const commonProps = {
      onClick: onChange,
    };

    const buttonNodes = options.map(option => (
      <Button
        {...commonProps}
        {...moreProps}
        key={option.value || option}
        active={value === (option.value || option)}
        value={option.value || option}
      >
        {option.text || option}
      </Button>
    ));

    return (
      <Button.Group className={classNames('button-switch', className)}>
        {buttonNodes}
      </Button.Group>
    );
  }
}

export default ButtonSwitch;
