import React, { Component } from 'react';

import HeaderSegment from '../../components/HeaderSegment';
import ActivityGridContainer from '../../containers/activities/ActivityGridContainer';
import ContentContainer from '../../components/ContentContainer';

class LeaderboardPage extends Component {
  render() {
    return (
      <ContentContainer size="large">
        <HeaderSegment header="Activities">
          <ActivityGridContainer />
        </HeaderSegment>
      </ContentContainer>
    );
  }
}

export default LeaderboardPage;
