import { getUser } from '../../services/users';
import withData from '../../components/higher-order/withData';
import UserProfile from '../../components/user/UserProfile';

const UserProfileContainer = withData(getUser, {
  paramPropName: 'userParams',
  singleData: true,
})(UserProfile);

export default UserProfileContainer;
