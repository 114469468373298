import React, { Component } from 'react';
import { string } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { MenuItem } from 'semantic-ui-react';
import classNames from 'classnames';

/**
 * Simple wrapper to render a SemanticUI MenuItem with a react-router NavLink.
 */
class RouterMenuItem extends Component {
  static propTypes = {
    to: string.isRequired,
    activeClassname: string,
  };

  static defaultProps = {
    activeClassname: '',
  };

  render() {
    const { activeClassname, ...otherProps } = this.props;
    return (
      <MenuItem
        as={NavLink}
        activeClassName={classNames('active', activeClassname)}
        {...otherProps}
      />
    );
  }
}

export default RouterMenuItem;
