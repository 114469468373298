import React, { Component, Fragment } from 'react';
import { element, array, number, oneOfType, string, func } from 'prop-types';
import { List, Loader } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import check from 'check-types';

import {
  makeSelectSlicedData,
  makeSelectConcatenatedSlices,
} from '../modules/selectors';
import { Waypoint } from 'react-waypoint';

class PartialLoadingList extends Component {
  static propTypes = {
    items: array.isRequired,
    itemAs: oneOfType([string, element, func]),
    nbBySlices: number,
    nbFirstSlice: number,
  };

  static defaultProps = {
    itemAs: List.Item,
    nbBySlices: 30,
    nbFirstSlice: 20,
  };

  state = {
    index: 0,
  };

  selectSlicedData = makeSelectSlicedData((_, props) => props.items);

  selectIsLastBatch = createSelector(
    this.selectSlicedData,
    state => state.index,
    (slicedData, index) => index >= slicedData.length,
  );

  selectDataToDisplay = makeSelectConcatenatedSlices(this.selectSlicedData);

  getDataToDisplay() {
    return this.selectDataToDisplay(this.state, this.props);
  }

  getIsLastBatch() {
    return this.selectIsLastBatch(this.state, this.props);
  }

  handleEnterWaypoint = () => {
    if (!this.getIsLastBatch()) {
      this.setState(state => ({
        index: state.index + 1,
      }));
    }
  };

  renderItems() {
    const { itemAs: ItemAs } = this.props;
    const renderFunc = check.function(ItemAs)
      ? ItemAs
      : item => <ItemAs {...item} />;

    const dataToDisplay = this.getDataToDisplay();

    return dataToDisplay.map(renderFunc);
  }

  render() {
    const {
      items,
      itemAs,
      nbBySlices,
      nbFirstSlice,
      isLoading,
      ...listProps
    } = this.props;

    const isLastBatch = this.getIsLastBatch();
    const children = this.renderItems();

    return (
      <Fragment>
        <List {...listProps}>{children}</List>
        {(!isLastBatch || isLoading) && (
          <Loader className="lazy-loader" active inline="centered" />
        )}
        {!isLastBatch && !isLoading && (
          <Waypoint onEnter={this.handleEnterWaypoint} />
        )}
      </Fragment>
    );
  }
}

export default PartialLoadingList;
