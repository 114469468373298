export const headerBarHeight = 70;

/**
 * Computes the ratio to be used inside a `padding-bottom` helper to get a
 * responsive image in post that respect the image ratio.
 *
 * @param { object } post The post to compute the ratio from.
 * @param { object } post.imageMeta The post's image information
 * @param { boolean } post.imageMeta.isLandscape `true` if the post has a landscape
 * or portrait medium, `false` if it's square.
 * @param { number } post.imageMeta.width The width of the medium.
 * @param { number } post.imageMeta.height The height of the medium.
 * @returns { number } A number in percentage representing the `padding-bottom`
 * to be used on the helper used inside the post to make it responsive.
 */
export const computePostMediumRatio = function computePostMediumRatio(post) {
  const { isLandscape, width = 1, height = 1 } = post.imageMeta || {
    isLandscape: false,
  };

  // We want the image to be half the width of the post
  const baseRatio = 50;

  // For square images, no computation needed
  let finalRatio = baseRatio;

  if (isLandscape) {
    const imageRatio = height / width;
    finalRatio = imageRatio * baseRatio;
  }

  return finalRatio;
};

/*
 * If you did not know this technique, the `padding-bottom` technique is used
 * to get a responsive image that scales with the given space while keeping
 * its ratio. Basically, padding defined using percentages uses the width of
 * its container consistantly. This means that you can exploit it to create
 * vertical space that is proportional to the width of the container.
 *
 * You can find more information if you type `padding-bottom technique` in your
 * favourite search engine.
 */
