import { shape, string } from 'prop-types';
import React from 'react';
import ContentContainer from '../components/ContentContainer';
import SinglePostItemContainer from '../containers/posts/SinglePostItemContainer';
import { getIsPublic } from '../services/authentication';
import Page404 from './Page404';
import PublicSinglePost from '../containers/posts/PublicSinglePostItem';

const propTypes = {
  match: shape({
    params: shape({
      postUid: string,
    }).isRequired,
  }).isRequired,
};

const SinglePostPage = ({
  match: {
    params: { postUid },
  },
}) => {
  if (!postUid) {
    return <Page404 />;
  }

  const isPublic = getIsPublic();

  return (
    <ContentContainer size="big">
      {isPublic ? (
        <PublicSinglePost postUid={postUid} />
      ) : (
        <SinglePostItemContainer postUid={postUid} />
      )}
    </ContentContainer>
  );
};

SinglePostPage.propTypes = propTypes;

export default SinglePostPage;
