import React from 'react';
import { LoggedInUserContext } from './higher-order/withLoggedInUser';
import { Loader } from 'semantic-ui-react';
import { convertKgToRoundedLbs, roundKg } from '../modules/units';
import { getLoggedInUid } from '../services/authentication';

/**
 * Converts the given weight value into the user's preferred unit.
 * @param { object } currentUser The current user from the context.
 * @param { number|string } value The value to convert.
 * @param { boolean } displayUnit true to include the unit in the resulting string.
 * @returns { string|number } The converted value into the user's preferred unit,
 * number if `displayUnit` is false, else a string with the value and the unit
 * (e.g. `164 lbs`).
 */
export const convertWeightIntoUserPreferredUnit = function convertWeightIntoUserPreferredUnit(
  { profile } = {},
  value,
  displayUnit,
) {
  const { units: { weight = 'lbs' } = {} } = profile || {};

  let convertedValue;

  switch (weight) {
    case 'lbs': {
      convertedValue = convertKgToRoundedLbs(value);
      break;
    }

    case 'kg': {
      convertedValue = roundKg(value);
      break;
    }

    default: {
      console.warn(`<Weight />: unrecognised unit '${weight}'`);
      convertedValue = value;
      break;
    }
  }
  return displayUnit ? `${convertedValue} ${weight}` : convertedValue;
};

const Weight = function Weight({
  children,
  value = children,
  displayUnit = true,
}) {
  return getLoggedInUid() ? (
    <LoggedInUserContext.Consumer>
      {userCtx => {
        if (!userCtx.profile) {
          return <Loader inline size="mini" />;
        }
        return convertWeightIntoUserPreferredUnit(userCtx, value, displayUnit);
      }}
    </LoggedInUserContext.Consumer>
  ) : (
    // Default is lbs, so just call function
    convertWeightIntoUserPreferredUnit({}, value, true)
  );
};

export default Weight;
