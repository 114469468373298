import React, { Component } from 'react';
import { oneOfType, string, element } from 'prop-types';
import { Menu } from 'semantic-ui-react';
import RouterMenuItem from '../../components/RouterMenuItem';
import { routes } from '../../modules/routes';

class MainNavigation extends Component {
  static propTypes = {
    as: oneOfType([string, element]),
  };

  static defaultProps = {
    as: 'nav',
  };

  render() {
    const { ...menuProps } = this.props;
    return (
      <Menu {...menuProps}>
        <RouterMenuItem exact to="/">
          Feed
        </RouterMenuItem>
        <RouterMenuItem to={routes.leaderboard.root}>
          Leaderboard
        </RouterMenuItem>
        <RouterMenuItem to={routes.standard.root}>
          FLEXPIT standard
        </RouterMenuItem>
      </Menu>
    );
  }
}

export default MainNavigation;
