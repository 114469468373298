import React, { Component } from 'react';
import { withCachedUsers } from '../../components/higher-order/withCachedUser';
import UserList from '../../components/user/UserList';
import { createSelector } from '../../../node_modules/reselect';
import { doubleFilter } from '../../modules/utils';

const FistBumpList = withCachedUsers({
  uidPropName: 'userUids',
  dataPropName: 'users',
})(
  class FistBumpListComp extends Component {
    selectProps = createSelector(
      props => props.users,
      users => {
        const [loadedUsers, loadingUsers] = doubleFilter(
          users,
          user => !user.isLoading,
        );

        return {
          users: loadedUsers.map(user => user.data),
          isLoading: loadingUsers.length > 0,
        };
      },
    );

    render() {
      const { users: _, ...props } = this.props;

      const { users, isLoading } = this.selectProps(this.props);
      return <UserList users={users} isLoading={isLoading} {...props} />;
    }
  },
);

export default FistBumpList;
