import React, { Component } from 'react';
import { string, func, shape } from 'prop-types';
import { Input, Dropdown } from 'semantic-ui-react';
import { createSelector } from 'reselect';

import {
  convertCmToFeetAndInches,
  convertFeetAndInchesToCm,
} from '../modules/units';
import withLoggedInUser from './higher-order/withLoggedInUser';

const unitOptions = [
  { text: 'ft', value: 'feet' },
  { text: 'cm', value: 'cm' },
];

class HeightInput extends Component {
  static propTypes = {
    name: string.isRequired,
    onChange: func.isRequired,
    value: shape({
      feet: string,
      inches: string,
      cm: string,
    }).isRequired,
  };

  static defaultProps = {
    type: 'number',
    value: {
      feet: '',
      inches: '',
      cm: '',
    },
    min: 0,
  };

  constructor(props) {
    super(props);

    const {
      currentUserState: { profile },
    } = props;
    this.state = {
      unit:
        profile && profile.units && profile.units.height !== 'ft'
          ? profile.units.height
          : 'feet',
    };
  }

  selectValue = createSelector(
    (_, props) => props.value,
    state => state.unit,
    (value, unit) => {
      if (value[unit] || Object.keys(value).every(key => value[key] === '')) {
        return value;
      }
      const { feet, inches, cm } = value;

      const result = { feet, inches, cm };

      if (unit === 'cm') {
        result.cm = convertFeetAndInchesToCm({ feet, inches }).toString();
      } else {
        const { feet: nbFeet, inches: nbInches } = convertCmToFeetAndInches(
          result.cm,
        );
        result.feet = nbFeet.toString();
        result.inches = nbInches.toString();
      }

      return result;
    },
  );

  convertValues(value, unit = this.state.unit) {
    const currentValue = this.selectValue(this.state, this.props);
    let cm = '';
    let inches = '';
    let feet = '';

    const parsedValue = parseFloat(value);

    if (value !== '' && !isNaN(parsedValue)) {
      if (unit === 'cm') {
        // Need to compute inches/feet
        cm = value;
        const { inches: nbInches, feet: nbFeet } = convertCmToFeetAndInches(
          parsedValue,
        );
        inches = nbInches.toString();
        feet = nbFeet.toString();
      } else {
        // Need to compute cm
        let nbInches;
        let nbFeet;

        // If inches, limit the value to 12 and increment feet
        if (unit === 'inches') {
          nbFeet =
            parseFloat(currentValue.feet || 0) + Math.floor(parsedValue / 12);
          nbInches = Math.floor(parsedValue % 12);
        } else {
          nbFeet = parsedValue;
          nbInches = inches || 0;
        }
        cm = convertFeetAndInchesToCm({
          feet: nbFeet,
          inches: nbInches,
        }).toString();
        inches = nbInches.toString();
        feet = nbFeet.toString();
      }
    }

    const convertedValues = {
      cm,
      inches,
      feet,
    };
    return convertedValues;
  }

  handleChange = (_, { name: names, value }) => {
    const [, unit] = names.split('.');
    const convertedValues = this.convertValues(value, unit);

    const { name, onChange } = this.props;
    const target = {
      name,
      value: convertedValues,
    };

    return onChange({ target }, target);
  };

  handleUnitChange = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      id,
      name,
      onChange,
      value,
      currentUserState,
      ...props
    } = this.props;
    const { unit } = this.state;

    const values = this.selectValue(this.state, this.props);

    const unitDropdown = (
      <Dropdown
        name="unit"
        onChange={this.handleUnitChange}
        value={unit}
        options={unitOptions}
      />
    );

    return unit === 'feet' ? (
      <div className="inputs">
        <Input
          placeholder="Feet"
          id={`${id}-feet`}
          onChange={this.handleChange}
          name={`${name}.feet`}
          min={0}
          value={values.feet}
          label={unitDropdown}
          labelPosition="right"
          {...props}
        />
        <Input
          placeholder="Inches"
          id={`${id}-inches`}
          onChange={this.handleChange}
          name={`${name}.inches`}
          step={1}
          min={0}
          max={12}
          label="inches"
          labelPosition="right"
          value={values.inches}
          {...props}
        />
      </div>
    ) : (
      <Input
        placeholder="Centimeters"
        id={id}
        onChange={this.handleChange}
        name={`${name}.cm`}
        min={0}
        step={0.1}
        value={values.cm}
        label={unitDropdown}
        labelPosition="right"
        {...props}
      />
    );
  }
}

export default withLoggedInUser(HeightInput);
