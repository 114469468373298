import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import identity from 'lodash/identity';

/**
 * Returns a higher-order component that handles the state of a controlled-form,
 * and send the data to an API call when submitting.
 * @param { object } params
 * @param { function } params.apiFunction The function to call when submitting the form
 * that returns a promise.
 * @returns { function } A higher-order component.
 */
export const withSubmitApiCall = function withSubmitApiCall(apiFunction) {
  return WrappedComponent => {
    const wrappedDisplayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    class WithSubmitApiCall extends Component {
      static propTypes = {
        extraParams: PropTypes.object,
        onError: PropTypes.func,
        onSuccess: PropTypes.func,
        parseData: PropTypes.func,
        initialValue: PropTypes.object,
      };

      static defaultProps = {
        extraParams: {},
        onSuccess: identity,
        onError: identity,
        parseData: identity,
        initialValue: {},
      };

      displayName = `WithSubmitApiCall(${wrappedDisplayName})`;

      constructor(props) {
        super(props);

        this.state = {
          error: undefined,
          formData: props.initialValue,
          isSubmitting: false,
        };
      }

      async submitForm() {
        const { parseData } = this.props;
        const { formData } = this.state;
        let parsedData;

        // Parse data if given a function for it
        try {
          parsedData = parseData(formData);
        } catch (error) {
          console.error(error);
          this.setState({ error });
          return;
        }

        // Show loading state and reset error
        this.setState({
          error: undefined,
          isSubmitting: true,
        });

        const { extraParams } = this.props;

        const params = {
          ...parsedData,
          ...extraParams,
        };

        let error = null;
        let result;

        try {
          result = await apiFunction(params);
        } catch (internalError) {
          console.error(internalError);
          error = internalError;
        }

        const { initialValue } = this.props;
        this.setState({
          error,
          isSubmitting: false,
          formData: initialValue,
        });
        const { onError, onSuccess } = this.props;
        if (error) {
          onError(error);
        } else {
          onSuccess(result);
        }
      }

      handleInputChange = ({ target }, data, onUpdate) => {
        // Semantic gives a data field, but just adding an extra safe-check
        const { name, value } = data || target;

        // Handle subobjects, like `foo.bar`
        const updateQuery = name.split('.').reduceRight(
          (q, subname) => {
            return { [subname]: q };
          },
          { $set: value },
        );

        this.setState(
          update(this.state, {
            formData: updateQuery,
            error: { $set: undefined },
          }),
          onUpdate,
        );
      };

      handleSubmit = evt => {
        if (evt) evt.preventDefault();
        this.submitForm();
      };

      render() {
        const {
          extraParams,
          parseData,
          onSuccess,
          onError,
          ...wrappedProps
        } = this.props;
        const { formData, error, isSubmitting } = this.state;
        const extraProps = {
          error,
          formData,
          loading: isSubmitting,
          onChange: this.handleInputChange,
          onSubmit: this.handleSubmit,
        };

        return <WrappedComponent {...extraProps} {...wrappedProps} />;
      }
    }

    return WithSubmitApiCall;
  };
};
