import React, { Component } from 'react';
import { oneOf, bool, string, shape, number } from 'prop-types';
import classNames from 'classnames';
import { Icon, Button } from 'semantic-ui-react';
import { createSelector } from 'reselect';

import Slicon from './icons/Slicon';

class Video extends Component {
  static propTypes = {
    source: string.isRequired,
    className: string,
    preload: oneOf(['none', 'metadata', 'auto']),
    mutable: bool,
    controls: bool,
    meta: shape({
      isLandscape: bool,
      height: number,
      width: number,
    }),
  };

  static defaultProps = {
    preload: 'none',
    mutable: true,
    controls: undefined,
    meta: {
      isLandscape: false,
    },
  };

  state = {
    playing: false,
    muted: false,
    dimensions: {
      height: -1,
      width: -1,
    },
  };

  selectVideoStyles = createSelector(
    (_, props) => props.meta,
    meta => {
      if (!meta || meta.isLandscape) {
        return { adjuster: undefined, container: undefined };
      }

      const { width = 1, height = 1 } = meta;

      const adjuster = {
        height: '100%',
        width: '100%',
      };

      const container = {
        flexDirection: 'row',
      };

      if (width > height) {
        adjuster.width = 'auto';
        container.flexDirection = 'column';
      } else if (width < height) {
        adjuster.height = 'auto';
      }

      return { adjuster, container };
    },
  );

  refVideoPlay = React.createRef();

  handleClickPlay = () => {
    this.setState(
      ({ playing }) => ({ playing: !playing }),
      () => {
        const { playing } = this.state;

        if (playing) {
          this.refVideoPlay.current.play();
        } else {
          this.refVideoPlay.current.pause();
        }
      },
    );
  };

  handleResizeVideo = contentRect => {
    this.setState({ dimensions: contentRect.bounds });
  };

  handleToggleMuteVideo = () => {
    this.setState(state => ({
      muted: !state.muted,
    }));
  };

  render() {
    const { className, source, children, mutable, meta, ...props } = this.props;
    const { playing, muted } = this.state;

    const videoProps = {
      muted,
      ref: this.refVideoPlay,
      className: 'video',
      src: source,
      loop: playing,
      ...props,
    };

    const square = !meta || !meta.isLandscape;
    const { adjuster, container } = this.selectVideoStyles(
      this.state,
      this.props,
    );

    return (
      <div
        className={classNames(
          'video-container',
          playing && '-playing',
          square && '-square',
          className,
        )}
        style={container}
      >
        <video style={adjuster} {...videoProps} />
        <button
          className="playbutton button -semantic"
          aria-label={playing ? 'Pause video' : 'Play video'}
          onClick={this.handleClickPlay}
        >
          <Icon
            aria-hidden
            className="-elevated"
            inverted
            name="play"
            size="huge"
          />
        </button>
        {mutable && (
          <Button
            className="mutebutton circular"
            circular
            aria-label={muted ? 'Unmute video' : 'Mute video'}
            onClick={this.handleToggleMuteVideo}
            icon={<Slicon name={muted ? 'volume-off' : 'volume-2'} />}
          />
        )}
        {children}
      </div>
    );
  }
}

export default Video;
