import check from 'check-types';
import axios from 'axios';

import { checkArguments } from '../modules/services';
import { getApiEndpoint, getTokenedConfig } from '../modules/api';
import { throwInternalError } from '../modules/errors';

/**
 * Services to manipulate data concerning relationships between users.
 */

/**
 * Object representing the relationship status between two users.
 * @typedef { object } RelationshipStatus
 * @property { string } outgoingStatus The relationship of the currently logged-in
 * user to the other user. Can be 'spotting', 'requested', 'none'.
 * @property { string } incomingStatus The other user's relationship to the currently
 * logged-in user. Can be 'spottedBy', 'requestedBy', 'blocked', 'none'.
 */

/**
 * Gets the current relationship status between the currently logged-in user
 * and the specified user.
 * @param { string } otherUid The uid of the user to get the relationship status with.
 * @returns { RelationshipStatus } The relationship status.
 */
export const getRelationshipWith = async function getRelationshipWith(
  otherUid,
) {
  checkArguments(
    { otherUid },
    {
      otherUid: check.nonEmptyString,
    },
  );

  const url = getApiEndpoint.relationships.getCurrent(otherUid);
  let data = [];

  try {
    const config = await getTokenedConfig();
    ({
      data: { data },
    } = await axios.get(url, config));
  } catch (error) {
    throwInternalError(error);
  }

  return data;
};

/**
 * Updates the relationship status between the currently logged-in user and the
 * specified user. Returns the updated status.
 * @param { string } userUid The uid of the user to update the relationship with.
 * @param { string } action The action. Can be 'spot' or 'unspot'.
 * @returns { RelationshipStatus } The updated relationship status.
 */
export const updateRelationship = async function updateRelationshipWith(
  userUid,
  action,
) {
  const args = { userUid, action };
  checkArguments(args, {
    userUid: check.nonEmptyString,
    action: check.nonEmptyString,
  });

  // Check action is possible
  if (!['spot', 'unspot'].includes(action)) {
    const error = new Error('Internal error: unrecognised action');
    error.params = args;
    console.error(error);
    throw error;
  }

  const url = getApiEndpoint.relationships.updateCurrent(userUid);
  const data = {
    action,
  };

  let result;

  try {
    const config = await getTokenedConfig();
    ({
      data: { data: result },
    } = await axios.post(url, data, config));
  } catch (error) {
    throwInternalError(error);
  }

  return result;
};

export const getSpotters = async function getSpotters(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  const url = getApiEndpoint.relationships.getSpottedBy(uid);
  let data = [];

  try {
    const config = await getTokenedConfig();
    ({
      data: { data },
    } = await axios.get(url, config));
  } catch (error) {
    throwInternalError(error);
  }

  return data;
};

export const getSpotting = async function getSpotting(uid) {
  checkArguments({ uid }, { uid: check.nonEmptyString });

  const url = getApiEndpoint.relationships.getSpotting(uid);
  let data = [];

  try {
    const config = await getTokenedConfig();
    ({
      data: { data },
    } = await axios.get(url, config));
  } catch (error) {
    throwInternalError(error);
  }

  return data;
};
