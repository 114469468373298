import React, { Component } from 'react';
import PostCard from './PostCard';
import {
  oneOfType,
  string,
  element,
  number,
  arrayOf,
  shape,
  bool,
  func,
} from 'prop-types';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import { Waypoint } from 'react-waypoint';
import times from 'lodash/times';

import { sliceByN } from '../../modules/utils';
import { Link, withRouter } from 'react-router-dom';

const makeGetPostRows = () =>
  createSelector(
    (_, props) => props.posts,
    (_, props) => props.columns,
    (posts, columns) => {
      return sliceByN(posts, parseInt(columns, 10));
    },
  );

const getRowKey = function getRowKey(row) {
  return row.map(post => post.uid).join('-');
};

class PostGrid extends Component {
  static propTypes = {
    as: oneOfType([string, element]),
    columns: oneOfType([number, string]),
    posts: arrayOf(
      shape({
        uid: string.isRequired,
      }),
    ),
    requestMoreData: func,
    isLastBatch: bool,
    isLoading: bool,
    onClickPost: func,
  };

  static defaultProps = {
    as: 'div',
    posts: [],
    columns: 3,
    isLastBatch: true,
    isLoading: false,
    requestMoreData: undefined,
    onClickPost: undefined,
  };

  madeGetPostRows = makeGetPostRows();

  getPostRows() {
    return this.madeGetPostRows(this.state, this.props);
  }

  renderEmptyNodes(row, uid) {
    const { columns } = this.props;

    const [head] = row;
    return times(parseInt(columns, 10) - row.length, index => (
      <li key={`${head.uid}-${index}`} aria-hidden className="item" />
    ));
  }

  renderRow(row) {
    const {
      onClickPost,
      match: { url },
    } = this.props;

    const emptyDivs = this.renderEmptyNodes(row);

    const postsNodes = row.map(post => (
      <li key={post.uid} className="item post-grid-item">
        <div className="_square-helper" />
        <PostCard
          post={post}
          as={Link}
          to={`${url}/${post.uid}`}
          replace
          value={onClickPost && post.uid}
        />
      </li>
    ));

    return (
      <ol key={getRowKey(row)} className="row">
        {postsNodes}
        {emptyDivs}
      </ol>
    );
  }

  render() {
    const { as: As, isLastBatch, isLoading, requestMoreData } = this.props;

    const postRows = this.getPostRows();

    return (
      <As className="post-grid">
        <ol className="grid-container">
          {postRows.map(row => this.renderRow(row))}
        </ol>
        {!isLoading && !isLastBatch && requestMoreData && (
          <Waypoint onEnter={requestMoreData} />
        )}
        {!isLastBatch && <Loader size="large" inline="centered" active />}
      </As>
    );
  }
}

export default withRouter(PostGrid);
