// PNG
import baseballPng from './baseball_icon-01.png';
import basketballPng from './basketball_icon-01.png';
import crossCountryPng from './crosscountry_icon-01.png';
import footballPng from './football_icon-01.png';
import generalSportPng from './general_sport_icon-01.png';
import golfPng from './golf_icon-01.png';
import gymnasticsPng from './gymnastics_icon-01.png';
import hockeyPng from './hockey_icon-01.png';
import lacrossPng from './lacross_icon-01.png';
import skiingPng from './skiing_icon-01.png';
import soccerPng from './soccer_icon-01.png';
import swimmingPng from './swimming_icon-01.png';
import tennisPng from './tennis_icon-01.png';
import trackAndFieldPng from './trackandfield_icon-01.png';
import volleyballPng from './volleyball_icon-01.png';
import waterPoloPng from './waterpolo_icon-01.png';
import wrestlingPng from './wrestling_icon-01.png';

// SVG
import baseballSvg from './baseball_icon-01.png';
import basketballSvg from './basketball_icon-01.png';
import crossCountrySvg from './crosscountry_icon-01.png';
import footballSvg from './football_icon-01.png';
import generalSportSvg from './general_sport_icon-01.png';
import golfSvg from './golf_icon-01.png';
import gymnasticsSvg from './gymnastics_icon-01.png';
import hockeySvg from './hockey_icon-01.png';
import lacrossSvg from './lacross_icon-01.png';
import skiingSvg from './skiing_icon-01.png';
import soccerSvg from './soccer_icon-01.png';
import swimmingSvg from './swimming_icon-01.png';
import tennisSvg from './tennis_icon-01.png';
import trackAndFieldSvg from './trackandfield_icon-01.png';
import volleyballSvg from './volleyball_icon-01.png';
import waterPoloSvg from './waterpolo_icon-01.png';
import wrestlingSvg from './wrestling_icon-01.png';

import { USER_SPORTS } from '../../../modules/constants/users';

export const SPORT_ASSETS = {
  [USER_SPORTS.baseball]: {
    svg: baseballSvg,
    png: baseballPng,
  },
  [USER_SPORTS.basketball]: {
    svg: basketballSvg,
    png: basketballPng,
  },
  [USER_SPORTS.crossCountry]: {
    svg: crossCountrySvg,
    png: crossCountryPng,
  },
  [USER_SPORTS.football]: {
    svg: footballSvg,
    png: footballPng,
  },
  [USER_SPORTS.other]: {
    svg: generalSportSvg,
    png: generalSportPng,
  },
  [USER_SPORTS.golf]: {
    svg: golfSvg,
    png: golfPng,
  },
  [USER_SPORTS.gymnastics]: {
    svg: gymnasticsSvg,
    png: gymnasticsPng,
  },
  [USER_SPORTS.hockey]: {
    svg: hockeySvg,
    png: hockeyPng,
  },
  [USER_SPORTS.lacross]: {
    svg: lacrossSvg,
    png: lacrossPng,
  },
  [USER_SPORTS.skiing]: {
    svg: skiingSvg,
    png: skiingPng,
  },
  [USER_SPORTS.soccer]: {
    svg: soccerSvg,
    png: soccerPng,
  },
  [USER_SPORTS.swimming]: {
    svg: swimmingSvg,
    png: swimmingPng,
  },
  [USER_SPORTS.tennis]: {
    svg: tennisSvg,
    png: tennisPng,
  },
  [USER_SPORTS.trackAndField]: {
    svg: trackAndFieldSvg,
    png: trackAndFieldPng,
  },
  [USER_SPORTS.volleyball]: {
    svg: volleyballSvg,
    png: volleyballPng,
  },
  [USER_SPORTS.waterPolo]: {
    svg: waterPoloSvg,
    png: waterPoloPng,
  },
  [USER_SPORTS.wrestling]: {
    svg: wrestlingSvg,
    png: wrestlingPng,
  },
};
