import withData from '../../components/higher-order/withData';
import UserList from '../../components/user/UserList';
import { getSpotting } from '../../services/relationships';

const SpottingUserList = withData(getSpotting, {
  dataPropName: 'users',
  paramPropName: 'userUid',
})(UserList);

export default SpottingUserList;
