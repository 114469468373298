import React, { Component } from 'react';

import ContentContainer from '../../components/ContentContainer';
import EditAboutYouForm from '../../containers/users/forms/EditAboutYouForm';

class EditAboutYouPage extends Component {
  render() {
    const { user, onUpdateSuccess } = this.props;
    return (
      <ContentContainer size="tiny" align="left">
        <EditAboutYouForm
          data={user}
          className="-stacked"
          onSuccess={onUpdateSuccess}
        />
      </ContentContainer>
    );
  }
}

export default EditAboutYouPage;
