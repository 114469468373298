import React, { Fragment } from 'react';

/**
 * @module modules/constants/messages
 * Module dedicated to error, info, and warning messages.
 */

export const ERROR_CODES = {
  userSuspended: 'auth/user-disabled',
  userNotRegistered: 'auth/user-not-registered',
};

export const ERROR_DATA = {
  [ERROR_CODES.userSuspended]: {
    type: 'error',
    message: 'Your account has been suspended!',
    extraChildren: (
      <Fragment>
        Please{' '}
        <a href="mailto:info@flexpit.com?subject=Account Suspended">
          contact us
        </a>
        .
      </Fragment>
    ),
  },

  [ERROR_CODES.userNotRegistered]: {
    type: 'error',
    message: 'You need to complete the onboarding on the mobile app to log in!',
  },
};
