import React from 'react';
import { Image } from 'semantic-ui-react';

import ContentContainer from '../components/ContentContainer';
import flexpitLogo from '../assets/images/logo_wordmark_black.png';
import StoreBadges from '../components/store-badges/StoreBadges';

const propTypes = {};

const defaultProps = {};

const StoreLinksPage = function StoreLinksPage() {
  return (
    <ContentContainer className="store-links-page" size="tiny" align="centre">
      <Image src={flexpitLogo} alt="FLEXPIT" className="flexpitlogo" />
      <p className="welcometext" size="small">
        Download the FLEXPIT app today to use it from your mobile!
      </p>
      <StoreBadges className="-small" />
    </ContentContainer>
  );
};

StoreLinksPage.propTypes = propTypes;
StoreLinksPage.defaultProps = defaultProps;

export default StoreLinksPage;
