export const customApi = {
  environment: 'development',
  apiUrl: 'https://flexpit-testing.appspot.com/',
  apiVersion: 'v1',
};

export const firebaseConfig = {
  apiKey: 'AIzaSyDWksm5DhlczTiHIw6fzo3GLOYzn5y9Hjc',
  authDomain: 'flexpit-testing.firebaseapp.com',
  databaseURL: 'https://flexpit-testing.firebaseio.com',
  storageBucket: 'flexpit-testing.appspot.com',
  messagingSenderId: '257839243301',
  projectId: 'flexpit-testing',
};
