import { getLeaderboard } from '../../services/leaderboards';
import withLazyLoadedData from '../../components/higher-order/withLazyLoadedData';
import LiftRankingTable from '../../components/activities/LiftRankingTable';

const ActivityCompleteLeaderboardTable = withLazyLoadedData(getLeaderboard, {
  dataPropName: 'rankings',
  paramPropName: 'criteria',
  // Sum the number of rankings in each group to calculate the offset
  combinerNumberData: groupMap => {
    return [...groupMap.values()].reduce(
      (sum, group) => sum + group.data.length,
      0,
    );
  },
})(LiftRankingTable);

export default ActivityCompleteLeaderboardTable;
