import React, { Component } from 'react';
import { arrayOf, shape, string, array } from 'prop-types';
import {
  Dimmer,
  Header,
  Loader,
  Dropdown,
  Icon,
  Popup,
} from 'semantic-ui-react';
import { createSelector } from 'reselect';
import copy from 'copy-to-clipboard';

import ContentContainer from '../../components/ContentContainer';
import CriteriaList from './CriteriaList';
import HeaderSegment from '../../components/HeaderSegment';
import Video from '../../components/Video';
import { injectParamsIntoRoute, routes } from '../../modules/routes';

class FlexpitStandardPage extends Component {
  static propTypes = {
    activities: arrayOf(
      shape({
        uid: string.isRequired,
        name: string.isRequired,
        criteria: array,
      }),
    ).isRequired,
  };

  static defaultProps = {
    activities: [],
    match: {
      params: {},
      path: routes.standard.root,
    },
    history: {
      replace() {},
    },
  };

  state = {
    copied: false,
  };

  selectDropdownOptions = createSelector(
    props => props.activities,
    activities =>
      activities.map(activity => ({
        text: activity.name,
        value: activity.uid,
      })),
  );

  selectSelectedActivityUid = createSelector(
    props => props.match.params,
    props => props.activities,
    (params, activities) => {
      const { activity } = params;
      const uid = !activity && activities[0] ? activities[0].uid : activity;
      return uid;
    },
  );

  selectSelectedActivity = createSelector(
    this.selectSelectedActivityUid,
    props => props.activities,
    (uid, activities) => {
      const activity = activities.find(act => act.uid === uid);
      return activity;
    },
  );

  componentWillUnmount() {
    if (this.clearCopied) {
      clearTimeout(this.clearCopied);
    }
  }

  handleCopyShareLink = () => {
    if (copy(window.location.href)) {
      this.setState({ copied: true });
      this.clearCopied = setTimeout(() => {
        this.setState({ copied: false });
        this.clearCopied = undefined;
      }, 3000);
    }
  };

  handleCloseCopiedPopup = () => {
    this.setState({ copied: false });
    if (this.clearCopied) {
      clearTimeout(this.clearCopied);
    }
  };

  handlePickActivity = ({ target }, data) => {
    const {
      history,
      match: { params, path },
    } = this.props;
    const { value } = data || target;

    const newUrl = injectParamsIntoRoute(path, { ...params, activity: value });
    history.replace(newUrl);
  };

  render() {
    const { isLoading } = this.props;
    const { copied } = this.state;

    const activityUid = this.selectSelectedActivityUid(this.props);
    const activity = this.selectSelectedActivity(this.props);
    const activityDropOptions = this.selectDropdownOptions(this.props);

    return (
      <ContentContainer
        as="article"
        className="flexpit-standard-page"
        size="small"
      >
        <Dimmer.Dimmable
          as={HeaderSegment}
          dimmed={isLoading}
          header="The FLEXPIT Standard"
          className="-sectionned"
        >
          <Dimmer active={isLoading} inverted>
            <Loader size="large">Loading...</Loader>
          </Dimmer>

          <section className="section">
            <p className="paragraph">
              FLEXPIT Standard allows users to learn the proper criteria for
              each lift. Once the user follows the lift criteria correctly, a
              certified trainer can verify the lift to be placed into the
              leaderboard.
            </p>

            <Dropdown
              options={activityDropOptions}
              onChange={this.handlePickActivity}
              value={activityUid}
              placeholder="Select an activity..."
              selection
              fluid
            />
          </section>
          {activity && (
            <section className="section">
              <Header as="h4">
                {activity.name}
                <Popup
                  trigger={
                    <button
                      className="semantic-link -icon -floatright"
                      onClick={this.handleCopyShareLink}
                      aria-label="Copy link to page"
                    >
                      <Icon name="share square" />
                    </button>
                  }
                  content="Link copied to clipboard!"
                  on="click"
                  open={copied}
                  onClose={this.handleCloseCopiedPopup}
                  position="bottom right"
                />
              </Header>
              <p className="paragraph">{activity.description}</p>
              <CriteriaList criteria={activity.criteria} />
              <Video
                source={activity.mediaUrl}
                preload="metadata"
                muted={false}
              />
            </section>
          )}
        </Dimmer.Dimmable>
      </ContentContainer>
    );
  }
}

export default FlexpitStandardPage;
