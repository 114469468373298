import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { customSortEntries } from '../../modules/utils';
import { makeSelectOrderedData } from '../../modules/selectors';
import isEqual from 'lodash/isEqual';

/**
 * @callback subscribeToDataFunction
 * @param { Object } params
 * @param { number } params.limit Defines the limit of documents to subscribe to.
 * @param { onReceiveDataFunction } params.onReceiveDataFunction The handler to call
 * when receiving data.
 * @param { onError } params.onError The error handler function.
 * @returns { Function } The function to call to unsuscribe.
 */

/**
 * @callback onReceiveDataFunction
 * @param { Object } params
 * @param { Object[] } params.added The subscribed documents that were added.
 * @param { Object[] } params.removed The subscribed documents that were removed.
 * @param { Object[] } params.updated The subscribed documents that were updated.
 */

/**
 * Gets a new parametised higher-order component with lazy-loaded reactive data.
 * The wrapped component is provided with data, and a function to get more data.
 *
 * @param { Object } params
 * @param { subscribeToDataFunction } params.subscribeToData The API call to subscribe
 * to data.
 * @param { number } params.nbByLoad The number of documents to load in each request.
 * @param { number } [ params.nbInitial = nbByLoad ] The number of documents to load when mounting.
 * @param { string } [ params.dataPropName = 'data' ] The name of the prop to give to the wrapped component.
 * @param { string } [ params.paramsPropName = 'subscriptionParams' ] The name
 * of the props to get the fetching parameters from in the HoC.
 * @param { boolean } [ params.passDownParams = false ] true to pass down the
 * `fetchParams` props in the wrapped component, false else.
 * @param { boolean } [ params.wrapProps = false ] true to wrap all the props passed down
 * in a single prop object.
 * @returns { Function } A higher-order component function to wrap a component with data.
 */
const withLazyLoadedReactiveData = function withLazyLoadedReactiveData({
  subscribeToData,
  nbByLoad,
  nbInitial = nbByLoad,
  dataPropName = 'data',
  paramsPropName = 'subscriptionParams',
  passDownParams = false,
  wrapProps = false,
}) {
  return WrappedComponent => {
    const wrappedDisplayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const makeGetData = () =>
      createSelector(
        [state => state.data, state => state.ignoredData],
        (dataMap, ignoredDataSet) => {
          return [...dataMap.values()].filter(
            piece => !ignoredDataSet.has(piece.uid),
          );
        },
      );

    class WithLazyLoadedReactiveData extends Component {
      static displayName = `withLazyLoadedReactiveData(${wrappedDisplayName})`;

      static propTypes = {
        load: PropTypes.string,
        orderBy: PropTypes.shape({
          field: PropTypes.string.isRequired,
          order: PropTypes.oneOf(['asc', 'desc']),
        }),
        [paramsPropName]: PropTypes.object,
      };

      static defaultProps = {
        load: 'after',
        orderBy: undefined,
        [paramsPropName]: {},
      };

      static get warningUnsuscribe() {
        return () =>
          console.warn(
            'Warning: unsuscribe(): Called unsuscribe before having suscribed to any data.',
          );
      }

      static get initialState() {
        return {
          data: new Map(),
          error: undefined,
          ignoredData: new Set(),
          nbSubscriptions: 0,
          isLoading: true,
        };
      }

      state = WithLazyLoadedReactiveData.initialState;

      unsuscribe = WithLazyLoadedReactiveData.warningUnsuscribe;

      /* Make selectors for this instance */
      madeGetData = makeGetData();
      madeGetOrderedData = makeSelectOrderedData(this.madeGetData);

      selectIsLastBatch = createSelector(
        state => state.ignoredData,
        state => state.isLoading,
        (ignoredData, isLoading) => !isLoading && ignoredData.size === 0,
      );

      componentDidMount() {
        this.subscribeNextData(true);
      }

      componentWillReceiveProps(nextProps) {
        const { [paramsPropName]: fetchParams } = this.props;

        // If fetch params change, reset whole state and subscription
        if (!isEqual(fetchParams, nextProps[paramsPropName])) {
          const { nbSubscriptions } = this.state;
          if (nbSubscriptions > 0) this.unsuscribe();

          this.setState(WithLazyLoadedReactiveData.initialState, () =>
            this.subscribeNextData(true),
          );
        }
      }

      componentWillUnmount() {
        const { nbSubscriptions } = this.state;
        if (nbSubscriptions > 0) this.unsuscribe();
      }

      getData() {
        return this.madeGetData(this.state);
      }

      getOrderedData() {
        return this.madeGetOrderedData(this.state, this.props);
      }

      /**
       * Gets the number of documents that should be displayed.
       * @returns { number }
       */
      getLimitToDisplay() {
        const { nbSubscriptions } = this.state;

        return nbInitial + nbByLoad * (nbSubscriptions - 1);
      }

      /**
       * Creates a new subscription to data, loading more data than the previous one.
       * @param { boolean } [ isFirstSubscription = false ] Whether this is the first
       * subscription or not (called from #componentDidMount).
       */
      subscribeNextData(isFirstSubscription = false) {
        const { [paramsPropName]: subscriptionParams } = this.props;
        const { isLoading } = this.state;

        /**
         * Here, empty the list of ignored pieces to display previously fetched pieces that
         * were not displayed. Why would we fetch pieces we already have?
         */
        const query = {
          nbSubscriptions: { $apply: nbSub => nbSub + 1 },
          ignoredData: { $set: new Set() },
          ...(!isLoading ? { isLoading: { $set: true } } : {}),
        };

        this.setState(update(this.state, query), () => {
          const { nbSubscriptions } = this.state;

          if (!isFirstSubscription) {
            this.unsuscribe();
            this.unsuscribe = WithLazyLoadedReactiveData.warningUnsuscribe;
          }

          /**
           * Offset the number of pieces fetched by one extra piece to start our
           * "rolling posts” system.
           */
          const limit = nbInitial + (nbSubscriptions - 1) * nbByLoad + 1;

          const newSubscription = subscribeToData({
            ...subscriptionParams,
            limit,
            onReceiveData: this.handleReceiveData,
            onError: this.handleError,
          });

          // Keep `unsuscribe` function for later use
          this.unsuscribe = newSubscription;
        });
      }

      /**
       * Handles the document changes by updating the state.
       * @param { Object } params
       * @param { Object[] } params.added The pieces of data that were added to the subscription.
       * @param { Object[] } params.modified The pieces of data that were modified in the subscription.
       * @param { Object[] } params.removed The pieces of data that were removed from the subscription.
       */
      handleReceiveData = ({ added, modified, removed }) => {
        const { orderBy, load } = this.props;
        const { data } = this.state;

        /**
         * The folowing functions return arrays in this structure [uid, data]. The
         * reason behind it is that we have a Map in the state, and `immutability-helper`
         * uses this format for the $add operator.
         *
         * Filtering existing data that was already added (this is to skip data we already
         * have from the previous subscription.)
         */
        const piecesToAdd = added
          .filter(piece => !data.has(piece.uid))
          .map(piece => [piece.uid, piece]);

        /**
         *  Ignore elements that were fetched as extra to check if it is the last batch
         */

        const nbPiecesToIgnore =
          data.size + piecesToAdd.length - this.getLimitToDisplay();

        const allPieces = orderBy
          ? [...data.entries(), ...piecesToAdd].sort(customSortEntries(orderBy))
          : piecesToAdd;

        /**
         * Depending on whether the loading button is before or after the currently
         * displayed data, the ignored pieces should be taken at the beginning or at the
         * end of the array.
         * This is to insure, for example, when new data arrives, that the data new data
         * is not ignored and replaces old data.
         */

        let piecesToIgnore = [];

        if (nbPiecesToIgnore > 0) {
          const sliceParameters =
            load === 'before' ? [0, nbPiecesToIgnore] : [-nbPiecesToIgnore];
          piecesToIgnore = allPieces.slice(...sliceParameters);
        }

        piecesToIgnore = piecesToIgnore.map(([uid]) => uid);

        const piecesToUpdate = modified.map(piece => [piece.uid, piece]);
        const piecesToRemove = removed.map(({ uid }) => uid);

        const query = {
          data: {
            $add: piecesToAdd.concat(piecesToUpdate),
            $remove: piecesToRemove,
          },
          ignoredData: { $add: piecesToIgnore },
          isLoading: { $set: false },
        };

        this.setState(update(this.state, query));
      };

      handleError = error => {
        this.setState({ error });
      };

      handleRequestMoreData = () => {
        const isLastBatch = this.selectIsLastBatch(this.state);

        if (!isLastBatch) {
          this.subscribeNextData();
        }
      };

      render() {
        const {
          [paramsPropName]: subscriptionParams,
          orderBy,
          load,
          isLoading: isLoadingProp,
          ...wrappedProps
        } = this.props;
        const { error, isLoading } = this.state;
        const isLastBatch = this.selectIsLastBatch(this.state);

        const propWrapper = {
          error,
          isLastBatch,
          [wrapProps ? 'data' : dataPropName]: orderBy
            ? this.getOrderedData()
            : this.getData(),
          isLoading: isLoadingProp || isLoading,
          requestMoreData: this.handleRequestMoreData,
        };

        // Pass down parametes if asked to in HoC
        if (passDownParams) {
          propWrapper[paramsPropName] = subscriptionParams;
        }

        // Either wrap all the props with an object, or pass them directly as props
        const extraProps = wrapProps
          ? { [dataPropName]: propWrapper }
          : propWrapper;

        return <WrappedComponent {...extraProps} {...wrappedProps} />;
      }
    }

    return WithLazyLoadedReactiveData;
  };
};

export default withLazyLoadedReactiveData;
