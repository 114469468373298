import React, { Component, Fragment } from 'react';
import { oneOfType, string, element, func, shape } from 'prop-types';
import HeaderSegment from '../HeaderSegment';
import { Loader } from 'semantic-ui-react';

class ActivitySegment extends Component {
  static propTypes = {
    as: oneOfType([string, element, func]),
    activity: shape({
      name: string,
    }),
  };

  render() {
    const { activity, header: extraHeader, isLoading, ...props } = this.props;

    const header = isLoading ? (
      <Loader inline="centered" active inverted />
    ) : (
      <Fragment>
        {activity && activity.name} {extraHeader}
      </Fragment>
    );

    return <HeaderSegment header={header} {...props} />;
  }
}

export default ActivitySegment;
