import React, { Component } from 'react';
import { string, arrayOf } from 'prop-types';
import { getLoggedInUid } from '../../services/authentication';
import withLoggedInUser from './withLoggedInUser';
import { createSelector } from 'reselect';
import update from '../../../node_modules/immutability-helper';

const initialContext = () =>
  console.warn(
    'Warning: get() called in <UserCache /> before context has been set',
  );

const { Consumer, Provider } = React.createContext(initialContext);

export const UserCacheContext = {
  Consumer,
  Provider,
};

export const withUserCache = function withUserCache(WrappedComponent) {
  return class WithUserCache extends Component {
    static displayName = `WithCachedUser(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`;

    render() {
      const { ...props } = this.props;
      return (
        <Consumer>
          {getCached => (
            <WrappedComponent getCachedUser={getCached} {...props} />
          )}
        </Consumer>
      );
    }
  };
};

/**
 * Higher-order component that delivers a cached version of a user, or fetches
 * it if not provided.
 * @param { string } [ uidPropName = 'cachedUserUid' ] The name of the prop to
 * get the uid from.
 * @param { string } [ dataPropName = 'cachedUser' ] The name of the prop to pass
 * down user data to the wrapped component.
 * @returns { function } A higher-order component taking a component as an argument.
 */
const withCachedUser = function withCachedUser({
  uidPropName = 'cachedUserUid',
  dataPropName = 'cachedUser',
} = {}) {
  return WrappedComponent =>
    withLoggedInUser(
      withUserCache(
        class WithCachedUser extends Component {
          static displayName = `WithCachedUser(${WrappedComponent.displayName ||
            WrappedComponent.name ||
            'Component'})`;

          static propTypes = {
            [uidPropName]: string,
          };

          state = {
            isLoading: true,
            cachedUser: undefined,
          };

          selectUser = createSelector(
            (_, props) => props[uidPropName],
            state => state.cachedUser,
            (_, props) => props.currentUserState,
            state => state.isLoading,
            (userUid, cachedUser, currentUserState, isStateLoading) => {
              const result =
                userUid !== getLoggedInUid()
                  ? {
                      data: cachedUser,
                      isLoading: isStateLoading,
                    }
                  : {
                      data: currentUserState.profile,
                      isLoading: currentUserState.isLoading,
                    };

              return result;
            },
          );

          componentDidMount() {
            const { isLoading } = this.state;
            const shouldLoad = this.storeUser();

            if (shouldLoad !== isLoading)
              this.setState({ isLoading: shouldLoad });
          }

          componentWillReceiveProps(nextProps) {
            const { [uidPropName]: cachedUserUid } = this.props;
            if (nextProps[uidPropName] !== cachedUserUid) {
              this.setState({ isLoading: this.storeUser(nextProps) });
            }
          }

          storeUser(props = this.props) {
            const {
              [uidPropName]: cachedUserUid,
              currentUserState,
              getCachedUser,
            } = props;

            if (cachedUserUid) {
              if (cachedUserUid !== getLoggedInUid()) {
                getCachedUser(cachedUserUid).then(cachedUser =>
                  this.setState({ isLoading: false, cachedUser }),
                );
                return true;
              } else {
                return currentUserState.isLoading;
              }
            }

            return false;
          }

          render() {
            const {
              [uidPropName]: cachedUserUid,
              currentUserState,
              getCachedUser,
              ...props
            } = this.props;

            const data = this.selectUser(this.state, this.props);
            const cacheProps = {
              [dataPropName]: data,
            };

            return <WrappedComponent {...cacheProps} {...props} />;
          }
        },
      ),
    );
};

export default withCachedUser;

/**
 * Higher-order component that delivers a list of cached users, or fetches
 * them if not provided.
 * @param { string } [ uidPropName = 'cachedUserUids' ] The name of the prop to
 * get the uid from.
 * @param { string } [ dataPropName = 'cachedUsers' ] The name of the prop to pass
 * down user data to the wrapped component.
 * @returns { function } A higher-order component taking a component as an argument.
 */
export const withCachedUsers = function withCachedUsers({
  uidPropName = 'cachedUserUids',
  dataPropName = 'cachedUsers',
} = {}) {
  return WrappedComponent =>
    withLoggedInUser(
      withUserCache(
        class WithCachedUsers extends Component {
          static displayName = `WithCachedUsers(${WrappedComponent.displayName ||
            WrappedComponent.name ||
            'Component'})`;

          static propTypes = {
            [uidPropName]: arrayOf(string).isRequired,
          };

          static defaultProps = {
            [uidPropName]: [],
          };

          /**
           * Create a new state object containing the users already available and
           * user that needs to be loaded.
           * @param { object } props
           * @param { boolean } refresh true to refresh user.
           */
          static getInitialState(props, refresh = false) {
            const { [uidPropName]: uids, getCachedUser } = props;
            const cache = uids
              .filter(uid => uid !== getLoggedInUid())
              .reduce((state, uid) => {
                const user = getCachedUser(uid, refresh);
                const userState = user
                  ? {
                      data: user,
                      isLoading: false,
                    }
                  : {
                      data: undefined,
                      isLoading: true,
                    };

                state[uid] = userState;

                return state;
              }, {});

            const initialState = { cache };
            return initialState;
          }

          state = {
            cache: {},
          };

          selectUsers = createSelector(
            (_, props) => props[uidPropName],
            state => state.cache,
            (_, props) => props.currentUserState,
            (uids, cache, currentUserState) => {
              const users = uids.map(uid =>
                uid === getLoggedInUid()
                  ? {
                      uid,
                      data: currentUserState.profile,
                      isLoading: currentUserState.isLoading,
                    }
                  : cache[uid] && { uid, ...cache[uid] },
              );

              return users;
            },
          );

          constructor(props) {
            super(props);

            this.state = WithCachedUsers.getInitialState(props);
          }

          componentDidMount() {
            this.refreshUserState(true);
          }

          componentWillReceiveProps(nextProps) {
            this.refreshUserState(nextProps);
          }

          /**
           * Check each uid and call #storeUser to store the user in the state once fetched.
           * @param { boolean } [ forceRefresh = false] true to fetch the users even if the
           * state is indicating that they are being loaded.
           * @param { object } [ props = this.props ]
           */
          refreshUserState(forceRefresh = false, props = this.props) {
            const { [uidPropName]: uids } = props;
            const { cache } = this.state;

            uids
              .filter(uid => uid !== getLoggedInUid())
              .forEach(uid => {
                const cachedUser = cache[uid];

                if (!cachedUser || (forceRefresh && cachedUser.isLoading)) {
                  this.storeUser(uid, props);

                  if (!cachedUser) {
                    this.setState(
                      update(this.state, {
                        cache: {
                          [uid]: userState =>
                            update(userState || {}, {
                              data: { $set: undefined },
                              isLoading: { $set: true },
                            }),
                        },
                      }),
                    );
                  }
                }
              });
          }

          /**
           * Calls the function to fetch the user and store the result into the state.
           * @param { string } uid The user's uid.
           * @param { object } props
           */
          storeUser(uid, props = this.props) {
            const { getCachedUser } = props;

            getCachedUser(uid).then(cachedUser =>
              this.setState(
                update(this.state, {
                  cache: {
                    [uid]: userState =>
                      update(userState || {}, {
                        data: { $set: cachedUser },
                        isLoading: { $set: false },
                      }),
                  },
                }),
              ),
            );
          }

          render() {
            const {
              [uidPropName]: cachedUserUids,
              currentUserState,
              getCachedUser,
              ...props
            } = this.props;

            const users = this.selectUsers(this.state, this.props);
            const cacheProps = {
              [dataPropName]: users,
            };

            return <WrappedComponent {...cacheProps} {...props} />;
          }
        },
      ),
    );
};
