import React, { Component } from 'react';
import { oneOfType, string, element, oneOf } from 'prop-types';
import classNames from 'classnames';

/**
 * Container to limit the width of content.
 */
class ContentContainer extends Component {
  static propTypes = {
    align: oneOf(['left', 'centre', 'right']),
    as: oneOfType([string, element]),
    size: oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big']),
  };

  static defaultProps = {
    align: 'centre',
    as: 'div',
    size: 'medium',
  };

  render() {
    const { align, as: As, size, className, ...otherProps } = this.props;

    return (
      <As
        className={classNames(
          className,
          'content-container',
          size && `-${size}`,
          align && `-${align}`,
        )}
        {...otherProps}
      />
    );
  }
}

export default ContentContainer;
