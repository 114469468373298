import React, { Component } from 'react';
import { Feed } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import moment from 'moment';
import classNames from 'classnames';

import { punctuate } from '../../modules/humanise';

class InfoEvent extends Component {
  selectMomentedDate = createSelector(
    props => props.event,
    event => moment(event.dateCreated),
  );

  render() {
    const {
      event: {
        data: { text },
        unseen,
      },
      className,
      currentUserState,
      ...props
    } = this.props;

    const dateCreated = this.selectMomentedDate(this.props);
    return (
      <Feed.Event
        className={classNames(
          'event-notification',
          unseen && '-unseen',
          className,
        )}
        {...props}
      >
        <Feed.Content>
          <Feed.Summary>
            {punctuate(text)}
            <Feed.Date>{dateCreated.fromNow(true)}</Feed.Date>
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    );
  }
}

export default InfoEvent;
