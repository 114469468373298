import React, { Component } from 'react';
import { bool, number } from 'prop-types';
import { Search } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import { createSelector } from 'reselect';
import classNames from 'classnames';

import UserSearchResult from './UserSearchResult';
import { searchUsers } from '../../services/users';

class UserSearchBar extends Component {
  static propTypes = {
    circular: bool,
    nbResults: number,
  };

  static defaultProps = {
    placeholder: 'Search user, e.g. john...',
    resultRenderer: UserSearchResult,
    showNoResults: true,
    input: {
      icon: 'search',
      iconPosition: 'left',
    },
    circular: false,
    nbResults: 8,
  };

  state = {
    error: undefined,
    isLoading: false,
    searchValue: '',
    users: new Map(),
  };

  request = undefined;

  selectResults = createSelector(
    state => state.users,
    userMap => {
      const users = [...userMap.values()];
      return users.map(({ uid, photoUrl, username }) => ({
        id: uid,
        image: photoUrl,
        title: username,
        price: uid,
      }));
    },
  );

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
      this.request = undefined;
    }
  }

  getResults() {
    return this.selectResults(this.state);
  }

  handleRequestFetchUsers = async () => {
    const { nbResults } = this.props;
    const { searchValue } = this.state;
    const usersMap = new Map();
    let error = null;

    if (searchValue) {
      if (this.request) {
        this.request.cancel();
        this.request = undefined;
      }

      try {
        this.request = searchUsers({ username: searchValue, limit: nbResults });
        const data = await this.request.promise;
        data.forEach(user => usersMap.set(user.uid, user));
      } catch (internalError) {
        error = internalError;
      }
    }

    this.setState({
      error,
      users: usersMap,
      isLoading: false,
    });
  };

  debouncedFetchUsers = debounce(this.handleRequestFetchUsers, 500);

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, searchValue: value });
    this.debouncedFetchUsers();
  };

  render() {
    const {
      loading,
      className,
      circular,
      showNoResults,
      onSearchChange,
      nbResults,
      ...props
    } = this.props;
    const { isLoading, value } = this.state;

    const results = this.getResults();

    return (
      <Search
        loading={isLoading || loading}
        onSearchChange={this.handleSearchChange}
        results={results}
        showNoResults={!isLoading && showNoResults}
        value={value}
        className={classNames(!circular && '-notcircular', className)}
        {...props}
      />
    );
  }
}

export default UserSearchBar;
