import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import PostItemWithAuthor, {
  PostItemWithAuthorContainer,
} from '../../containers/posts/PostItemWithAuthor';
import { computePostMediumRatio } from '../../modules/css';
import Page404 from '../../pages/Page404';
import { getIsPublic } from '../../services/authentication';

class SinglePostItem extends Component {
  selectRatioHelper = createSelector(
    (_, props) => props.post,
    post => {
      if (!post) {
        return 0;
      }
      const helperRatio = computePostMediumRatio(post);
      return helperRatio;
    },
  );

  render() {
    const { post, ...props } = this.props;
    const { isLoading } = props;

    if (isLoading) {
      return <Loader size="big" active inline="centered" />;
    }

    if (!post) {
      return <Page404 />;
    }

    const isPublic = getIsPublic();
    const helperRatio = this.selectRatioHelper(this.state, this.props);

    return (
      <div className="post-item">
        <div style={{ paddingBottom: `${helperRatio}%` }} />
        {isPublic ? (
          <PostItemWithAuthor {...props} data={post} />
        ) : (
          <PostItemWithAuthorContainer
            {...props}
            data={post}
            subscriptionParams={{ uid: post.uid }}
          />
        )}
      </div>
    );
  }
}

export default SinglePostItem;
